<template>
  <div class="bool-content">
    <p>{{ $t(`application.general.${params.value}`) }}</p>
  </div>
</template>

<script>
export default {
  name: 'Bool',
  components: {},
  methods: {},
  data() {
    return {}
  }
}
</script>

<style lang="scss">
.bool-content {
  display: flex;
  justify-content: left;
}
</style>
