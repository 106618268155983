import { notify } from '@cargill/shared'

export async function onBatchDeleteHandler(
  service,
  identifiersToDelete,
  callback
) {
  await this.$brain.confirm(
    {
      title: this.$t('core.crud.attention'),
      subtitle: this.$tc(
        'core.crud.alertRemoveMessage',
        identifiersToDelete.length
      )
    },
    async () => {
      try {
        await service.batchDelete(identifiersToDelete)
        callback()
        notify.success({
          title: this.$tc(
            'core.crud.alertItemRemovedSuccess',
            identifiersToDelete.length
          )
        })
      } catch (err) {
        console.error('Failed to remove items', err)
        notify.error({
          title: this.$tc(
            'core.crud.alertItemRemoveError',
            identifiersToDelete.length
          )
        })
      }
    }
  )
}

export async function onDeleteHandler(service, id, callback) {
  await this.$brain.confirm(
    {
      title: this.$t('core.crud.attention'),
      subtitle: this.$tc('core.crud.alertRemoveMessage')
    },
    async () => {
      try {
        await service.delete(id)
        callback()
        notify.success({ title: this.$tc('core.crud.alertItemRemovedSuccess') })
      } catch (err) {
        console.error('Failed to remove item', err)
        notify.error({ title: this.$tc('core.crud.alertItemRemoveError') })
      }
    }
  )
}
