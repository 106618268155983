import { notify } from '@brain/ui'

const notifyFunctions = ['custom', 'error', 'info', 'success', 'warning']
const originalFunctions = {
  ...notify
}

const generalDefaultTimeout = 0
const defaultTimeoutByFunc = {
  success: 6000
}

for (const func of notifyFunctions) {
  notify[func] = (options) => {
    if (!options.timeout) {
      options.timeout = defaultTimeoutByFunc[func] ?? generalDefaultTimeout
    }
    originalFunctions[func](options)
  }
}

export { notify }
