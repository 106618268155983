import { CrudAction } from '@brain/core'
import { i18n, notify } from '@cargill/shared'
import _ from 'lodash'
import { processErrors, handleError } from '../helpers/errorHandler'

const logRequest = (serviceName, commandName, ...args) => {
  console.log(
    `%c ${serviceName} %c ${commandName} %c`,
    'background:#21a0e2 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff',
    'background:#89cd4d ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff',
    'background:transparent',
    ...args
  )
}

const getResponseFilename = (response) => {
  const headerPart = response.headers['content-disposition'].split("''")

  const filename =
    Array.isArray(headerPart) && headerPart.length > 1 ? headerPart[1] : null

  return decodeURIComponent(filename)
}

export const createCrudService = (
  baseURL,
  gateway,
  configOptions,
  name = 'Crud Service',
  fixedColumnContext = null
) => {
  const service = {
    preProcessing: {},
    postProcessing: {},
    lastAppliedFilter: null,
    logRequest: (commandName, ...args) =>
      logRequest(name, commandName, ...args),
    runPreProcessing(action, args) {
      this.runProcessing(this.preProcessing[action], args)
    },
    runPostProcessing(action, args) {
      this.runProcessing(this.postProcessing[action], args)
    },
    runProcessing(processes, args) {
      if (processes) {
        for (const process of processes) {
          process(args)
        }
      }
    },
    registerPreProcessing(action, process) {
      this.registerProcessing(this.preProcessing, action, process)
    },
    registerPostProcessing(action, process) {
      this.registerProcessing(this.postProcessing, action, process)
    },
    registerProcessing(processByAction, action, process) {
      if (processByAction[action] == null) {
        processByAction[action] = []
      }
      processByAction[action].push(process)
    },
    cancelPreProcessing(action, process) {
      this.cancelProcessing(this.preProcessing, action, process)
    },
    cancelPostProcessing(action, process) {
      this.cancelProcessing(this.postProcessing, action, process)
    },
    cancelProcessing(processByAction, action, process) {
      if (processByAction[action]) {
        processByAction[action] = processByAction[action].filter(
          (p) => p != process
        )
      }
    },
    async getPostProcessing(data) {
      return data
    },
    applyFixedColumns(entities) {
      if (fixedColumnContext == null) {
        return
      }
      fixedColumnContext.fixedColumns.forEach((fixedColumn) => {
        entities.forEach((entity) => {
          entity[fixedColumn.name] = fixedColumn.value
        })
      })
    },
    applyFixedColumnsFilter(filter) {
      if (fixedColumnContext == null) {
        return filter
      }
      const filterQueryToken = 'filterQuery'
      const andToken = '%26'
      const equalToken = '%3Deq%3A'
      const fixedColumnFilter = fixedColumnContext.fixedColumns
        .map(
          (fixedColumn) =>
            `${fixedColumn.name}${equalToken}${fixedColumn.value}`
        )
        .join(andToken)
      if (_.isEmpty(filter)) {
        return `${filterQueryToken}=${fixedColumnFilter}`
      }
      const queryTokens = Object.fromEntries(
        filter.split('&').map((token) => token.split('='))
      )
      if (queryTokens[filterQueryToken] == null) {
        queryTokens[filterQueryToken] = fixedColumnFilter
      } else {
        queryTokens[
          filterQueryToken
        ] = `${queryTokens[filterQueryToken]}${andToken}${fixedColumnFilter}`
      }
      return Object.entries(queryTokens)
        .map((entry) => entry.join('='))
        .join('&')
    },
    applyTabNameQuery(filter) {
      if (fixedColumnContext == null) {
        return filter
      }
      const tabNameQuery = `tabName=${fixedColumnContext.name.replace(
        '+',
        '%2B'
      )}`
      return _.isEmpty(filter) ? tabNameQuery : `${filter}&${tabNameQuery}`
    },
    fixPlusSign(filter) {
      if (_.isEmpty(filter)) {
        return filter
      }
      const importTabsToken = 'importTabs'

      const queryTokens = _.groupBy(
        filter.split('&').map((token) => token.split('=')),
        (token) => token[0]
      )
      Object.keys(queryTokens).forEach(
        (key) =>
          (queryTokens[key] = queryTokens[key].map((tokens) => tokens[1]))
      )
      if (!_.isEmpty(queryTokens[importTabsToken])) {
        queryTokens[importTabsToken] = queryTokens[importTabsToken].map(
          (importTab) => importTab.replace(/\+/g, '%2B')
        )
      }
      return Object.entries(queryTokens)
        .map((entry) => entry[1].map((value) => [entry[0], value]))
        .flat()
        .map((entry) => entry.join('='))
        .join('&')
    },
    async getAllVm(filter) {
      logRequest(name, 'getAll')
      try {
        filter = this.applyFixedColumnsFilter(filter)
        this.SetLastAppliedFilter(filter)
        this.runPreProcessing('read', { filter })
        const endPoint = filter ? `${baseURL}?${filter}` : baseURL

        const response = await gateway.get(endPoint)
        const data = Array.isArray(response.data.data)
          ? response.data.data
          : response.data
        this.runPostProcessing('read', { filter, data })
        await this.getPostProcessing(data)
        if (configOptions?.getPostProcessing) {
          configOptions.getPostProcessing(data)
        }
        return data
      } catch (e) {
        handleError(e)
        throw e
      }
    },
    handleError(e) {
      return handleError(e)
    },
    async getById(id) {
      logRequest(name, 'getById', id)
      try {
        this.runPreProcessing('read', { id })
        const response = await gateway.get(`${baseURL}/${id}`)
        const data = response.data
        this.runPostProcessing('read', { id, data })
        await this.getPostProcessing(data)
        if (configOptions?.getPostProcessing) {
          configOptions.getPostProcessing(data)
        }
        return data
      } catch (e) {
        handleError(e)
        throw e
      }
    },
    async getCountVm(filter) {
      logRequest(name, 'getCount')

      try {
        filter = this.applyFixedColumnsFilter(filter)
        this.SetLastAppliedFilter(filter)

        const endPointCount = filter
          ? `${baseURL}/count?${filter}`
          : `${baseURL}/count`
        const responseCount = await gateway.get(endPointCount)
        const count = responseCount.data
        return count
      } catch (e) {
        handleError(e)
        throw e
      }
    },
    createUpdatePreProcessing(data) {
      return data
    },
    async create(item, requestOptions) {
      logRequest(name, 'create', item)

      try {
        this.applyFixedColumns([item])
        this.runPreProcessing(CrudAction.CREATE, { item })

        if (configOptions?.masterName && requestOptions?.masterId) {
          item[configOptions.masterName] = requestOptions.masterId
        }

        this.createUpdatePreProcessing(item)
        const response = await gateway.post(baseURL, item)

        this.runPostProcessing(CrudAction.CREATE, { data: response.data })
        return response.data
      } catch (e) {
        handleError(e)
        return { ...e, errorItems: [], hasErrors: true }
      }
    },
    async deleteAll() {
      logRequest(name, 'deleteAll')

      try {
        const allData = await this.getAll()
        const allIds = allData.map((x) => x.id)
        await this.batchDelete(allIds)
      } catch (e) {
        handleError(e)
        throw e
      }
    },
    async delete(id) {
      logRequest(name, 'delete', id)

      try {
        this.runPreProcessing(CrudAction.DELETE, { ids: [id] })
        const response = await gateway.delete(`${baseURL}/${id}`)
        this.runPostProcessing(CrudAction.DELETE, { ids: [id], response })
      } catch (e) {
        handleError(e)
        throw e
      }
    },
    hasFilterApplied() {
      if (_.isEmpty(this.lastAppliedFilter)) {
        return false
      }
      const queryTokens = Object.fromEntries(
        this.lastAppliedFilter.split('&').map((token) => token.split('='))
      )
      const filterQueryToken = 'filterQuery'
      const andToken = '%26'
      const equalToken = '%3Deq%3A'

      if (!_.isEmpty(configOptions?.masterName)) {
        const queryFields = queryTokens[filterQueryToken].split(andToken)
        const finalQueryFields = queryFields.filter(
          (x) => !x.startsWith(`${configOptions?.masterName}${equalToken}`)
        )
        return !_.isEmpty(finalQueryFields)
      }
      return !_.isEmpty(queryTokens[filterQueryToken])
    },
    clearLastAppliedFilter() {
      this.lastAppliedFilter = ''
    },
    SetLastAppliedFilter(filterString) {
      if (!filterString) {
        this.lastAppliedFilter = filterString;
        return;
    }
      this.lastAppliedFilter = filterString
        .replace(/limit=[^&]*(&|$)/, '')
        .replace(/&$/, '')
    },
    async deleteFiltered() {
      logRequest(name, 'deleteFiltered')

      try {
        this.runPreProcessing(CrudAction.DELETE, {})
        const filter = _.isEmpty(this.lastAppliedFilter)
          ? ''
          : `?${this.lastAppliedFilter}`
        const response = await gateway.delete(
          `${baseURL}/deleteFiltered${filter}`
        )
        this.runPostProcessing(CrudAction.DELETE, { response })
      } catch (e) {
        handleError(e)
        throw e
      }
    },
    async batchDelete(ids) {
      logRequest(name, 'batchDelete', ids)

      try {
        this.runPreProcessing(CrudAction.DELETE, { ids })
        const response = await gateway.post(`${baseURL}/deleteBatch`, ids)
        this.runPostProcessing(CrudAction.DELETE, { ids, response })
      } catch (e) {
        handleError(e)
        throw e
      }
    },
    async update(item) {
      logRequest('edit', item)

      try {
        this.applyFixedColumns([item])
        this.runPreProcessing(CrudAction.UPDATE, { item })
        this.createUpdatePreProcessing(item)

        const response = await gateway.put(`${baseURL}/${item.id}`, item)
        this.runPostProcessing(CrudAction.UPDATE, { data: response.data })
        return response.data
      } catch (e) {
        handleError(e)
        return { ...e, errorItems: [], hasErrors: true }
      }
    },
    async batchUpdate(entities) {
      logRequest(name, 'batchUpdate', entities)

      try {
        this.applyFixedColumns(entities)
        this.runPreProcessing(CrudAction.UPDATE, { entities })
        this.createUpdatePreProcessing(entities)

        const response = await gateway.post(`${baseURL}/updateBatch`, entities)
        processErrors(response.data)
        const errors = response.data.reduce((result, entry) => {
          if (entry.errors && Object.keys(entry.errors).length) {
            result[entry.rowId] = entry.errors
          }
          return result
        }, {})
        this.runPostProcessing(CrudAction.UPDATE, {
          data: response.data,
          errors
        })

        return {
          data: response.data,
          errors
        }
      } catch (e) {
        handleError(e)
        throw e
      }
    },
    async import(file, queryParams) {
      logRequest(name, 'import')

      try {
        queryParams = this.applyTabNameQuery(this.fixPlusSign(queryParams))
        queryParams = this.applyFixedColumnsFilter(queryParams)
        this.runPreProcessing(CrudAction.IMPORT, { file })
        const response = await gateway.post(
          `${baseURL}/excel?${queryParams}`,
          file,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        this.runPostProcessing(CrudAction.IMPORT, { file, data: response.data })
        return response.data
      } catch (e) {
        handleError(e)
        return [
          {
            service: null,
            totalRows: 0,
            insertedRows: 0,
            updatedRows: 0,
            errorRows: 0,
            invalidFile: true
          }
        ]
      }
    },
    async export(queryParams) {
      logRequest(name, 'export', queryParams)

      try {
        queryParams = this.applyTabNameQuery(queryParams)
        queryParams = this.applyFixedColumnsFilter(queryParams)
        this.runPreProcessing(CrudAction.EXPORT, {
          queryParams
        })
        const endPoint = queryParams
          ? `${baseURL}/excel?${queryParams}`
          : `${baseURL}/excel`
        const response = await gateway.get(endPoint, {
          responseType: 'blob'
        })
        this.runPostProcessing(CrudAction.EXPORT, {
          queryParams,
          data: response.data
        })
        const filename = getResponseFilename(response)

        return { data: response.data, filename }
      } catch (e) {
        handleError(e)
        throw e
      }
    },
    getFileResponseData(response) {
      const filename = getResponseFilename(response)
      return {
        data: response.data,
        filename
      }
    },
    async exportResult(id) {
      logRequest(name, 'exportResult')

      try {
        // TODO: get base url from service configuration
        const baseURL = configOptions?.importLogUrl || '/api/importLog'
        const url = `${baseURL}/${id}/excel`

        const response = await gateway.get(url, {
          responseType: 'blob'
        })
        const filename = getResponseFilename(response)

        return { data: response.data, filename }
      } catch (e) {
        notify.error({
          title: i18n.t('application.errors.importError')
        })
        throw e
      }
    },
    async getMeta() {
      logRequest(name, 'meta')
      const response = await gateway.get(`${baseURL}/meta`)
      const meta = response?.data
      if (this.postProcessMeta) {
        this.postProcessMeta(meta)
      }
      return meta
    },
    async getValidationContext() {
      try {
        const response = await gateway.get(`${baseURL}/validationContext`)
        return response?.data
      } catch (exception) {
        console.log(exception)
        return null
      }
    },
    // eslint-disable-next-line no-unused-vars
    processValidationContext(_component, _ctx, _meta) {},
    async getMetaWithValidation(component) {
      let [meta, ctx] = await Promise.all([
        this.getMeta(),
        this.getValidationContext()
      ])
      if (this.processValidationContext) {
        this.processValidationContext(component, ctx, meta, this)
      }
      meta.validationCtx = ctx
      return meta
    },
    createServicesFixedColumns(fixedColumnContexts) {
      return fixedColumnContexts.map(this.createServiceFixedColumn)
    },
    createServiceFixedColumn(fixedColumnContext) {
      return createCrudService(
        baseURL,
        gateway,
        configOptions,
        `${name} - ${fixedColumnContext.name}`,
        fixedColumnContext
      )
    },
    async getFieldValues(field, queryParams) {
      logRequest(name, 'getFieldValues')

      const endPointValues =
        `${baseURL}/filterOptions/${field}` +
        (queryParams ? `?${queryParams}` : '')

      try {
        const response = await gateway.get(endPointValues)

        return response.data
      } catch (e) {
        handleError(e)
      }
    },
    async getOptions(endpoint, queryParams) {
      logRequest(name, 'getOptions')
      const endPointValues =
        `${endpoint}/options` + (queryParams ? `?${queryParams}` : '')
      try {
        const response = await gateway.get(endPointValues)
        return response.data
      } catch (e) {
        handleError(e)
      }
    }
  }
  service.getAll = (filter) => service.getAllVm(filter)
  service.getCount = (filter) => service.getCountVm(filter)
  return service
}
