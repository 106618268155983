import { createCrudRoutes } from '@brain/core'

const routes = [
  {
    path: '/forecast-sales',
    name: 'ForecastSales',
    title: 'controlTower.pages.forecastSales.title',
    public: true,
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/ForecastSales')
  },
  {
    path: '/on-time-in-full',
    name: 'OnTimeInFull',
    title: 'controlTower.pages.onTimeInFull.title',
    public: true,
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/OnTimeInFull')
  },
  {
    path: '/forecast-origin',
    name: 'ForecastOrigin',
    title: 'controlTower.pages.forecastOrigin.title',
    public: true,
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/ForecastOrigin')
  },
  {
    path: '/capacity-utilization',
    name: 'CapacityUtilization',
    title: 'controlTower.pages.capacityUtilization.title',
    public: true,
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/CapacityUtilization')
  },
  {
    path: '/weekly-planning-dashboard',
    // name: 'WeeklyPlanningDashboard',
    name: 'WeeklyPlanningDashboardRevised',
    title: 'controlTower.pages.weeklyPlanningDashboard.title',
    public: true,
    //component: () => import('../views/WeeklyPlanningDashboard')
    component: () => import('../views/WeeklyPlanningDashboardRevised')
  },
  {
    path: '/dashboards/monthly-planning',
    name: 'DashboardsMonthlyPlanning',
    title: 'application.pages.monthlyPlanningDashboardRevised.title',
    public: true,
    component: () => import('../views/MonthlyPlanningDashboardRevised.vue')
  },
  {
    path: '/total-plan-dashbord',
    name: 'TotalPlanDashboard',
    title: 'controlTower.pages.totalPlanDashboard.title',
    public: true,
    component: () => import('../views/TotalPlanDashboard.vue')
  },
  {
    path: '/performance-dashboard',
    name: 'PerformanceDashboard',
    title: 'application.pages.performanceDashboard.title',
    public: true,
    component: () => import('../views/PerformanceDashboard')
  },
  {
    path: '/cockpit-execution',
    name: 'CockpitExecution',
    title: 'controlTower.pages.cockpitExecution.title',
    public: true,
    component: () => import('../views/CockpitExecution')
  },
  {
    path: '/stuffing-terminal',
    name: 'StuffingPortal',
    title: 'controlTower.pages.stuffingPortal.title',
    public: true,
    component: () => import('../views/StuffingPortal')
  },
  {
    path: '/stuffing-portal-companies',
    name: 'StuffingPortalCompanies',
    title: 'controlTower.pages.stuffingPortalCompanies.title',
    public: true,
    component: () => import('../views/StuffingPortalCompanies')
  },
  ...createCrudRoutes('booking', () => import('@/views/Booking')),
  ...createCrudRoutes('Goal', () => import('../views/Goal')),
  ...createCrudRoutes('PlannedOrigination', () =>
    import('../views/PlannedOrigination')
  ),
  ...createCrudRoutes('originationFulfilled', () =>
    import('../views/OriginationFulfilled')
  ),
  ...createCrudRoutes('fulfilledSale', () => import('../views/FulfilledSale')),
  ...createCrudRoutes('plannedSale', () => import('../views/PlannedSale')),
  ...createCrudRoutes('plannedCapacityUtilization', () =>
    import('../views/PlannedCapacityUtilization')
  ),
  ...createCrudRoutes('fulfilledCapacityUtilization', () =>
    import('../views/FulfilledCapacityUtilization')
  ),
  ...createCrudRoutes('otifMonthly', () => import('../views/OtifMonthly')),
  ...createCrudRoutes('otifWeekly', () => import('../views/OtifWeekly')),
  ...createCrudRoutes('maximumCapacityUtilization', () =>
    import('../views/MaximumCapacityUtilization')
  ),
  ...createCrudRoutes('takeUpPurchaseContract', () =>
    import('../views/TakeUpPurchaseContract')
  ),
  ...createCrudRoutes('controlTowerUnity', () =>
    import('../views/ControlTowerUnity')
  ),
  ...createCrudRoutes('controlTowerUnityConversion', () =>
    import('../views/ControlTowerUnityConversion')
  ),
  ...createCrudRoutes('controlTowerUnityConversion', () =>
    import('../views/ControlTowerUnityConversion')
  ),
  ...createCrudRoutes(
    'takeUpBook',
    () => import('../views/TakeUpBook'),
    [],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpCompany',
    () => import('../views/TakeUpCompany'),
    ['takeUpCompaniesUsers', 'takeUpCompanyProducer'],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'stuffingPortalCompanies',
    () => import('../views/StuffingPortalCompanies'),
    ['stuffingPortalCompaniesUsers']
  ),
  ...createCrudRoutes(
    'takeUpQualityAndContamination',
    () => import('../views/TakeUpQualityAndContamination'),
    ['takeUpQualityAndContaminationTranslation']
  ),
  ...createCrudRoutes(
    'takeUpLotReportInputMaster',
    () => import('../views/TakeUpLotReportInputMaster'),
    ['takeUpLotReportInput'],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpReportInformationMaster',
    () => import('../views/takeUpReportInformationMaster'),
    ['takeUpLotReportInformationSummary'],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpProducer',
    () => import('../views/TakeUpProducer'),
    ['takeUpProducerGin', 'takeUpCounterPartyRef'],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpGin',
    () => import('../views/TakeUpGin'),
    [],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpGrade',
    () => import('../views/TakeUpGrade'),
    [],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpOffer',
    () => import('../views/TakeUpOffer'),
    ['takeUpOfferLot'],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpCancellations',
    () => import('../views/TakeUpCancellations'),
    [],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpSchedules',
    () => import('../views/TakeUpSchedules'),
    [],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpLocality',
    () => import('../views/TakeUpLocality'),
    [],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpContaminationValue',
    () => import('../views/TakeUpContaminationValue'),
    [],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpProducerMonitoring',
    () => import('../views/TakeUpProducerMonitoring'),
    [],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpSchedulesFarmReportLog',
    () => import('../views/TakeUpSchedulesFarmReportLog'),
    [],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpReferenceTableIntrinsic',
    () => import('../views/TakeUpReferenceTableIntrinsic'),
    [
      'takeUpReferenceTableIntrinsicGradeRule',
      'takeUpReferenceTableIntrinsicRule'
    ],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpReferenceTableContamination',
    () => import('../views/TakeUpReferenceTableContamination'),
    ['takeUpReferenceTableContaminationRule'],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpUsBucket',
    () => import('../views/TakeUpUsBucket'),
    [
      'takeUpUsBucketImportance',
      'takeUpUsBucketGrade',
      'takeUpUsBucketIntrinsic',
      'takeUpUsBucketContamination'
    ],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpHvi',
    () => import('../views/TakeUpHvi'),
    ['takeUpHviInput'],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpUsBucketCalculatorReportLog',
    () => import('../views/TakeUpUsBucketCalculatorReportLog'),
    [],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpPremiumDiscountManagement',
    () => import('../views/TakeUpPremiumDiscountManagement'),
    [],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpImportsPreparation',
    () => import('../views/TakeUpImportsPreparation'),
    ['takeUpImportReport'],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpPremiumDiscountApplication',
    () => import('../views/TakeUpPremiumDiscountApplication'),
    [],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpHviExtraction',
    () => import('../views/TakeUpHviExtraction'),
    [],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'takeUpContractsPosition',
    () => import('../views/TakeUpContractsPosition'),
    [],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'logisticPortalDomesticMarket',
    () => import('../views/LogisticPortalDomesticMarket'),
    [],
    null,
    'controlTower.pages'
  ),
  ...createCrudRoutes(
    'logisticPortalStorage',
    () => import('../views/LogisticPortalStorage'),
    [],
    null,
    'controlTower.pages'
  )
]

export default routes
