<script>
import BcCrudGrid from './BcCrudGrid.vue'
import { helpers } from '@cargill/shared'

export default {
  name: 'CgCrudGrid',
  extends: BcCrudGrid,
  props: {
    customToolbarActions: { type: Array, default: () => [] },
    deleteFiltered: { type: Boolean, default: true }
  },
  methods: {
    async confirmDeleteFilteredWithoutFilter() {
      return helpers.createConfirmPromise(
        this,
        {
          type: 'error',
          title: this.$t('core.crud.attention'),
          subtitle: this.$tc(
            'core.crud.alertRemoveFilteredWithoutFilterMessage'
          )
        },
        () =>
          this.service.hasFilterApplied == null ||
          this.service.hasFilterApplied()
      )
    },
    async confirmDeleteFiltered() {
      return helpers.createConfirmPromise(this, {
        title: this.$t('core.crud.attention'),
        subtitle: this.$t('core.crud.alertRemoveFilteredMessage')
      })
    },
    async confirmDeleteFilteredCompleted() {
      let confirm = await this.confirmDeleteFiltered()
      if (confirm) {
        await new Promise((x) => setTimeout(x))
        confirm = await this.confirmDeleteFilteredWithoutFilter()
      }
      return confirm
    },
    async onDeleteFilteredHandler() {
      const confirm = await this.confirmDeleteFilteredCompleted()
      if (confirm) {
        try {
          await this.service.deleteFiltered()
          const aggridApi = this.$refs?.gridComponent?.aggridApi
          if (aggridApi) {
            aggridApi.setFilterModel(null)
          }
          this.reloadData()
          this.notify.success({
            title: this.$tc('core.crud.alertItemRemovedSuccess')
          })
        } catch (err) {
          console.error('Failed to remove items', err)
          this.notify.error({
            title: this.$tc('core.crud.alertItemRemoveError')
          })
        }
      }
    }
  },
  computed: {
    toolbarActions() {
      return [
        {
          canShow: true,
          titleKey: 'core.misc.refresh',
          onClick: this.onReloadHandler,
          iconColor: '#959DB5',
          faIcon: 'fa-redo'
        },
        ...this.customToolbarActions,
        {
          canShow: this.canDelete && this.deleteFiltered,
          titleKey: 'core.misc.deleteFiltered',
          onClick: this.onDeleteFilteredHandler,
          iconColor: '#959DB5',
          faIcon: 'fa-trash'
        },
        {
          canShow: this.canImport,
          titleKey: 'core.misc.import',
          onClick: this.onImportHandler,
          iconColor: '#959DB5',
          faIcon: 'fa-upload'
        },
        {
          canShow: this.canExport,
          titleKey: 'core.misc.export',
          onClick: this.onExportHandler,
          iconColor: '#959DB5',
          faIcon: 'fa-download'
        }
      ]
    }
  }
}
</script>
