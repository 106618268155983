<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="service"
      :metadata="metadata"
      translatePrefix="integrationService.pages"
      @gridReady="onGridReady"
    />
  </div>
</template>

<script>
import { CargillCrudMetaView, helpers } from '@cargill/shared'
import createService from '../api/integrationLogService'
import { CrudAction } from '@brain/core'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      service: null
    }
  },
  methods: {
    async getMeta() {
      const meta = await this.service.getMeta()
      meta.permissions = [CrudAction.EXPORT]
      return meta
    },
    getGridComponent() {
      return helpers.findFirstComponent(this, ['BcGrid', 'CgGrid'])
    },
    onGridReady() {
      const gridOptions = this.getGridComponent().aggridOptions
      gridOptions.api.setSortModel([{ colId: 'date', sort: 'desc' }])
      gridOptions.api.onFilterChanged()
    }
  },
  created() {
    this.service = createService()
    this.getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>

<style scoped>
::v-deep .ag-selection-checkbox {
  display: none !important;
}
::v-deep .table-toolbar__select {
  visibility: hidden;
}
</style>
