import { defineStore } from 'pinia'

export const useCrudFilters = defineStore('crudFilter', {
  state: () => ({
    /**
     * CRUD filters object
     *
     * @example <caption>Example of filter object</caption>
     * filters: {
     *   crudMasterA: {
     *     lastFilterModel: {},
     *     lastSortModel: {},
     *     lastFilterPage: {},
     *   },
     *   'crudMasterA.crudDetailB': {
     *      lastFilterModel: {},
     *      lastSortModel: {},
     *      lastFilterPage: {},
     *   },
     *   crudC: {
     *     lastFilterModel: {},
     *     lastSortModel: {},
     *     lastFilterPage: {},
     *   },
     *   // ...
     * }
     */
    filters: {}
  }),

  getters: {
    /**
     * Get filter model of a crud
     * @param {*} state
     * @returns
     */
    getFilterModel: (state) => (crudName) => {
      const filterModel = state.filters[crudName]?.lastFilterModel
      return filterModel
    },
    /**
     * Get sort model of a crud
     * @param {*} state
     * @returns
     */
    getSortModel: (state) => (crudName) => {
      const sortModel = state.filters[crudName]?.lastSortModel
      return sortModel
    },
    /**
     * Get sort model of a crud
     * @param {*} state
     * @returns
     */
    getFilterPage: (state) => (crudName) => {
      const filterPage = state.filters[crudName]?.lastFilterPage
      return filterPage
    },
    /**
     * Get master id of a crud
     * @param {*} state
     * @returns
     */
    getMasterId: (state) => (crudName) => {
      const masterId = state.filters[crudName]?.masterId
      return masterId
    }
  },
  actions: {
    /**
     * Update filter model of a crud
     * @param {*} crudName
     * @param {*} filterModel
     */
    updateFilterModel(crudName, filterModel) {
      if (!this.filters[crudName]) {
        this.filters[crudName] = {}
      }
      this.filters[crudName].lastFilterModel = filterModel
    },
    /**
     * Update sort model of a crud
     * @param {*} crudName
     * @param {*} sortModel
     */
    updateSortModel(crudName, sortModel) {
      if (!this.filters[crudName]) {
        this.filters[crudName] = {}
      }
      this.filters[crudName].lastSortModel = sortModel
    },
    /**
     * Update filter page of a crud
     * @param {*} crudName
     * @param {*} sortModel
     */
    updateFilterPage(crudName, filterPage) {
      if (!this.filters[crudName]) {
        this.filters[crudName] = {}
      }
      this.filters[crudName].lastFilterPage = filterPage
    },
    /**
     * Update master id of a crud
     * @param {*} crudName
     * @param {*} sortModel
     */
    updateMasterId(crudName, masterId) {
      if (!this.filters[crudName]) {
        this.filters[crudName] = {}
      }
      this.filters[crudName].masterId = masterId
    },
    /**
     * Cleans the filter and sort data of a crud and also its details (if it is a master detail)
     * @param {*} crudName
     */
    cleanDataCascade(crudName) {
      const detailPrefix = `${crudName}.`
      const detailKeys = Object.keys(this.filters).filter((key) =>
        key.startsWith(detailPrefix)
      )
      const allKeys = [crudName, ...detailKeys]

      for (const key of allKeys) {
        this.filters[key] = {}
      }
    }
  }
})
