import api from './api'
import { createCrudService } from './cargillCrudService'

const service = createCrudService('/api/scenarioCargill', api)

service.getMeta = async () => (await api.get('/api/scenarioCargill/meta'))?.data

service.getMasterData = async () =>
  (await api.get('/api/scenarioCargill/getMasterData'))?.data

service.publish = async (id) => {
  try {
    return (await api.put(`/api/scenarioCargill/publish/${id}`))?.data
  } catch (error) {
    console.log(error)
    return { error }
  }
}

service.toggleScenarioPublic = async (id) => {
  try {
    return (await api.put(`/api/scenarioCargill/toggleScenarioPublic/${id}`))
      ?.data
  } catch (error) {
    console.log(error)
    return { error }
  }
}

service.unpublish = async (id) => {
  try {
    return (await api.put(`/api/scenarioCargill/unpublish/${id}`))?.data
  } catch (error) {
    console.log(error)
    return { error }
  }
}

service.importScenario = async function (file) {
  this.logRequest('importScenario')
  try {
    const response = await api.post(
      `/api/scenarioCargill/importScenario`,
      file,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    return response.data
  } catch (e) {
    this.handleError(e)
    throw e
  }
}

service.exportScenario = async function (id) {
  this.logRequest('exportScenario')
  try {
    const response = await api.get(
      `/api/scenarioCargill/exportScenario/${id}`,
      {
        responseType: 'blob'
      }
    )
    return response.data
  } catch (e) {
    this.handleError(e)
    throw e
  }
}

service.exportDataScenario = async function () {
  try {
    const response = await api.get(`/api/scenarioCargill/getExportAllData`, {
      responseType: 'blob'
    })
    return response.data
  } catch (e) {
    this.handleError(e)
    throw e
  }
}

service.getLastCreatedScenarios = async (_showAll = false) => {
  return (
    await api.get(
      '/api/scenarioCargill/getLastCreatedScenarios?showAll=' + _showAll
    )
  )?.data
}

service.validadeWizard = async (item) => {
  try {
    const response = await api.post(`/api/scenarioCargill/validadeWizard`, item)

    return response
  } catch (error) {
    return { error }
  }
}
export default service
