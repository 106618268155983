import locales from './gridLocalizations'

function addLocalization(language) {
  locales.addLocalization(this, language)
}

// function localeTextFunc(key, defaultValue) {
//   console.log('localeTextFunc', key, defaultValue)
//   if (key == 'dateFormatOoo') {
//     console.log(locales.localizations['pt-BR'][key] ?? defaultValue)
//     console.log('-------------------------------------------------')
//   }
//   return locales.localizations['pt-BR'][key] ?? defaultValue
// }

export default {
  addLocalization,
  popupParent: document.querySelector('body')
}
