<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="service"
      :metadata="metadata"
      translatePrefix="integrationService.pages"
      :gridMode="'clientSide'"
      :reloadData="reloadData"
      @onReloadData="reloadData = false"
    />
    <bc-dialog v-model="dialog" :width="900" :height="900">
      <bc-card class="modal">
        <div class="modal-header">
          <bc-card-title>
            <span class="text-uppercase mt-2 mb-2">
              {{
                $t('integrationService.pages.integrationTrigger.cronFrequence')
              }}
            </span>
          </bc-card-title>
        </div>
        <div class="container-modal">
          <bc-card-text>
            <div class="default">
              <cargill-cron ref="cargillCron" />
            </div>
          </bc-card-text>
        </div>

        <bc-card-actions>
          <bc-spacer></bc-spacer>
          <bc-btn color="link-grey" text @click="cancel()">
            {{ _cancelLabel }}
          </bc-btn>
          <bc-btn color="primary" text @click="confirm()">
            {{ _confirmLabel }}
          </bc-btn>
        </bc-card-actions>
      </bc-card>
    </bc-dialog>
  </div>
</template>

<script>
import { CargillCrudMetaView, CargillCron } from '@cargill/shared'
import createService from '../api/integrationTriggerService'
import {
  BcDialog,
  BcCard,
  BcCardTitle,
  BcCardText,
  BcCardActions,
  BcSpacer,
  BcBtn,
  CrudAction
} from '@brain/core'

export default {
  name: 'IntegrationTrigger',
  components: {
    CargillCrudMetaView,
    CargillCron,
    BcDialog,
    BcCard,
    BcCardTitle,
    BcCardText,
    BcCardActions,
    BcSpacer,
    BcBtn
  },
  data() {
    return {
      metadata: null,
      service: null,
      dialog: false,
      nodeInEdition: {},
      reloadData: false
    }
  },
  computed: {
    _confirmLabel() {
      return this.$t('integrationService.pages.integrationTrigger.confirm')
    },
    _cancelLabel() {
      return this.$t('integrationService.pages.integrationTrigger.cancel')
    }
  },
  methods: {
    async confirm() {
      this.dialog = false
      const cronExpression = this.$refs['cargillCron'].cronExpression
      let nodeData = this.nodeInEdition
      nodeData.cronExpression = cronExpression
      await this.service.customEditCron(nodeData)
      this.clearNodeInEdition()
      this.reloadData = true
    },
    cancel() {
      this.dialog = false
      this.clearNodeInEdition()
    },
    clearNodeInEdition() {
      this.nodeInEdition = {}
    },
    triggerIsPaused(triggerData) {
      return (
        triggerData.triggerState ===
        this.$t('integrationService.pages.integrationTrigger.paused')
      )
    },
    async getMeta() {
      const meta = await this.service.getMeta()
      meta.actions = [
        {
          title: ({ node }) => {
            return this.triggerIsPaused(node.data)
              ? this.$t('integrationService.pages.integrationTrigger.resume')
              : this.$t('integrationService.pages.integrationTrigger.pause')
          },
          className: ({ node }) => {
            return this.triggerIsPaused(node.data)
              ? 'fas fa-play'
              : 'fas fa-pause'
          },

          enable: true,
          callback: async ({ node }) => {
            if (this.triggerIsPaused(node.data)) {
              await this.service.customResume(node.data)
            } else {
              await this.service.customPause(node.data)
            }
            this.reloadData = true
          }
        },
        {
          title: this.$t('integrationService.pages.integrationTrigger.edit'),
          className: 'fas fa-clock',
          enable: true,
          callback: async ({ node }) => {
            this.dialog = true
            this.nodeInEdition = node.data
            this.reloadData = true
          }
        },
        {
          title: this.$t('integrationService.pages.integrationTrigger.delete'),
          className: 'fas fa-trash',
          enable: true,
          callback: async ({ node }) => {
            await this.$brain.confirm(
              {
                title: this.$t('core.crud.attention'),
                subtitle: this.$tc('core.crud.alertRemoveMessage')
              },
              async () => {
                try {
                  await this.service.customDelete(node.data)
                  this.reloadData = true
                  this.notify.success({
                    title: this.$tc('core.crud.alertItemRemovedSuccess')
                  })
                } catch (err) {
                  console.error('Failed to remove item', err)
                  this.notify.error({
                    title: this.$tc('core.crud.alertItemRemoveError')
                  })
                }
              }
            )
          }
        }
      ]
      const removedPermissions = new Set([
        CrudAction.DELETE,
        CrudAction.UPDATE,
        CrudAction.EXPORT,
        CrudAction.IMPORT
      ])
      meta.permissions = meta.permissions.filter(
        (p) => !removedPermissions.has(p)
      )
      return meta
    }
  },
  created() {
    this.service = createService()
    this.getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>

<style></style>
