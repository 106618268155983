import {
  router,
  pinia,
  useTransactions,
  useModuleOpen,
  authService,
  useScenarioOpen
} from '@cargill/shared'
import routes from './routes'

const simpleRoutes = routes.map((route) => {
  const children = route.children.filter((child) => !child.isDynamicRoute)
  return { ...route, children: children }
})

router.addRoutes(simpleRoutes)

// Essa não é a forma ideal de resolver o problema.
// O ideal seria indicar na declaração da rota (via params ou props) que é uma rota de cenário semanal.
// Por algum motivo, as props não estavams sendo reconhecidas dentro da função before-each
const weeklyScenarioRoutes = []

router.beforeEach(async (to, from, next) => {
  const { canRead } = useTransactions(pinia)
  const { getModuleOpen, isModuleOpen } = useModuleOpen(pinia)
  const { isScenarioOpen, isScenarioWeekly } = useScenarioOpen(pinia)
  const authRequired = !to.public
  const isLoggedIn = authService.isLoggedIn()

  if (authRequired && !isLoggedIn) {
    authService.doLogin()
  } else {
    const canAcessPage = !to.meta.transaction || canRead(to.meta.transaction)
    const itIsLoggedInLoginPage =
      (to.name === 'Login' || to.name === 'LoginForm') && isLoggedIn

    const cantAccessPageWithCurrentModule =
      to.name !== 'Home' &&
      (!isModuleOpen() ||
        (to.module != null && !to.module.includes(getModuleOpen())))

    const cantAccessWeeklyScenarioRoute =
      weeklyScenarioRoutes.includes(to.name) && !isScenarioWeekly()

    const cantAccessPageWithoutScenario =
      to.name !== 'Home' && !isScenarioOpen()

    if (
      !canAcessPage ||
      itIsLoggedInLoginPage ||
      cantAccessPageWithCurrentModule ||
      cantAccessPageWithoutScenario ||
      cantAccessWeeklyScenarioRoute
    ) {
      next({ name: 'Home' })
      return
    }
    next()
  }
})
