import api from './api'
import { authService, handleError } from '@cargill/shared'

const allScopes = ['create', 'view', 'update', 'delete', 'import', 'export']

const service = {}

service.createTransaction = (scopes) => {
  const notAllowedScopes = allScopes.filter((scope) => !scopes.includes(scope))
  const transaction = {}
  scopes.forEach((scope) => (transaction[scope] = true))
  notAllowedScopes.forEach((scope) => (transaction[scope] = false))
  return transaction
}

service.createTransactions = (userSettings) => {
  return Object.keys(userSettings.permissions).reduce((acc, transaction) => {
    acc[transaction] = service.createTransaction(
      userSettings.permissions[transaction]
    )
    return acc
  }, {})
}

service.get = async () => {
  const response = await api.get('/api/userSettings')
  return response?.data
}

service.update = async (userSettings) => {
  try {
    const response = await api.put('/api/userSettings', userSettings)
    await authService.updateToken(null, 999_999_999)

    return response?.data
  } catch (e) {
    handleError(e)
    throw e
  }
}

export default service
