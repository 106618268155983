<template>
  <div>
    <div :class="{ 'main-toolbar': !hasMasterId }">
      <div
        :class="{ 'buttons-container--detail': hasMasterId }"
        v-bind:style="{ display: gridHasEmptyData ? 'none' : 'flex' }"
        class="buttons-container"
      >
        <bc-btn v-show="canCreate" @click="onCreateHandler()" color="primary">
          <span>{{
            `${this.$t('core.misc.create')} ${getTitleCreateBtn()}`
          }}</span>
        </bc-btn>
      </div>
    </div>

    <div class="h-100" v-if="gridHasEmptyData">
      <bc-card class="empty-data-info-container">
        <bc-message
          type="info"
          icon="fa-info-square"
          :subtitle="$t('grid.whatDoYouWant')"
          :title="$t('grid.emptyList', [getTitle()])"
          :actions="[
            {
              type: 'button',
              hidden: !canImport,
              label: $t('core.misc.import'),
              color: 'link-gray',
              callback: onImportHandler
            },
            {
              type: 'button',
              hidden: !canCreate,
              label: $t('core.misc.create'),
              color: 'primary',
              callback: () => onCreateHandler()
            }
          ]"
        >
        </bc-message>
      </bc-card>
    </div>

    <div
      v-bind:style="{ display: gridHasEmptyData ? 'none' : 'block' }"
      class="h-100"
    >
      <bc-form-header-title :title="getTitle()"></bc-form-header-title>

      <bc-card class="panel-crud-list">
        <template slot="header"> </template>
        <div>
          <div class="table-toolbar">
            <div class="table-toolbar__select">
              <bc-checkbox
                v-if="selectableRow"
                class="table-toolbar__select__checkbox"
                dense
                hide-details
                color="primary"
                :value="selectAllItems"
                :indeterminate="selectIndeterminate"
                @change="onSelectMultipleHandler"
              ></bc-checkbox>

              <div
                v-if="selectableRow && canDelete && selectedIds.length > 0"
                @click="onBatchDelete(service, selectedIds, reloadData)"
                :title="this.$t('core.misc.delete')"
                class="table-toolbar__action text-uppercase white--text"
              >
                {{ $tc('core.crud.deleteSelected', selectedIds.length) }}
              </div>
            </div>
            <bc-crud-actions-toolbar
              :actions="toolbarActions"
            ></bc-crud-actions-toolbar>
          </div>
          <cg-grid
            inline
            ref="gridComponent"
            :gridOptions="_gridOptions"
            :getItems="service.getAll"
            :countItems="service.getCount"
            :actions="mapActionButtons(actions)"
            :columnDefs="gridColumns"
            :filter="filter"
            :mode="gridMode"
            :rowData="gridData"
            :pageSize="pageSize"
            :startPage="startPage"
            @rowSelect="onRowSelectedHandler"
            @rowClick="onRowClickHandler"
            @gridReady="onGridReady($event)"
            @clientSideLoadData="$emit('clientSideLoadData')"
            @cellChanged="onCellValueChangedHandler"
            @filterChanged="onFilterChanged"
            @sortChanged="onSortChanged"
            @dataLoaded="$emit('dataLoaded', $event)"
            :selectable="selectableRow"
            :style="`height: ${gridHeight}px`"
            :customSetSize="customSetSize"
          />
        </div>
      </bc-card>

      <div
        class="buttons-container bottom-buttons-container"
        v-if="canUpdate && hasUpdate"
      >
        <bc-btn
          type="button"
          @click="onReloadHandler"
          color="link-gray"
          class="sm link-disable"
        >
          <span>{{ $t('core.misc.cancel') }}</span>
        </bc-btn>
        <bc-btn
          type="submit"
          @click="onBatchUpdateHandler"
          :disabled="hasErrors"
          color="primary"
          class="sm ml-5"
        >
          <span>{{ $t('core.misc.save') }}</span>
        </bc-btn>
      </div>
    </div>
  </div>
</template>

<script>
import CgGrid from './CgGrid.vue'
import { helpers } from '@brain/grid'
import { BcBtn, BcCheckbox } from '@brain/ui'
import FileDownload from 'js-file-download'

import { CrudAction } from '@brain/crud'
import { useCrudFilters } from '@brain/crud'
import {
  onBatchDeleteHandler,
  onDeleteHandler
} from '../../helpers/crudHandlers'
import BcCrudActionsToolbar from './BcCrudActionsToolbar.vue'
import BcFormHeaderTitle from './BcFormHeaderTitle.vue'

export default {
  name: 'BcCrudGrid',
  components: {
    BcBtn,
    BcCrudActionsToolbar,
    BcCheckbox,
    BcFormHeaderTitle,
    CgGrid
  },
  emits: ['create', 'update', 'delete'],
  props: {
    name: { type: String, require: true },
    masterName: { type: String },
    columns: { type: Array, default: () => [] },
    gridMode: { type: String },
    actions: { type: Array },
    gridData: { type: Array },
    gridHeight: { type: Number, default: 290 },
    masterId: { type: [Number, String] },
    selectableRow: { default: true, type: Boolean },
    service: { type: Object, require: true },
    translate: { type: Function },
    validationSchema: { type: Object, default: null },
    customSetSize: { type: Function, default: null },
    gridOptions: { default: () => {} },
    keepFilters: { require: true, type: Boolean },
    masterNameFilter: { type: String }
  },
  data() {
    return {
      gridActionBtns: {
        [CrudAction.UPDATE]: {
          title: this.$t('core.misc.update'),
          className: 'fas fa-edit',
          enable: true,
          callback: ({ node }) => {
            this.onEditHandler(node.data.id)
          }
        },
        [CrudAction.DELETE]: {
          title: this.$t('core.misc.delete'),
          className: 'fas fa-trash',
          enable: true,
          callback: ({ node }) => {
            this.onDelete(this.service, node.data.id, this.reloadData)
          }
        }
      },
      gridHasEmptyData: false,
      modifiedCells: {},
      onBatchDelete: onBatchDeleteHandler.bind(this),
      onDelete: onDeleteHandler.bind(this),
      selectAllItems: false,
      selectIndeterminate: false,
      selectedIds: [],
      validationResult: {},
      startPage: 0
    }
  },
  computed: {
    _gridOptions() {
      return {
        onPaginationChanged: this.onPaginationChangedHandler.bind(this),
        ...this.gridOptions
      }
    },
    canCreate() {
      return this.actions.includes(CrudAction.CREATE)
    },
    canDelete() {
      return this.actions.includes(CrudAction.DELETE)
    },
    canExport() {
      return this.actions.includes(CrudAction.EXPORT)
    },
    canImport() {
      return this.actions.includes(CrudAction.IMPORT)
    },
    canUpdate() {
      return this.actions.includes(CrudAction.UPDATE)
    },
    hasMasterId() {
      return this.masterId !== null && this.masterId !== undefined
    },
    filter() {
      return {
        masterName: this.masterName,
        masterId: this.masterId,
        objectFields: this.objectFields
      }
    },
    toolbarActions() {
      return [
        {
          canShow: true,
          titleKey: 'core.misc.refresh',
          onClick: this.onReloadHandler,
          iconColor: '#959DB5',
          faIcon: 'fa-redo'
        },
        {
          canShow: this.canImport,
          titleKey: 'core.misc.import',
          onClick: this.onImportHandler,
          iconColor: '#959DB5',
          faIcon: 'fa-upload'
        },
        {
          canShow: this.canExport,
          titleKey: 'core.misc.export',
          onClick: this.onExportHandler,
          iconColor: '#959DB5',
          faIcon: 'fa-download'
        }
      ]
    },
    gridColumns() {
      return this.columns.map((column) => ({
        ...column,
        cellClass: (params) => {
          const edited =
            !!this.modifiedCells?.[params.node.id]?.[params.colDef.field]
          return edited ? 'cell-edited' : ''
        },
        filterParams: {
          ...column.filterParams,
          gridHeight: this.gridHeight
        }
      }))
    },
    hasUpdate() {
      return Object.keys(this.modifiedCells).length > 0
    },
    pageSize() {
      return Math.floor((this.gridHeight - 90) / 40)
    },
    objectFields() {
      return this.columns
        .filter((o) => o.filterParams?.isObjectType === true)
        .map((o) => o.field)
    }
  },
  mounted() {
    this.updateEmptyData()
  },
  methods: {
    getFilterQueryParams() {
      const aggridApi = this.$refs.gridComponent.aggridApi
      const filterModel = aggridApi?.getFilterModel() ?? {}
      const queryParamsObject = helpers.buildQueryObject({
        filterModel: filterModel
      })

      if (!!this.masterName && this.hasMasterId) {
        queryParamsObject.filterQuery[this.masterName] = `eq:${this.masterId}`
      }

      return helpers.buildQueryParams(queryParamsObject)
    },
    clearInlineEditing() {
      this.modifiedCells = {}
      this.$refs.gridComponent.clearModifiedData()
    },
    async emitAsync(method, ...params) {
      const listener = this.$listeners[method] || this.$attrs[method]
      if (listener) {
        return await listener(...params)
      }
    },
    getTitle() {
      return this.translate(`${this.name}.title`)
    },
    getTitleCreateBtn() {
      if (this.translate(`${this.name}.titleBtn`).indexOf('.titleBtn') !== -1)
        return this.translate(`${this.name}.title`)

      return this.translate(`${this.name}.titleBtn`)
    },
    async hasErrorsInCurrentEditingCell() {
      if (this.$refs.gridComponent) {
        // Stop editing and check for errors
        const aggridApi = this.$refs.gridComponent.aggridApi
        const editingCell = aggridApi.getEditingCells()
        if (editingCell.length > 0) {
          const rowIndex = editingCell[0].rowIndex
          aggridApi.stopEditing()
          await new Promise((resolve) => setTimeout(resolve, 200))
          const rowNode = aggridApi.getDisplayedRowAtIndex(rowIndex)
          this.validate(rowNode, rowNode.data)
          return this.hasErrors
        }
      }
      return false
    },
    mapActionButtons(actions) {
      const baseActions =
        actions
          ?.map((action) => this.gridActionBtns[action])
          .filter((action) => !!action) || []

      const customActions =
        actions?.filter((action) => action && typeof action === 'object') || []

      return [...customActions, ...baseActions]
    },
    async onBatchUpdateHandler() {
      if (this.$refs.gridComponent) {
        if (await this.hasErrorsInCurrentEditingCell()) return

        const modiefiedRows = this.$refs.gridComponent.getModifiedRows()
        const entities = Object.entries(modiefiedRows).map(([rowId, dto]) => ({
          rowId,
          dto
        }))
        if (entities.length === 0) return

        const result = await this.service.batchUpdate(entities)

        if (!result) return

        this.clearInlineEditing()
        if (result.errors && Object.keys(result.errors).length) {
          this.validationResult.errors = result.errors
          this.$refs.gridComponent.aggridApi.redrawRows()
        } else {
          this.reloadData()
        }
      }
    },
    onCellValueChangedHandler(modifiedCells, event) {
      this.modifiedCells = modifiedCells
      this.validate(event.node, event.data)
      event.api.redrawRows({ rowNodes: [event.node] })
    },
    hasMasterNameFilter() {
      return this.masterNameFilter != null && this.masterNameFilter != ''
    },
    getFilterModelKey() {
      return this.hasMasterNameFilter()
        ? `${this.masterNameFilter}.${this.name}`
        : this.name
    },
    onSortChanged() {
      this.saveFilter()
    },
    saveFilter() {
      const gridOptions = this.$refs.gridComponent.aggridOptions
      const { updateFilterModel, updateSortModel } = useCrudFilters()
      const filterModelKey = this.getFilterModelKey()
      const filterModel = gridOptions.api?.getFilterModel()
      const sortModel = gridOptions.api?.getSortModel()
      updateFilterModel(filterModelKey, filterModel)
      updateSortModel(filterModelKey, sortModel)
    },
    restoreFilter() {
      const { getFilterModel, getSortModel, getFilterPage } = useCrudFilters()
      const filterModelKey = this.getFilterModelKey()
      const filterModel = getFilterModel(filterModelKey)
      const sortModel = getSortModel(filterModelKey)
      const filterPage = getFilterPage(filterModelKey)
      const gridOptions = this.$refs.gridComponent.aggridOptions
      this.$refs.gridComponent?.aggridApi.paginationGoToPage(filterPage)
      this.startPage = filterPage
      gridOptions.api.setFilterModel(filterModel)
      gridOptions.api.setSortModel(sortModel)
      gridOptions.api.onFilterChanged()
    },
    async onGridReady(params) {
      this.$emit('gridReady', params)
      await this.$nextTick() // Wait until $emits have been handled
      this.restoreFilter()
    },
    onFilterChanged(event) {
      this.saveFilter()
      helpers.resetFilters(event.api.gridOptionsWrapper.gridOptions)
    },
    async onCreateHandler() {
      await this.emitAsync('create', {
        masterId: this.masterId,
        name: this.name
      })
    },
    async onEditHandler(id) {
      await this.emitAsync('edit', {
        id,
        masterId: this.masterId,
        name: this.name
      })
    },
    async onExportHandler() {
      const result = await this.service.export(this.getFilterQueryParams())
      FileDownload(result.data, result.filename ?? `${this.name}.xlsx`)
    },
    onReloadHandler() {
      this.clearInlineEditing()
      this.reloadData()
    },
    async onImportHandler() {
      await this.emitAsync('import', {
        masterId: this.masterId,
        name: this.name
      })
    },
    onPaginationChangedHandler() {
      const { updateFilterPage } = useCrudFilters()

      const aggridApi = this.$refs.gridComponent?.aggridApi
      if (aggridApi) {
        const currentPage =
          this.$refs.gridComponent.aggridApi?.paginationGetCurrentPage()
        const filterModelKey = this.getFilterModelKey()
        updateFilterPage(filterModelKey, currentPage)

        aggridApi.deselectAll()
        this.selectedIds = []
      }
    },
    onRowSelectedHandler(ids) {
      this.selectedIds = ids.map((o) => o.id)
    },
    onRowClickHandler(node) {
      const focusedId = node?.data?.id
      this.$emit('select', {
        masterId: focusedId !== undefined ? focusedId : null
      })
    },
    onSelectMultipleHandler(selectAll) {
      const aggridApi = this.$refs.gridComponent.aggridApi
      if (selectAll) {
        const firstRow = aggridApi.getFirstDisplayedRow()
        const lastRow = aggridApi.getLastDisplayedRow()

        if (this.gridMode === 'serverSide') {
          aggridApi.forEachNode((node) => {
            if (node.id >= firstRow && node.id <= lastRow) {
              node.setSelected(true)
            }
          })
        } else {
          aggridApi.forEachNodeAfterFilterAndSort((node, index) => {
            if (index >= firstRow && index <= lastRow) {
              node.setSelected(true)
            }
          })
        }
      } else {
        aggridApi.deselectAll()
      }
    },
    async updateEmptyData() {
      // Show Import there are no entities.
      // Show NoRowsOverlay when no entities have been filtered
      let gridDataQuantity = await this.service.getCount()
      this.gridHasEmptyData = gridDataQuantity === 0
    },
    reloadData() {
      this.validationResult.errors = {}
      this.selectedIds = []
      this.updateEmptyData()
      if (this.$refs.gridComponent) {
        const currentPage =
          this.$refs.gridComponent.aggridApi.paginationGetCurrentPage()
        this.$refs.gridComponent.loadData(this.filter, currentPage)
        this.$emit('dataReload')
      }
    },
    validate(node, data) {
      if (!this.validationSchema) return

      if (!this.validationResult.errors) this.validationResult.errors = {}

      const id = node.id
      const validationErrors = {}

      try {
        this.validationSchema.validateSync(data, { abortEarly: false })
      } catch (e) {
        e.inner.forEach((error) => {
          validationErrors[error.path] = [error.message]
        })
      }

      this.validationResult.errors[id] = validationErrors
      this.$refs.gridComponent.aggridApi.redrawRows({ rowNodes: [node] })

      this.hasErrors = Object.keys(this.validationResult.errors).some(
        (k) => Object.keys(this.validationResult.errors[k]).length > 0
      )
    }
  },
  provide() {
    return {
      validationResult: this.validationResult
    }
  },
  watch: {
    masterId: {
      handler() {
        this.reloadData()
      }
    },
    selectedIds: {
      deep: true,
      handler() {
        const aggridApi = this.$refs.gridComponent.aggridApi
        const firstRow = aggridApi.getFirstDisplayedRow()
        const lastRow = aggridApi.getLastDisplayedRow()

        // Avoid checking when the grid is empty
        if (lastRow < firstRow) return

        const itemsInRange = lastRow - firstRow + 1
        const selectedItems = this.selectedIds.length

        this.selectIndeterminate =
          this.selectedIds.length > 0 && selectedItems < itemsInRange
        this.selectAllItems = selectedItems === itemsInRange
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-data-info-container {
  padding: 230px 0px !important;
}
.file-select {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 60px;
}
.panel-crud-list {
  margin-top: 4px;
}
.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &--detail {
    margin-bottom: 10px;
  }
}
.bottom-buttons-container {
  margin-top: 20px;
}
.detail-tabs {
  margin-top: 10px;
}

.table-toolbar {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;

  &__select {
    padding-left: 20px;
    display: flex;
    align-items: center;

    &__checkbox {
      &.v-input {
        height: unset;
        margin: 0;
        padding: 0;
      }
    }
  }

  &__action-container {
    display: flex;

    &.disabled {
      .table-toolbar__action {
        pointer-events: none;
        opacity: 0.3;
      }
    }
  }

  &__action {
    padding: 0 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;

    &:hover {
      opacity: 0.8;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    i {
      font-size: 16px;
    }
  }
}

/* TODO: Update this with theme variables */
::v-deep .ag-theme-brain .ag-row .cell-edited {
  background-color: #222222;
}
</style>
