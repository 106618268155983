<template>
  <div>
    <div class="scenario-parameter-container">
      <div v-for="parameterGroup in parametersData" :key="parameterGroup.code">
        <scenario-parameter-group-component
          :parameterGroup="parameterGroup"
          :ref="parameterGroup.code"
          :custom="custom"
        />
      </div>
    </div>
    <bc-snackbar v-model="snackbar" color="#CB0022">
      <span v-for="error in errors" :key="error" class="span-error">{{
        error
      }}</span>
      <template v-slot:action="{ attrs }">
        <bc-btn text v-bind="attrs" @click="snackbar = false">
          {{ $t('application.actions.close') }}
        </bc-btn>
      </template>
    </bc-snackbar>
  </div>
</template>

<script>
import ScenarioParameterGroupComponent from './ScenarioParameterGroupComponent'
import ScenarioParametersService from '../../api/scenarioParametersService'
import { BcBtn } from '@brain/core'
import { eventHub, useScenarioOpen } from '@cargill/shared'
import _ from 'lodash'

export default {
  name: 'ScenarioParameters',
  components: { ScenarioParameterGroupComponent, BcBtn },
  props: {
    custom: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      useScenarioOpen: null,
      parametersData: [],
      snackbar: false,
      errors: [],
      indexInternalMarket: 0,
      indexExternalMarket: 0,
      imAllowedMonthsInAdvanceBeforePositionMonthStartDate: 0,
      emAllowedMonthsInAdvanceBeforePositionMonthStartDate: 0
    }
  },
  async mounted() {
    this.useScenarioOpen = useScenarioOpen()
    this.parametersData = await ScenarioParametersService.getParameters()

    this.indexInternalMarket = this.parametersData.findIndex(
      (x) => x.code === 'InternalMarket'
    )
    this.imAllowedMonthsInAdvanceBeforePositionMonthStartDate =
      this.parametersData[this.indexInternalMarket].parameters.findIndex(
        (x) => x.code === 'IMAllowedMonthsInAdvanceBeforePositionMonthStartDate'
      )
    this.parametersData[this.indexInternalMarket].parameters[
      this.imAllowedMonthsInAdvanceBeforePositionMonthStartDate
    ].hidden = true

    this.indexExternalMarket = this.parametersData.findIndex(
      (x) => x.code === 'ExternalMarket'
    )
    this.emAllowedMonthsInAdvanceBeforePositionMonthStartDate =
      this.parametersData[this.indexExternalMarket].parameters.findIndex(
        (x) => x.code === 'EMAllowedMonthsInAdvanceBeforePositionMonthStartDate'
      )
    this.parametersData[this.indexExternalMarket].parameters[
      this.emAllowedMonthsInAdvanceBeforePositionMonthStartDate
    ].hidden = true
  },
  async created() {
    eventHub.$on('changeOptimizationParameter', this.onChangeParameter)
  },
  async beforeDestroy() {
    eventHub.$off('changeOptimizationParameter', this.onChangeParameter)
  },
  methods: {
    onChangeParameter(event) {
      if (event.paramater.code === 'FulfillIMBeforePositionMonthStartDate') {
        this.parametersData[this.indexInternalMarket].parameters[
          this.imAllowedMonthsInAdvanceBeforePositionMonthStartDate
        ].hidden = !event.value
      } else if (
        event.paramater.code === 'FulfillEMBeforePositionMonthStartDate'
      ) {
        this.parametersData[this.indexExternalMarket].parameters[
          this.emAllowedMonthsInAdvanceBeforePositionMonthStartDate
        ].hidden = !event.value
      }
    },
    getUpdatedGroups() {
      const parametersGroups = this.parametersData
      parametersGroups.forEach((parametersGroup) => {
        this.$refs[parametersGroup.code][0].getUpdatedValues()
      })
      return parametersGroups
    },
    validate() {
      const parametersGroups = this.getUpdatedGroups()
      this.errors = []
      var errors = this.validatePenaltyWeight(parametersGroups)
      if (this.hasEmptyFieldInGroups(parametersGroups)) {
        errors.push(this.$t('application.validation.validateRequiredFieldsAll'))
      }
      const integerErrors = this.validateIntegerInGroups(parametersGroups)
      if (this.useScenarioOpen.isScenarioWeekly()) {
        var errorsFlexibility =
          this.validateFlexibilityToExceedMaxApplication(parametersGroups)
        errors = [...errors, ...integerErrors, ...errorsFlexibility]
      } else {
        errors = [...errors, ...integerErrors]
      }
      if (errors.length > 0) {
        _.sortBy(errors)
        this.errors = errors
        this.snackbar = true
        return false
      }
      return true
    },
    hasEmptyFieldInGroups(_parametersGroups) {
      return _parametersGroups.some((parametersGroup) =>
        this.hasEmptyField(parametersGroup.parameters)
      )
    },
    hasEmptyField(_parameters) {
      return _parameters.some((parameter) => _.isEmpty(parameter.value))
    },
    validateIntegerInGroups(_parametersGroups) {
      var errors = []
      _parametersGroups.forEach((parametersGroup) =>
        this.validateInteger(parametersGroup.parameters, errors)
      )
      return errors.map((error) => this.$t(error.key, error.params))
    },
    validateInteger(_parameters, errors) {
      _parameters.forEach((parameter) => {
        if (
          parameter.type == 'integer' &&
          (parameter.value.includes('.') || parameter.value.includes(','))
        ) {
          errors.push({
            key: 'application.parameters.errors.notInteger',
            params: {
              field: this.$t(`application.parameters.items.${parameter.code}`)
            }
          })
        }
      })
    },
    validatePenaltyWeight(_parametersGroups) {
      var errors = new Set()
      var penaltyWeight = _parametersGroups.find(
        (x) => x.code == 'PenaltyWeight'
      )
      if (penaltyWeight == null) {
        return true
      }
      var valueByCode = {}
      penaltyWeight.parameters.forEach(
        (x) =>
          (valueByCode[x.code] = { code: x.code, value: parseInt(x.value) })
      )
      var values = Object.values(valueByCode)

      values.forEach((x) => {
        if (x.value < 1 || x.value > 1000) {
          errors.add({
            key: 'application.parameters.errors.penaltyWeight.outOfRange',
            params: { field: this.$t(`application.parameters.items.${x.code}`) }
          })
        }
      })

      Object.values(
        _.groupBy(
          values.filter((x) => x.value != 1),
          (x) => x.value
        )
      )
        .filter((x) => x.length > 1)
        .forEach((x) => {
          const fields = x
            .map((x) => this.$t(`application.parameters.items.${x.code}`))
            .join(', ')

          errors.add({
            key: 'application.parameters.errors.penaltyWeight.duplicateWeights',
            params: { fields: fields }
          })
        })

      return [...errors].map((error) => this.$t(error.key, error.params))
    },
    validateFlexibilityToExceedMaxApplication(_parametersGroups) {
      var errors = new Set()
      var parameterGroup = _parametersGroups.find(
        (x) => x.code == 'GeneralParameters'
      )
      var flexibilityToExceedMaxApplication = parameterGroup.parameters.find(
        (x) => x.code == 'FlexibilityToExceedMaxApplication'
      )
      if (flexibilityToExceedMaxApplication == null) {
        return true
      }
      if (flexibilityToExceedMaxApplication.value < 5) {
        errors.add({
          key: 'application.parameters.errors.penaltyWeight.outOfRangeFlexibility',
          params: {
            field: this.$t(
              `application.parameters.items.${flexibilityToExceedMaxApplication.code}`
            )
          }
        })
      }

      return [...errors].map((error) => this.$t(error.key, error.params))
    }
  }
}
</script>

<style lang="scss" scoped>
.scenario-parameter-container {
  background: var(--bc-panel-bg);
}
.span-error {
  display: inline-block;
}
</style>
