<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="service"
      :metadata="metadata"
      :details="details"
      translatePrefix="integrationService.pages"
    />
  </div>
</template>

<script>
import { CargillCrudMetaView, helpers } from '@cargill/shared'
import createService from '../api/integrationModelService'
import createIntegrationExecutionLogService from '../api/integrationExecutionLogService'
import createIntegrationOutboundsService from '../api/integrationOutboundsService'
import moment from 'moment'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      service: null,
      integrationExecutionLogService: null,
      integrationOutboundsService: null,
      details: {}
    }
  },
  methods: {
    formatDateTime(date) {
      return moment(date).format(this.$t('application.dateTimeFullFormat'))
    },
    getTime(stringDate) {
      return moment(stringDate, this.$t('application.dateTimeFullFormat'))
        .toDate()
        .getTime()
    },
    async getIntegrationExecutionLogServiceMeta() {
      const integrationExecutionLogMeta =
        await this.integrationExecutionLogService.getMetaWithValidation()
      helpers.processMetaDetails(
        integrationExecutionLogMeta,
        'integrationExecutionLog',
        'integrationId',
        'integration',
        this.integrationExecutionLogService
      )
      const datesFields = new Set(['startDate', 'endDate'])

      integrationExecutionLogMeta.fields
        .filter((field) => datesFields.has(field.id))
        .forEach((field) => {
          field.type = 'text'
          field.valueGetter = (params) => {
            return this.formatDateTime(params.data[field.id])
          }
          field.comparator = (dateA, dateB) =>
            this.getTime(dateA) - this.getTime(dateB)
        })
      return integrationExecutionLogMeta
    },
    async getIntegrationOutboundsMeta() {
      const integrationOutboundsMeta =
        await this.integrationOutboundsService.getMetaWithValidation()
      helpers.processMetaDetails(
        integrationOutboundsMeta,
        'integrationOutbounds',
        'integrationId',
        'integration',
        this.integrationOutboundsService
      )
      return integrationOutboundsMeta
    },
    async getMetaDetails() {
      return await Promise.all([
        this.getIntegrationExecutionLogServiceMeta(),
        this.getIntegrationOutboundsMeta()
      ])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        this.service.getMetaWithValidation(),
        this.getMetaDetails()
      ])
      meta.details = metaDetails
      meta.actions = [
        {
          title: this.$t('integrationService.pages.integrationModel.run'),
          className: 'fas fa-play',
          enable: true,
          callback: ({ node }) => {
            this.service.run(node.data.id, null)
          }
        }
      ]
      return meta
    }
  },
  created() {
    this.service = createService()
    this.integrationExecutionLogService = createIntegrationExecutionLogService()
    this.integrationOutboundsService = createIntegrationOutboundsService()
    this.getMeta().then((meta) => {
      this.details = helpers.createDetailsObject(meta)
      this.metadata = meta
    })
  }
}
</script>
