import { AuthService } from './keycloak.js'

const authService = new AuthService({
  realm: 'SupplyBrain',
  clientId: process.env.VUE_APP_AUTH_RESOURCE,
  url: process.env.VUE_APP_AUTH_URL
})

// authService.kc.onTokenExpired = () => {
//   console.log('expired ' + new Date())
//   authService
//     .updateToken(60)
//     .success((refreshed) => {
//       if (refreshed) {
//         console.log('refreshed ' + new Date())
//       } else {
//         console.log('not refreshed ' + new Date())
//       }
//     })
//     .error(() => {
//       console.error('Failed to refresh token ' + new Date())
//     })
// }

export { authService }
