/**
 * Cria os formatters para campos que tem opções estáticas e internacionalizáveis (ex: enum)
 *
 * @param {*} metadata Metadados da tela
 * @param {Array<string>} staticOptionFields Campos que tem opções estáticas e internacionalizáveis
 * @param {*} localization Localization com funcionalidades de  internacionalização
 * @param {Function?} getI18NKey Função que recebe metadata, field, value e retorna key internacionalizável
 */
const createStaticOptionsFormatters = (metadata, localization, getI18NKey) => {
  metadata.fields
    .filter((field) => field.options)
    .forEach((field) => {
      field.valueFormatter = (params) =>
        localization.$t(getI18NKey(metadata, field, params.value))
      field.valueFormatterForm = (value) =>
        localization.$t(getI18NKey(metadata, field, value))
    })
}

export default { createStaticOptionsFormatters }
