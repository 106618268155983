import { pinia } from './pinia'
import { api } from './api'
import utils from './utils'
import { authService } from './authService'
import {
  supplyTransactionStore,
  createSupplyTransactionStore
} from './stores/supplyTransactions'
import SupplyShared from '@supply/shared'
import { brainCore, router, i18n } from './brainCore'

export default (Vue, { supplyTransactionStoreSettings }) => {
  createSupplyTransactionStore({ ...supplyTransactionStoreSettings, pinia })
  Vue.use(SupplyShared, {
    pinia,
    brainCore,
    router,
    i18n,
    api,
    utils,
    authService,
    supplyTransactionStore
  })
}

export * from './api'
export * from './authService'
export * from './brainCore'
export * from './bus/eventhub'
export * from './components'
export * from './constants'
export * from './grid'
export * from './helpers'
export * from './pinia'
export * from './router'
export * from './stores'
export * from './utils'
export * from './views'
export * as sharedLocales from './locales'
