<template>
  <div class="chart-container">
    <svg
      :width="internalWidth"
      :height="internalWidth"
      :viewBox="`0 0 ${internalWidth} ${internalWidth}`"
      :style="`width: ${width}px; height: ${height}px;`"
    >
      <circle
        v-for="(d, i) in _dataset"
        :key="i"
        :r="radius"
        :cx="halfWidth"
        :cy="halfWidth"
        :stroke-width="halfWidth"
        :stroke-dasharray="circunference"
        fill="transparent"
        :style="`transform: translate(${halfWidth}px, ${halfWidth}px) rotate(${
          d.angleOffset
        }deg) translate(${-halfWidth}px, ${-halfWidth}px)`"
        :stroke-dashoffset="(1 - d.value) * circunference"
        :stroke="d.color"
      ></circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'PieChart',
  props: {
    width: {
      type: Number,
      default: 160
    },
    height: {
      type: Number,
      default: 160
    },
    dataset: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      internalWidth: 100,
      _dataset: []
    }
  },
  watch: {
    dataset: {
      deep: true,
      immediate: true,
      handler() {
        const totalValue = this.dataset.reduce(
          (total, curr) => total + curr.value,
          0
        )
        const baseAngleOffset = -90
        let acc = 0

        this._dataset = this.dataset.map((data) => ({
          value: data.value,
          color: data.color
        }))

        this._dataset.forEach((data) => {
          data.value = data.value / totalValue
          data.angleOffset = baseAngleOffset + acc * 360
          acc += data.value
        })
      }
    }
  },
  computed: {
    halfWidth() {
      return this.internalWidth / 2
    },
    radius() {
      return this.internalWidth / 4
    },
    circunference() {
      return 2 * Math.PI * this.radius
    }
  }
}
</script>

<style lang="scss" scoped>
svg {
  circle {
    transition: all 0.5s ease-in-out;
    transform: translate(50px, 50px) rotate(90deg) translate(-50px, -50px);
  }
}
</style>
