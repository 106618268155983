import _ from 'lodash'

function findComponent(root, componentName) {
  if (root?.$options?.name === componentName) {
    return root
  }
  const children = root?.$children ?? []
  for (const child of children) {
    const component = findComponent(child, componentName)
    if (component) {
      return component
    }
  }
  return null
}

function findComponentOrPolling(root, componentName, delay, resolve) {
  const component = findComponent(root, componentName)
  if (component != null) {
    resolve(component)
  } else {
    setTimeout(
      () => findComponentOrPolling(root, componentName, delay, resolve),
      delay
    )
  }
}

function findComponentPolling(root, componentName, delay) {
  delay = delay ?? 150
  return new Promise((resolve) => {
    findComponentOrPolling(root, componentName, delay, resolve)
  })
}

function findComponents(root, componentsNames) {
  return componentsNames.map((x) => findComponent(root, x))
}

function findFirstComponent(root, componentsNames) {
  if (!_.isArray(componentsNames)) {
    return null
  }
  for (const componentName of componentsNames) {
    const component = findComponent(root, componentName)
    if (component != null) {
      return component
    }
  }
  return null
}

export default {
  findComponent,
  findComponents,
  findFirstComponent,
  findComponentPolling
}
