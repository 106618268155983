<template>
  <v-container class="offset">
    <v-row align="center" justify="end">
      <bc-btn color="primary" text @click="doCancel">{{ cancel }}</bc-btn>
      <bc-btn color="primary" text @click="doBack" v-if="back">{{
        back
      }}</bc-btn>
      <bc-btn color="primary" @click="doNext">{{ next }}</bc-btn>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'ActionButtons',
  props: {
    cancel: {
      type: String
    },
    next: {
      type: String
    },
    back: {
      type: String
    }
  },
  methods: {
    doNext(_next) {
      this.$emit('next', _next)
    },
    doBack(_back) {
      this.$emit('back', _back)
    },
    doCancel(_cancel) {
      this.$emit('cancel', _cancel)
    }
  }
}
</script>
<style scoped>
.container {
  margin-right: inherit;
}
.offset {
  margin-top: 1rem;
}
</style>
