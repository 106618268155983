export default {
  aggrid: {
    // Set Filter
    selectAll: 'Selecionar tudo',
    searchOoo: 'Procurar',
    blanks: 'Vazio',
    selectAllSearchResults: 'Selecionar todos os resultados da pesquisa',
    noMatches: 'Sem correspondências',

    // Number Filter & Text Filter
    filterOoo: 'Filtrar',
    equals: 'Igual',
    notEqual: 'Não é igual',
    blank: 'Em branco',
    notBlank: 'Não em branco',
    empty: 'Escolha um',

    // Number Filter
    lessThan: 'Menor que',
    greaterThan: 'Maior que',
    greaterThanOrEqual: 'Maior ou igual',
    lessThanOrEqual: 'Menor ou igual',
    inRange: 'No intervalo',
    inRangeStart: 'a partir de',
    inRangeEnd: 'até',

    // Text Filter
    contains: 'Contém',
    startsWith: 'Começa com',
    endsWith: 'Termina com',
    notContains: 'Não contém',

    // Date Filter
    dateFormatOoo: 'DD-MM-YYYY',

    // Filter Conditions
    andCondition: 'E',
    orCondition: 'OU',

    // Filter Buttons
    applyFilter: 'Aplicar filtro',
    resetFilter: 'Limpar filtro',
    clearFilter: 'Limpar filtro',
    cancelFilter: 'Cancelar',

    // Filter Titles
    textFilter: 'Text Filter',
    numberFilter: 'Number Filter',
    dateFilter: 'Date Filter',
    setFilter: 'Set Filter',

    // Side Bar
    columns: 'Colunas',
    filters: 'Filtros',

    // columns tool panel
    pivotMode: 'Modo pivô',
    groups: 'Grupos',
    rowGroupColumnsEmptyMessage: 'Colunas do grupo de linhas vazias',
    values: 'Valores',
    valueColumnsEmptyMessage: 'Valores de colunas vazias',
    pivots: 'Pivôs',
    pivotColumnsEmptyMessage: 'Pivôs de colunas vazias',

    // Header of the Default Group Column
    group: 'Group',

    // Row Drag
    rowDragRows: 'linhas',

    // Other
    loadingOoo: 'Carregar',
    noRowsToShow: 'Não há registros para mostrar.',
    enabled: 'Habilitado',

    // Menu
    pinColumn: 'Pinar coluna',
    pinLeft: 'Pinar <<',
    pinRight: 'Pinar >>',
    noPin: 'Sem pinagem',
    valueAggregation: 'Agregar valor',
    autosizeThiscolumn: 'Redimensionar esta coluna',
    autosizeAllColumns: 'Redimensionar todas colunas',
    groupBy: 'Agrupar por',
    ungroupBy: 'Desagrupar por',
    addToValues: 'Add ${variable} to values',
    removeFromValues: 'Remove ${variable} from values',
    addToLabels: 'Add ${variable} to labels',
    removeFromLabels: 'Remove ${variable} from labels',
    resetColumns: 'Resetar colunas',
    expandAll: 'Expandir tudo',
    collapseAll: 'Contrair tudo',
    copy: 'Copiar',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Copiar com cabeçalho',
    copyWithGroupHeaders: 'Copy with Group Headers',
    paste: 'Colar',
    ctrlV: 'Ctrl+V',
    toolPanel: 'Painel de ferramentas',
    export: 'Exportar',
    csvExport: 'Exportar para CSV',
    excelExport: 'Exportar para Excel',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Soma',
    min: 'Mínimo',
    max: 'Máximo',
    none: 'Nenhum',
    count: 'Contagem',
    average: 'Média',
    avg: 'Média',
    filteredRows: 'Filtered',
    selectedRows: 'Selected',
    totalRows: 'Total Rows',
    totalAndFilteredRows: 'Rows',
    more: 'mais',
    to: 'para',
    of: 'de',
    page: 'Página',
    first: 'Primeiro',
    last: 'Último',
    next: 'Próximo',
    previous: 'Anterior',
    nextPage: 'Next Page',
    lastPage: 'Last Page',
    firstPage: 'First Page',
    previousPage: 'Previous Page',

    // Pivoting
    pivotColumnGroupTotals: 'Total',

    // tool panel
    rowGroupColumns: 'Colunas do grupo de linhas',
    valueColumns: 'Valores das colunas',
    toolPanelButton: 'Botão de painel de ferramentas',

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: '.',
    decimalSeparator: ','
  }
}
