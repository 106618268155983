<template>
  <div v-if="data !== null">
    <bc-row>
      <span
        class="text-uppercase ml-3 mt-2 mb-1 bc-font-size--10 group-text-color"
        >{{ getLabel() }}</span
      >
    </bc-row>
    <bc-checkbox :label="getLabel()" v-model="data" @change="onChange" />
  </div>
</template>

<script>
import { BcCheckbox } from '@brain/ui'
import { eventHub } from '@cargill/shared'

export default {
  name: 'ScenarioParameterCheckbox',
  components: { BcCheckbox },
  data() {
    return {
      data: null
    }
  },
  props: {
    parameterData: {
      type: Object,
      require: true
    }
  },
  created() {
    this.data = this.convertToBoolean(this.parameterData.value)
  },
  methods: {
    onChange(value) {
      eventHub.$emit('changeOptimizationParameter', {
        paramater: this.parameterData,
        value
      })
    },
    convertToBoolean(value) {
      let valuestr = value.toLowerCase()
      return valuestr === 'true'
    },
    getLabel() {
      return this.$t(`application.parameters.items.${this.parameterData.code}`)
    },
    getData() {
      return this.data
    }
  }
}
</script>

<style lang="scss" scoped>
.group-text-color {
  color: var(--bc-toast-color);
}
</style>
