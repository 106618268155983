import ptBr from './locales/pt-BR'

const localizations = {
  'pt-BR': ptBr.aggrid
}

const addLocalization = (gridOptions, language = 'en') => {
  gridOptions.localeText = localizations[language]
}

export default {
  localizations,
  addLocalization
}
