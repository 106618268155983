<template>
  <div v-if="meta != null" class="bc-crud">
    <cg-crud-meta-view
      :service="service"
      :details="_details"
      :metadata="meta"
      :gridData="gridData"
      :gridMode="gridMode"
      @gridReady="onGridReady"
      @clientSideLoadData="onClientSideLoadData"
      :translatePrefix="translatePrefix"
      :validation-schema="_validationSchema"
      :gridOptions="gridOptions"
      :customGridSetSize="customGridSetSize"
      :defaultImport="defaultImport"
      :deleteFiltered="deleteFiltered"
    />
  </div>
</template>

<script>
import CgCrudMetaView from './CgCrudMetaView.vue'
import CargillCrudMetaView from '../CargillCrudMetaView.vue'

export default {
  name: 'CustomCgCrudMetaView',
  extends: CargillCrudMetaView,
  components: { CgCrudMetaView },
  props: {
    defaultImport: { type: Boolean, default: true },
    deleteFiltered: { type: Boolean, default: true }
  }
}
</script>
