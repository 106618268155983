<template>
  <div class="result-container">
    <import-result
      v-if="itemsSize == 1"
      :resultData="result[0]"
      :service="service"
      :name="name"
    />
    <template v-else>
      <bc-tabs v-model="tab" align-with-title>
        <bc-tab v-for="item in resultTabs" :key="item.id">
          {{ item.title }}
        </bc-tab>
      </bc-tabs>

      <bc-tabs-items v-model="tab">
        <bc-tab-item v-for="item in resultTabs" :key="item.id">
          <import-result :resultData="item" :service="service" :name="name" />
        </bc-tab-item>
      </bc-tabs-items>
    </template>
  </div>
</template>

<script>
import ImportResult from './import-result-components/ImportResult.vue'
import { BcTabItem, BcTabsItems } from '@brain/ui'

export default {
  name: 'ImportResultStep',
  components: { ImportResult, BcTabItem, BcTabsItems },
  data() {
    return {
      tab: null
    }
  },
  props: {
    value: { type: [Object, Array], require: true },
    name: {
      type: String,
      require: true
    },
    service: {
      type: Object,
      require: true
    }
  },
  computed: {
    result() {
      return Array.isArray(this.value) ? this.value : [this.value]
    },
    resultTabs() {
      return [this.consolidatedItem, ...this.result]
    },
    consolidatedItem() {
      var initial = {
        id: 0,
        totalRows: 0,
        insertedRows: 0,
        updatedRows: 0,
        errorRows: 0,
        hideTable: true,
        title: this.$t('core.import.result.consolidated')
      }
      return this.result.reduce((accum, curr) => {
        accum['totalRows'] += curr.totalRows
        accum['insertedRows'] += curr.insertedRows
        accum['updatedRows'] += curr.updatedRows
        accum['errorRows'] += curr.errorRows
        return accum
      }, initial)
    },
    itemsSize() {
      return Array.isArray(this.result) ? this.result.length : 0
    }
  },
  methods: {
    async validate() {
      return true
    }
  }
}
</script>

<style>
.result-container {
  margin-top: 10px;
}
.v-tab {
  display: grid;
}
</style>
