<template>
  <cg-crud-view
    v-if="metadata && show"
    :name="name"
    :label="label"
    :selectableRow="selectableRow"
    :service="service"
    :grid-columns="gridColumns"
    :validation-schema="_validationSchema"
    :form-fields="formFields"
    :actions="actions"
    :grid-mode="gridMode"
    :grid-data="gridData"
    :grid-height="gridHeight"
    :translate="translate"
    :tryTranslateOtherLocale="tryTranslateOtherLocale"
    :details="_details"
    :customGridSetSize="customGridSetSize"
    :gridOptions="gridOptions"
    :keepFilters="keepFilters"
    :keepFiltersOnExitCrud="keepFiltersOnExitCrud"
    :customToolbarActions="metadata.customToolbarActions"
    :defaultImport="defaultImport"
    :deleteFiltered="deleteFiltered"
    @gridReady="$emit('gridReady')"
    @clientSideLoadData="$emit('clientSideLoadData')"
  />
</template>

<script>
import CgCrudView from './CgCrudView.vue'
import { BcCrudMetaView } from '@brain/crud'
import {
  createCrudColumns,
  createCrudFormFields,
  createCrudDetailCrudColumns,
  createCrudDetailFormFields,
  parseToYup
} from '@brain/crud'

export default {
  name: 'CgCrudMetaView',
  extends: BcCrudMetaView,
  components: { CgCrudView },
  props: {
    defaultImport: { type: Boolean, default: true },
    deleteFiltered: { type: Boolean, default: true },
    keepFilters: { default: true, type: Boolean }
  },
  methods: {
    createMetaDataFields(metadata) {
      const permissions = metadata.permissions || []
      const actions = (metadata.actions || []).filter(
        (action) => !action.requires || permissions.includes(action.requires)
      )
      metadata.customToolbarActions ??= []
      this.processMetadata(metadata, this.service)
      ;(this.name = metadata.id),
        (this.label = this.translate(`${this.name}.title`))
      this.actions = [...permissions, ...actions]
      this.configService.getPostProcessing = metadata.getPostProcessing
      this.gridColumns = createCrudColumns(
        metadata,
        this.gridMode,
        this.translate,
        this.inline
      )
      this.formFields = createCrudFormFields(metadata, this.translate)
      this._validationSchema =
        this.validationSchema ||
        parseToYup(metadata, (key) => this.translate(`${this.name}.${key}`))
      this._details = metadata?.details?.map((detail) => {
        const detailProps = this.details?.[detail.id] || {}
        const detailActions = (detail.actions || []).filter(
          (action) =>
            !action.requires || detail.permissions.includes(action.requires)
        )
        detailProps.customToolbarActions ??= []
        this.processMetadata(detail, detailProps.service)
        return {
          id: detail.id,
          name: detail.id,
          masterName: detail.masterName,
          label: this.translate(`${this.name}.${detail.id}.title`),
          keyLabel: `${this.name}.${detail.id}.title`,
          validationSchema:
            detailProps.validationSchema ||
            parseToYup(detail, (key) =>
              this.translate(`${this.name}.${detail.id}.${key}`)
            ),
          actions: [...detail.permissions, ...detailActions],
          selectableRow:
            detailProps.selectableRow === undefined
              ? true
              : detailProps.selectableRow,
          service: detailProps.service,
          customToolbarActions: detail.customToolbarActions ?? [],
          columns: createCrudDetailCrudColumns(
            this.name,
            detail,
            this.gridMode,
            this.translate,
            detailProps.inline
          ),
          fields: createCrudDetailFormFields(this.name, detail, this.translate),
          gridMode: detailProps.gridMode || this.gridMode,
          gridData: detailProps.gridData,
          gridHeight: detailProps.gridHeight
        }
      })
      this.show = true
    }
  }
}
</script>

<style lang="scss" scoped></style>
