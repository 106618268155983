<template>
  <div>
    <bc-dialog v-model="dialog" :width="'55rem'">
      <bc-card class="modal">
        <div class="modal-header">
          <bc-card-title>
            {{ $t('controlTower.pages.stuffingPortal.comments') }}
          </bc-card-title>
        </div>
        <div class="container-modal">
          <div class="col-margin">
            <bc-row class="text-center">
              <bc-col
                cols="6"
                v-for="field in fields"
                :key="field"
                class="padding-override"
              >
                <div class="parameter">
                  <div>
                    {{ $t(`controlTower.pages.${page}.${field}`) }}
                  </div>

                  <div class="parameter-value">
                    <span>{{ formatParameter(field) }}</span>
                  </div>
                </div>
              </bc-col>
            </bc-row>
          </div>
        </div>
        <div class="container-modal">
          <div class="timeline-items">
            <v-timeline dense>
              <v-slide-x-reverse-transition group hide-on-leave>
                <v-timeline-item
                  v-for="comment in comments"
                  :key="comment.id"
                  :color="'#24BBEF'"
                  small
                  fill-dot
                >
                  <v-alert :value="true" dark class="alert-container">
                    <div class="message-container">
                      <div>
                        {{ formatDatetime(comment.date) }} -
                        <b>{{ comment.userName }}</b>
                      </div>
                      <div class="message-container__message">
                        {{ comment.message }}
                      </div>
                    </div>
                  </v-alert>
                </v-timeline-item>
              </v-slide-x-reverse-transition>
            </v-timeline>
          </div>

          <bc-card-actions class="container-comment">
            <bc-text-field
              filled
              v-model="comment.message"
              :label="label"
              class="input-spacing"
            ></bc-text-field>
          </bc-card-actions>
        </div>

        <bc-card-actions class="modal-footer">
          <bc-spacer></bc-spacer>
          <bc-btn @click="sendComment" color="primary" id="btn-send-comment">
            <bc-icon>fas fa-paper-plane</bc-icon>
          </bc-btn>
        </bc-card-actions>
      </bc-card>
    </bc-dialog>
  </div>
</template>

<script>
import {
  BcDialog,
  BcBtn,
  BcCard,
  BcCardTitle,
  BcCardActions,
  BcSpacer
} from '@brain/core'
import moment from 'moment'

export default {
  name: 'ScenarioImportModal',
  components: {
    BcDialog,
    BcBtn,
    BcCard,
    BcCardTitle,
    BcCardActions,
    BcSpacer
  },
  props: {
    show: {
      default: false,
      type: Boolean
    },
    currentRow: {},
    page: {
      type: String
    },
    comments: {
      type: Array,
      default: () => []
    },
    service: {
      required: true
    },
    fieldParentId: {
      type: String
    },
    fields: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      label: this.$t('controlTower.pages.stuffingPortal.comments'),
      comment: {
        message: ''
      },
      dialog: false
    }
  },
  methods: {
    clearComment() {
      this.comment.message = ''
    },
    formatParameter(field) {
      const value = this.currentRow[field]
      if (field.includes('month') && value != undefined) {
        return moment(value).format(this.$t('application.dateFormat'))
      }
      return value
    },
    formatDatetime(date) {
      return moment(date).format(this.$t('application.dateTimeFullFormat'))
    },
    async sendComment() {
      if (this.comment.message != '') {
        try {
          this.comment[this.fieldParentId] = this.currentRow.id
          const response = await this.service.create(this.comment)

          if (response.id != undefined) {
            const comment = await this.service.getById(response.id)
            this.comments.unshift(comment)
          } else throw new Error()

          this.clearComment()
          this.notify.success({
            title: this.$t(
              'controlTower.pages.stuffingPortal.commentSendSuccess'
            )
          })
        } catch (error) {
          this.clearComment()
          this.notify.error({
            title: this.$t('controlTower.pages.stuffingPortal.commentSendError')
          })
        }
      } else {
        this.notify.error({
          title: this.$t('controlTower.pages.stuffingPortal.emptyCommentError')
        })
      }
    }
  },
  watch: {
    show: function () {
      if (this.dialog != this.show) {
        this.dialog = this.show
      }
    },
    dialog: function () {
      this.$emit('input', this.dialog)
    }
  }
}
</script>

<style lang="scss" scoped>
.container-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  background-color: #262e39;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 4px;
}
.v-dialog .v-card .modal-footer {
  padding: 8px 0 8px 0;
}
.modal-header {
  background-color: #262e39;
}
.modal-header .v-card__title {
  color: rgb(149, 157, 181);
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  padding: 12px 20px 12px 20px;
}

.modal.v-card.brain {
  padding: 20px 20px 12px 20px;
}

#btn-send-comment {
  padding: 0 !important; //overrides v-btn 0 40px!
  min-width: 60px;
}
.message-container {
  color: #959db5;
  text-transform: uppercase;

  &__message {
    overflow-wrap: break-word;
    inline-size: 660px;
  }
}
.alert-container {
  background: #262c39;
}

.timeline-items {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 20px;
}

.container-comment {
  padding: 15px 8px 8px 8px;
}

/* start scrollbar */

::-webkit-scrollbar {
  width: 3px;
  height: 85px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #b9b9b9;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #27bee7;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #27bee7;
}
/* end scrollbar*/

.form-header__title {
  text-align: left;
  font: bold 14px Aller;
  letter-spacing: 0px;
  color: var(--bc-title-color);
  text-transform: uppercase;
}

.parameter-card {
  padding: 12px 20px;
}

.parameter-card-container {
  padding: 20px;
}

.parameter {
  padding: 12px 20px;
  background: #262c39;
  color: #959db5;
  text-align: left;
  min-height: 4.5rem;
}

.parameter-value {
  color: #24bbef;
}

.padding-override {
  padding: 5px !important;
}

.col-margin {
  margin-left: 25px;
  margin-right: 25px;
}

.parameter-field-container {
  background: var(--bc-bg-filds);
  margin-bottom: 5px;
  padding-top: 10px;
  padding-left: 10px;
}
</style>
