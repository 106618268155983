<template>
  <div :class="rootClass">
    <bc-dialog v-model="dialog" :width="width">
      <bc-card>
        <bc-card-title :class="headerClass">
          {{ title }}
        </bc-card-title>

        <bc-card-text>
          {{ content }}
        </bc-card-text>

        <bc-divider></bc-divider>

        <bc-card-actions>
          <bc-spacer></bc-spacer>
          <bc-btn :color="confirmColor" text @click="confirm()">
            {{ _confirmLabel }}
          </bc-btn>
          <bc-btn :color="cancelColor" text @click="cancel()">
            {{ _cancelLabel }}
          </bc-btn>
        </bc-card-actions>
      </bc-card>
    </bc-dialog>
  </div>
</template>
<script>
import {
  BcDialog,
  BcBtn,
  BcCard,
  BcCardTitle,
  BcCardText,
  BcDivider,
  BcCardActions,
  BcSpacer
} from '@brain/core'

export default {
  name: 'ConfirmDialog',
  components: {
    BcDialog,
    BcBtn,
    BcCard,
    BcCardTitle,
    BcCardText,
    BcDivider,
    BcCardActions,
    BcSpacer
  },
  props: {
    show: {
      default: false,
      type: Boolean
    },
    width: {
      default: '50rem',
      type: String
    },
    title: {
      default: 'Default title',
      type: String
    },
    content: {
      default: 'Default content',
      type: String
    },
    confirmLabel: {
      type: String
    },
    confirmColor: {
      default: 'primary',
      type: String
    },
    cancelLabel: {
      type: String
    },
    cancelColor: {
      default: 'link-gray',
      type: String
    },
    rootClass: {
      default: 'text-center',
      type: String
    },
    headerClass: {
      default: 'text-h5',
      type: String
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm')
      this.dialog = false
    },
    cancel() {
      this.$emit('cancel')
      this.dialog = false
    }
  },
  computed: {
    _confirmLabel() {
      return this.confirmLabel ?? this.$t('core.misc.yes')
    },
    _cancelLabel() {
      return this.cancelLabel ?? this.$t('core.misc.no')
    }
  },
  watch: {
    show: function () {
      if (this.dialog != this.show) {
        this.dialog = this.show
      }
    },
    dialog: function () {
      this.$emit('input', this.dialog)
    }
  }
}
</script>
<style scoped></style>
