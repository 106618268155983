import Keycloak from 'keycloak-js'

// export interface AuthServiceOptions {
//   realm: string
//   clientId: string
//   url: string
// }

// export interface AuthServiceTokenParsed {
//   exp?: number
//   iat?: number
//   nonce?: string
//   sub?: string
//   session_state?: string
//   realm_access?: AuthServiceRoles
//   resource_access?: AuthServiceResourceAccess
//   [key: string]: any
// }

// export interface AuthServiceRoles {
//   roles: string[]
// }

// export interface AuthServiceResourceAccess {
//   [key: string]: AuthServiceRoles
// }

// export type AuthServiceLoginOptions = KeycloakLoginOptions
// export type AuthServiceLogoutOptions = KeycloakLogoutOptions
// export type OnAuthenticatedCallback = (authenticated: boolean) => void
// export type OnUpdateTokenCallback = (updated: boolean) => void

export class AuthService {
  constructor(options) {
    this.options = options
    this.kc = new Keycloak(options)
  }

  /**
   * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
   *
   * @param onAuthenticatedCallback
   */
  async init(onAuthenticatedCallback) {
    try {
      const authenticated = await this.kc.init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/silent-check-sso.html',
        pkceMethod: 'S256'
      })

      if (authenticated) {
        setInterval(() => {
          this.kc.updateToken(70).catch(() => {
            this.doLogout()
          })
        }, 30000)

        onAuthenticatedCallback?.(authenticated)
      }
    } catch (ex) {
      // TODO: Add better error handling
      console.error(
        'AuthService: Failed to initialize authentication service',
        ex
      )
    }
  }

  /**
   * Redirects to login form.
   *
   * @param options The login options
   */
  doLogin(options) {
    this.kc.login(options)
  }

  /**
   * Redirects to logout.
   *
   * @param options Yhe logout options
   */
  doLogout(options) {
    this.kc.logout(options)
  }

  /**
   * The base64 encoded token that can be sent in the Authorization
   * header in requests to services.
   */
  getToken() {
    return this.kc.token
  }

  /**
   * The parsed token as a JavaScript object.
   *
   * @returns An object with the token data
   */
  getParsedToken() {
    return this.kc.tokenParsed || {}
  }

  /**
   * Determines whether the current user is a logged in.
   */
  isLoggedIn() {
    return !!this.kc.token
  }

  /**
   * If the token expires within `minValidity` seconds, the token is refreshed.
   * If the session status iframe is enabled, the session status is also checked.
   *
   * @param successCallback The callback invoked after the check is made
   * @param minValidity
   */
  async updateToken(successCallback, minValidity = 5) {
    try {
      const updated = await this.kc.updateToken(minValidity)
      if (successCallback) {
        successCallback(updated)
      }
    } catch (e) {
      console.log(e)
      this.doLogin()
    }
  }

  /**
   * Determine the current user preferred username
   */
  getUsername() {
    return this.kc.tokenParsed?.preferred_username
  }

  /**
   * Determine the current user name
   */
  getUserRealName() {
    return this.kc.tokenParsed?.name
  }

  /**
   * Determine the current user email
   */
  getEmail() {
    return this.kc.tokenParsed?.email
  }

  /**
   * Determine if the user has at least one of the `roles`
   * @param roles The list of roles to check
   */
  hasRole(roles) {
    return roles.some((role) => this.kc.hasRealmRole(role))
  }
}
