export default {
  application: {
    enums: {
      integrationStatus: {
        started: 'Started',
        authenticating: 'Authenticating',
        authenticated: 'Authenticated',
        authenticationError: 'Authentication Error',
        executingIntegrationInbound: 'Executing Integration Inbound',
        finishedIntegrationInbound: 'Finished Integration Inbound',
        integrationInboundError: 'Integration Inbound Error',
        executingIntegrationOutbounds: 'Executing Integration Outbounds',
        integrationOutboundsError: 'Integration Outbounds Error',
        finishedIntegrationOutbounds: 'Finished Integration Outbounds',
        finishedIntegration: 'Finished Integration',
        invalidSizeResponse: 'Invalid Size Response',
        error: 'Error'
      }
    }
  },
  integrationService: {
    general: {
      integration: 'Integration'
    },
    pages: {
      integrationAuthenticator: {
        title: 'Integration Authenticator',
        code: 'Code',
        service: 'Service',
        arguments: 'Arguments'
      },
      integrationModel: {
        title: 'Integration',
        code: 'Code',
        integrationInbound: 'Integration Inbound',
        arguments: 'Arguments',
        isRunning: 'Running',
        run: 'Run',
        errors: {
          alreadyRunning: 'Integration is already running',
          notFound: 'Integration not found'
        },
        integrationExecutionLog: {
          title: 'Integration Execution Log',
          integration: 'Integration',
          user: 'User',
          status: 'Status',
          startDate: 'Start Date',
          endDate: 'End Date',
          statusCode: 'Status Code',
          request: 'Request',
          response: 'Response'
        },
        integrationOutbounds: {
          title: 'Integration Outbounds',
          integration: 'Integration',
          integrationOutbound: 'Integration Outbound',
          order: 'Order'
        }
      },
      integrationInbound: {
        title: 'Integration Inbound',
        code: 'Code',
        service: 'Service',
        authenticator: 'Authenticator',
        arguments: 'Arguments'
      },
      integrationOutbound: {
        title: 'Integration Outbound',
        code: 'Code',
        service: 'Service',
        arguments: 'Arguments'
      },
      integrationTrigger: {
        title: 'Integration Triggers',
        integrationModel: 'Integration',
        triggerName: 'Name',
        triggerGroup: 'Group',
        previousExecution: 'Previous Execution',
        nextExecution: 'Next Execution',
        triggerState: 'State',
        cronExpression: 'Frequence of Execution',
        cronFrequence: 'Frequence of Execution',
        confirm: 'Confirm',
        cancel: 'Cancel',
        edit: 'Edit',
        resume: 'Start',
        pause: 'Pause',
        delete: 'Delete',
        startNow: 'Start Now',
        paused: 'Paused'
      },
      integrationReportEventRaw: {
        title: 'Integration Log',
        integrationType: 'Integration Type',
        entity: 'Entity',
        event: 'Event',
        message: 'Message',
        key: 'Key',
        validationEvent: 'Validation Event',
        rawDataJson: 'Data (json)',
        date: 'Date',
        validationEventOptions: {
          noContractReferenceCode: 'No contract reference code',
          noCompany: 'No company',
          noBillOfLandingDate: 'No bill of landing date',
          outdated: 'Outdated',
          noQuantityUnit: 'No quantity unit',
          unknownContractCodePattern: 'Unknown contract code pattern',
          noClientDeliveryCode: 'No client delivery code',
          noCustomerFound: 'Customer not found',
          noDeliveryCountry: 'No delivery country',
          noValidQuantity: 'No valid quantity',
          noValidInvoicedQuantity: 'No valid invoiced quantity',
          noPortOfDestination: 'No port of destination',
          noTerminalCode: 'No terminal code',
          noInvoiceDate: 'No invoice date',
          noCropYear: 'No crop year',
          noShippingLineCode: 'No shipping line code',
          noMaterial: 'No material',
          notExecuted: 'Not executed',
          noShippingPeriod: 'No shipping period',
          invalidShippingPeriod: 'Invalid shipping period',
          unknowMarketType: 'Unknow market type',
          matchingRuleFound: 'Matching rule found',
          openContractUpdate: 'Open contract update',
          unknownContract: 'Unknown contract',
          intercompany: 'Intercompany',
          noPort: 'No port',
          noPortCountry: 'No port country',
          cancelled: 'Cancelled',
          recap: 'Recap',
          reserved: 'Reserved',
          unknownWeightUnit: 'Unknown weight unit',
          noValidPrice: 'No valid price',
          noPriceUnit: 'No price unit',
          contractClaim: 'Contract Claim',
          noPriceCurrency: 'No price currency',
          unknownPriceCurrency: 'Unknown price currency',
          noIncoterm: 'No incoterm',
          unknownInconterm: 'Unknown inconterm',
          noPositionMonth: 'No position month',
          noShippingMonth: 'No shipping month',
          noCounterpartySector: 'No counterparty sector',
          unknownCounterpartySector: 'Unknown counterparty sector',
          noCounterparty: 'No counterparty',
          executed: 'Executed',
          noMatchingRuleFound: 'No matching rule found',
          noMarketType: 'No market type',
          allocated: 'Allocated',
          noStatus: 'No status',
          availableInventoryEmptyMarks: 'Available inventory, empty Marks',
          availableInventoryWarehouseFarm: 'Available inventory in Farm',
          availableInventoryWarehouseStorage: 'Available inventory in Storage',
          availableInventoryWarehouseRailwayStuffingTerminal:
            'Available inventory in railway stuffing terminal',
          availableInventoryWarehouseWaterwayStuffingTerminal:
            'Available inventory in waterway stuffing terminal',
          availableInventoryWarehousePortStuffingTerminal:
            'Available inventory in port stuffing terminal',
          unavailableInventoryUnknownWarehouse:
            'Unavailable inventory, unknown Warehouse',
          duplicatedKey: 'Duplicated key',
          unknownPurchaseContract: 'Unknown purchase contract',
          unknownUsbucket: 'Unknown US Bucket',
          unknownPurchaseContractCodePattern:
            'Unknown purchase contract code pattern',
          unknownWarehouse: 'Unknown warehouse',
          reservedForRecap: 'Reserved for recap',
          internalMarket: 'Internal market',
          externalMarket: 'External market',
          allocatedInternalMarket: 'Allocated internal market',
          allocatedExternalMarket: 'Allocated external market',
          notAllocated: 'Not allocated',
          reservedForContract: 'Reserved for contract',
          noPurchaseContract: 'No purchase contract',
          noLotId: 'No lot ID',
          invalidLotReferenceValue: 'Invalid lot reference value',
          noWarehouse: 'No warehouse',
          noNetWeight: 'No net weight',
          noGrossWeight: 'No gross weight',
          noWeightUnit: 'No weight unit',
          noBalesQuantity: 'No bales quantity',
          noBalesPerContainer: 'No bales per container',
          noInventoryMaterial: 'No inventory material',
          noPurchaseMaterial: 'No purchase material',
          invalidCampimValue: 'Invalid campim value',
          invalidCauleValue: 'Invalid caule value',
          invalidFumaginaValue: 'Invalid fumagina value',
          invalidPicaoValue: 'Invalid picao value',
          invalidSementeValue: 'Invalid semente value',
          invalidMinimumLengthValue: 'Invalid minimum length value',
          invalidMinimumStrengthValue: 'Invalid minimum strength value',
          invalidMinimumMicronaireValue: 'Invalid minimum micronaire value',
          invalidAverageLengthValue: 'Invalid average length value',
          invalidAverageStrengthValue: 'Invalid average strength value',
          invalidAverageMicronaireValue: 'Invalid average micronaire value',
          invalidMaximumLengthValue: 'Invalid maximum length value',
          invalidMaximumStrengthValue: 'Invalid maximum strength value',
          invalidMaximumMicronaireValue: 'Invalid maximum micronaire value',
          invalidShortFibreValue: 'Invalid short fibre value',
          noBrazilDraftDeadlineDate: 'No Brazil draft deadline date',
          noCargoDeadline: 'No cargo deadline',
          noBrazilPersonInCharge: 'No Brazil person in charge',
          noController: 'No controller',
          noShipLineRef: 'No ship line ref',
          noEstimatedShipmentDate: 'No estimated shipment date',
          noShippingLine: 'No shipping line',
          noJourneyNumber: 'No journey number',
          noVesselReference: 'No vessel reference',
          noDeliveryCountryCode: 'No delivery country code',
          noBrazilStatus: 'No Brazil status',
          noRelevantStatus: 'No relevant status',
          invalidStatusForCreateInstruction:
            'Invalid status for create instruction',
          instructionAlreadyExists: 'Instruction already exists',
          instructionNotFound: 'Instruction not found',
          instructionOutdated: 'Instruction outdated',
          noInvoiceDueDate: 'No invoice due date',
          noBillOfLadingDate: 'No bill of lading date',
          unloadingNotFound: 'Unloading not found',
          unloadingOutdated: 'Unloading outdated',
          loadingNotFound: 'Loading not found',
          loadingOutdated: 'Loading outdated',
          documentationNotFound: 'Documentation not found',
          documentationOutdated: 'Documentation outdated',
          reserveNotFound: 'Reserve not found',
          reserveOutdated: 'Reserve outdated',
          reserveRequestNotFound: 'Reserve request not found',
          reserveRequestOutdated: 'Reserve request outdated',
          noInstruction: 'No Instruction',
          noHaulierCode: 'No Haulier Code',
          noArrivalMinimumDate: 'No Arrival Minimum Date',
          noArrivalMaximumDate: 'No Arrival Maximum Date',
          invalidArrivalPeriod: 'Arrival date with invalid period',
          noUnloadingLine: 'No Unloading Line',
          noDriverLine: 'No Driver Line',
          nonMatchingBatch: 'Non Matching Batch',
          driverOutdated: 'Driver Outdated',
          matchingRuleFoundWithoutBatch: 'Matching Rule Found Without Batch',
          duplicateNewDriverLine: 'Duplicate New Driver Line',
          unknownInstructionCodePattern: 'Unknown Instruction Code Pattern',
          noLotVolume: 'No Lot Volume',
          noLoadedLotReference: 'No Loaded Lot Reference',
          noContractedLotReference: 'No Contracted Lot Reference',
          noPlanId: 'No PlanId',
          noVehiclePlate: 'No Vehicle Plate',
          invalidPurchCounterpartyTitle: 'Invalid Purchase Counterparty Title',
          noPremiumDiscountBrain:
            'There is no Premium/Discount for this contract and lot',
          premiumDiscountBrainNotApproved:
            'The Premium/Discount for this contract and lot has not yet been approved',
          premiumDiscountRegisteredOnItas:
            'Premium/Discount has been registered with ITAS',
          noImportsPreparationBrain:
            'There is no Imports Preparation for this contract and lot',
          noArrivalDateAtOrigin: 'No Arrival Date at Origin',
          noReserveLine: 'No Reserve Line',
          contractPrefixInvalid: 'Contract Prefix Invalid',
          brazilPurchaseTermsFob: 'Incoterm FOB',
          finishedInstruction: 'Finished instruction',
          marksPrefixInvalid: 'Invalid Marks prefix',
          noCasaInstruction: 'No CASA instruction',
          noFobInstruction: 'No FOB instruction',
          fobControlNotFound: 'FOB Control not found',
          fobControlOutdated: 'FOB Control outdated',
          noDestination: 'No destination',
          invalidDestination:
            'Invalid destination (Its not the stuffing terminal)',
          notificationOldReadDate: 'Old read date',
          invalidCounterparty: 'Invalid counterparty',
          unknownCounterPartyRef: 'Unknown Counter Party Ref',
          instructionWithChangedIncoterm: 'Instruction with changed Incoterm',
          noExpectedStartLoadDate: 'No expected start load date',
          noExpectedEndLoadDate: 'No expected end load date',
          invalidExpectedLoadPeriod: 'Expected load date with invalid period',
          noLotReference: 'No Lot Reference',
          unknownDestination: 'Unknown destination',
          noIncotermDetail: 'No Incoterm Detail',
          containersWithdrawalNotFound: 'Container Withdrawal not found',
          containersWithdrawalOutdated: 'Container Withdrawal outdated',
          matchingRuleFoundFreeGroups: 'Matching rule found for Free Groups',
          matchingRuleFoundWithErrorFreeGroups:
            'Matching rule found with error for Free Groups',
          finishedInstructionFreeGroups: 'Finished instruction for Free Groups',
          noDriverLineFreeGroups: 'No Driver Line for Free Groups',
          driverWithFreeGroupsCompleted: 'Driver with Free Groups completed',
          sameContractWithLessInformation:
            'Another Purchase Contract with more information was found',
          sameContractWithLessGroupNumber:
            'Another Purchase Contract with a higher Group Number was found',
          sameContractWithLessPlanId:
            'Another Purchase Contract with Plan Id zero was found',
          invalidCommodityCodePart5: 'Invalid Part5',
          invalidCommodityCodePart5OrPart4: 'Invalid Part5 or Invalid Part4',
          notProgrammed: 'Not programmed',
          matchingRuleFoundWithInvalidPremiumDiscount:
            'Matching rule found with invalid Premium/Discount',
          noBuyer: 'No Buyer',
          unknownBuyer: 'Unknown Buyer',
          buyerNotDomesticMarket: 'Buyer is not from Domestic Market',
          noSalesContract: 'No Sale Contract',
          unknownSalesContractCodePattern: 'Unknown Sale Contract code pattern',
          unknownCompany: 'Unknown Company',
          invalidWarehouseOrDestinationOrBuyer:
            'Invalid Warehouse, Destination or Buyer',
          warehouseNotStorage: 'Warehouse is not Stuffing Terminal or Storage',
          noWarehouseAndDestination: 'No Warehouse and Destination',
          unknownWarehouseAndDestination: 'Unknown Warehouse and Destination',
          warehouseAndDestinationNotStorage:
            'Warehouse and Destination not storage',
          hasAllocatedSale: 'Has Allocated Sale',
          invalidWarehouseOrDestination: 'Invalid Warehouse or Destination',
          sameLogisticPortalKeyOfAnotherLine:
            'There is a line of the same key that was used',
          invalidCostConversion: 'Invalid Cost Conversion',
          unknownPortOfDestination: 'Unknown Port of Destination',
          noBrazilDeliveryDeadlineDate: 'No Brazil Delivery Deadline Date',
          hasInvalidMarksForStorage: 'Marks is not valid for Storage',
          noLogisticStorageLine: 'No Storage line',
          contractMovedAfterFreeGroupsCompleted:
            'Contract moved from instruction after Free Groups completed',

          purchaseDeletedInItas: 'Purchase Contract deleted in ITAS',
          saleDeletedInItas: 'Sale Contract deleted in ITAS',
          bookingDeletedInItas: 'Booking deleted in ITAS',
          shipmentDeletedInItas: 'Shipment deleted in ITAS',
          inventoryDeletedInItas: 'Inventory deleted in ITAS'
        }
      },
      httpRequestIntegrationLog: {
        title: 'Integration Request Log',
        timestamp: 'Timestamp',
        url: 'URL',
        method: 'Method',
        requestHeaders: 'Request Headers',
        requestBody: 'Request Body',
        statusCode: 'Status Code',
        responseHeaders: 'Response Headers',
        responseBody: 'Response Body',
        responseBodyLength: 'Response Body Length',
        responseTimeMs: 'Response Time (ms)',
        errorMessage: 'Error Message',
        classError: 'Class Error'
      }
    }
  }
}
