<template>
  <div>
    <bc-btn
      icon
      class="filter-icon"
      :max-width="30"
      :max-height="30"
      @click="showModal"
      :disabled="!permission"
      :title="
        mode == 'create'
          ? $t('application.actions.create')
          : $t('application.actions.update')
      "
    >
      <bc-icon>fas {{ btnIcon }}</bc-icon>
    </bc-btn>
    <bc-dialog v-model="dialog" :width="width">
      <bc-card class="modal">
        <div class="modal-header">
          <bc-card-title>
            {{ name }}
          </bc-card-title>
        </div>
        <div class="container-modal">
          <!-- insert code here -->
          <div>
            <div class="main-toolbar"></div>
            <bc-form
              ref="formContainer"
              v-if="ready"
              v-slot="{ form }"
              :initial-values="initialValues"
              :yup-schema="_validationSchema"
              @submit.prevent="onSubmitHandler"
              @value="onFormValueChangeHandler"
            >
              <bc-card class="form-card">
                <div class="form-input-container">
                  <template v-for="field in _fields">
                    <div
                      :key="field.id"
                      v-if="!field.hide"
                      class="form-input"
                      :class="fieldContainerClass(field)"
                    >
                      <component
                        :is="fieldRenderer(field)"
                        v-bind="field"
                        v-on="$listeners"
                        :type="field.type"
                        :editable="!itemData[field.id].disabled"
                        :placeholder="`${$t('core.misc.enter')} ${
                          field.label
                        }...`"
                        :options="itemData[field.id].options"
                        :name="field.id"
                        :value="form.values[field.id]"
                        :error-messages="
                          (form.touched[field.id] && form.errors[field.id]) ||
                          ''
                        "
                        :required="!!requiredFields[field.id]"
                        :persistent-hint="!!field.hint"
                        :hint="field.hint ? $t(field.hint) : undefined"
                        @blur="form.onBlur"
                        @change="form.onChange(field.id, $event, field.type)"
                        @input="form.onInput(field.id, $event, field.type)"
                      />
                    </div>
                  </template>
                </div>
              </bc-card>
              <div class="form-footer">
                <bc-btn
                  type="button"
                  @click.prevent="onCancelHandler"
                  class="sm link-disable link-gray"
                >
                  <span>{{ $t('core.misc.cancel') }}</span>
                </bc-btn>
                <bc-btn
                  type="submit"
                  :disabled="!form.isValid && validated"
                  class="sm primary ml-5"
                >
                  <span>{{ $t('core.misc.save') }}</span>
                </bc-btn>
              </div>
            </bc-form>
          </div>
        </div>
        <bc-card-actions class="modal-footer">
          <bc-spacer></bc-spacer>
        </bc-card-actions>
      </bc-card>
    </bc-dialog>
  </div>
</template>

<script>
import { BcBtn, BcForm, BcCard, InputType } from '@brain/ui'
import { BcCrudFormInput } from '@brain/crud'
import { eventHub } from '@cargill/shared'
import { BcDialog } from '@brain/core'
import BcFormHeaderTitle from '../BcFormHeaderTitle.vue'

export default {
  name: 'ModalCrudForm',
  components: {
    BcDialog,
    BcBtn,
    BcCrudFormInput,
    BcForm,
    BcFormHeaderTitle,
    BcCard
  },
  props: {
    name: {
      type: String,
      require: true
    },
    fields: {
      type: Array,
      require: true
    },
    service: {
      type: Object,
      require: true
    },
    details: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      require: true
    },
    translate: {
      type: Function,
      require: true
    },
    validationSchema: {
      type: Object,
      require: true
    },
    mode: {
      type: String,
      require: true
    },
    width: {
      type: String,
      require: true
    },
    btnIcon: {
      type: String,
      default: 'fa-plus'
    },
    permission: {
      type: Boolean,
      default: true
    },
    serviceOptions: {},
    itemId: null
  },
  data: () => ({
    itemData: {},
    inputFields: [],
    validated: false,
    ready: false,
    initialValues: {},
    dialog: false
  }),
  created() {
    this.inputFields = this.fields.reduce((list, input) => {
      if (input.children) return [...list, ...input.children]
      return [...list, input]
    }, [])

    eventHub.$on('update-radio', async (args) => {
      const { colId, value } = args

      this.onFormValueChangeHandler({ key: colId, value: value })
      this.initialValues = this.createInitialValues()
    })
  },
  async mounted() {
    if (this.isDetailForm) {
      this.setInput(this.detailForm.masterName, {
        value: this.$route.params.masterId
      })
    }

    this.setInput('id', {
      value: this.itemId
    })
    this.setDefaultInputValues()
    this.initialValues = this.createInitialValues()

    this.ready = true
  },
  methods: {
    createInitialValues() {
      return Object.keys(this.itemData).reduce((values, inputId) => {
        const value = this.itemData[inputId].value
        values[inputId] = value === undefined ? null : value
        return values
      }, {})
    },
    fieldRenderer(field) {
      return field.fieldRenderer || BcCrudFormInput
    },
    fieldContainerClass(field) {
      const fullRowTypes = [
        InputType.SWITCH,
        InputType.CHECKBOX,
        InputType.TEXTAREA
      ]
      return {
        'form-input--hidden': this.itemData[field.id].hidden,
        'col-md-12': fullRowTypes.includes(field.type),
        'col-md-6': !fullRowTypes.includes(field.type)
      }
    },
    async onCancelHandler() {
      this.dialog = false
    },
    async onFormValueChangeHandler({ key, value }) {
      const oldValue = this.itemData[key]?.value
      const field = this._fields.find(({ id }) => id === key)

      if (value !== oldValue) {
        this.itemData[key].value = value
        await this.loadFieldOptions(key)
        field?.onValueChange?.(value, this.itemData)
      }
    },
    async onSubmitHandler() {
      const form = this.$refs.formContainer.form
      // Validate the form before submiting
      const isValid = await form.validate()
      this.validated = true
      if (!isValid) {
        return
      }

      // this.$bc.helper.preloader.show()
      try {
        const data = form.values

        let response = null
        if (this.isCreateForm) {
          response = await this._service.create(data, {
            masterId: this.serviceOptions
          })
        } else {
          response = await this._service.update(data)
        }

        if (response?.errorItems) {
          response.errorItems.forEach((errorItem) => {
            errorItem.fieldErrors.forEach((errorField) => {
              this.$refs.formContainer.form.errors[errorField.fieldName] =
                this.$t(errorField.keyMessage)
            })
          })
          this.notify.error({
            title: this.$t('error.saveUpdateError')
          })
        } else {
          this.notify.success({
            title: this.isCreateForm
              ? this.$t('core.crud.createSuccess')
              : this.$t('core.crud.updateSuccess')
          })
          this.onCancelHandler()
          this.reloadTableAndCloseModal()
        }
        // this.$bc.helper.preloader.hide()
      } catch (ex) {
        console.error('Failed to submit changes', ex)
        this.notify.error({
          title: this.$t('error.unexpectedError')
        })
        // this.$bc.helper.preloader.hide()
      }
    },
    async getItemData() {
      const itemId = this.itemData.id?.value
      if (itemId != null) {
        // this.$bc.helper.preloader.show()
        try {
          const data = await this._service.getById(itemId)
          this._fields.forEach(({ id, editable, hide, options }) => {
            this.setInput(id, {
              value: data[id],
              hidden: hide,
              disabled: this.isCreateForm ? false : !editable,
              options
            })
          })
        } catch (ex) {
          console.error('Failed to get item data', ex)
        } finally {
          // this.$bc.helper.preloader.hide()
        }
      } else {
        this.setDefaultInputValues()
      }
      this.initialValues = this.createInitialValues()
      await this.dispatchFirstChange()
    },
    setDefaultInputValues() {
      this._fields.forEach(({ id, defaultValue, editable, hide, options }) => {
        this.setInput(id, {
          value: defaultValue === undefined ? null : defaultValue,
          hidden: hide,
          disabled: this.isCreateForm ? false : !editable,
          options
        })
      })
    },
    setInput(key, inputData) {
      const previousData = this.itemData[key] || {
        value: null,
        hidden: false,
        disabled: false
      }
      this.$set(this.itemData, key, { ...previousData, ...inputData })
    },
    async dispatchFirstChange() {
      await this.loadFieldOptions()

      Object.keys(this.itemData).forEach((inputId) => {
        const field = this._fields.find(({ id }) => id === inputId)
        const value = this.itemData[inputId].value
        field?.onValueChange?.(value, this.itemData)
      })
    },
    async loadFieldOptions(changedFieldId) {
      const itemValue = Object.keys(this.itemData).reduce(
        (res, key) => ({
          ...res,
          [key]: this.itemData[key].value
        }),
        []
      )

      await Promise.all(
        this._fields.map(async (field) => {
          if (
            typeof field.options === 'function' &&
            (!changedFieldId ||
              !field.dependencies ||
              field.dependencies.has(changedFieldId))
          ) {
            field.dependencies = field.dependencies || new Set()

            const proxy = new Proxy(itemValue, {
              get: (target, prop) => {
                field.dependencies.add(prop)
                return target[prop]
              },
              set: () => undefined
            })

            this.itemData[field.id].options = await field.options({
              itemData: proxy
            })
          }
        })
      )
    },
    async showModal() {
      this.dialog = !this.dialog
      if (this.dialog) {
        await this.getItemData()
      }
    },
    reloadTableAndCloseModal() {
      this.dialog = false
      this.$emit('reloadTable')
    }
  },
  computed: {
    _title() {
      return this.translate(
        `${this.isDetailForm ? this.$route.params.parentRoute + '.' : ''}${
          this.$route.params.name
        }.title`
      )
    },
    _validationSchema() {
      return this.isDetailForm
        ? this.detailForm.validationSchema
        : this.validationSchema
    },
    isCreateForm() {
      return this.mode == 'create'
    },
    isDetailForm() {
      return (
        this.$route.params.masterId != null &&
        this.$route.params.masterId != undefined
      )
    },
    masterId() {
      return this.isDetailForm ? this.$route.params.masterId : undefined
    },
    detailForm() {
      const detailName = this.$route.params.name
      const detail = detailName
        ? this.details?.find((detail) => detail.name === detailName)
        : null

      return detail
    },
    _fields() {
      const fields = this.isDetailForm ? this.detailForm.fields : this.fields

      return fields.reduce((list, input) => {
        if (input.children) return [...list, ...input.children]
        return [...list, input]
      }, [])
    },
    _service() {
      return this.isDetailForm ? this.detailForm.service : this.service
    },
    requiredFields() {
      const yupRequiredName = 'required'
      const depValues = Object.keys(this._validationSchema.fields).reduce(
        (fields, key) => {
          const dependencies = this._validationSchema.fields[key].deps || []
          dependencies.forEach((dep) => {
            fields[dep] = this.itemData[dep]?.value
          })

          return fields
        },
        {}
      )

      const requiredFields = Object.keys(this._validationSchema.fields).reduce(
        (fields, key) => {
          const presence = this._validationSchema.fields[key].spec.presence
          if (presence === yupRequiredName) {
            fields[key] = true
          }
          return fields
        },
        {}
      )

      try {
        this._validationSchema.validateSync(depValues, { abortEarly: false })
      } catch (err) {
        err.inner.forEach(({ path, type }) => {
          if (type === yupRequiredName) {
            requiredFields[path] = true
          }
        })
      }

      return requiredFields
    }
  },
  watch: {
    itemData: {
      deep: true,
      handler() {
        const form = this.$refs.formContainer?.form
        if (form) {
          Object.keys(this.itemData).forEach((inputId) => {
            if (this.itemData[inputId].value !== form.values[inputId]) {
              form.values[inputId] = this.itemData[inputId].value
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-card {
  background: var(--bc-layer-2-bg);
  padding: 20px !important;
  margin-top: 10px;
}

.form-input-container {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;

  & > * {
    padding: 5px;
  }
}

.form-input {
  &--hidden {
    display: none;
  }
}

.form-footer {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
