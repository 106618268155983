<template>
  <div class="table-toolbar__action-container">
    <div v-for="action in actions" :key="action.titleKey">
      <div
        v-if="action.canShow"
        @click="action.onClick"
        :title="$t(action.titleKey)"
        class="table-toolbar__action"
      >
        <bc-icon :color="action.iconColor">{{ action.faIcon }}</bc-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BcCrudActionsToolbar',
  props: {
    actions: {
      require: true
    }
  }
}
</script>

<style lang="scss" scoped>
.table-toolbar {
  &__action-container {
    display: flex;

    &.disabled {
      .table-toolbar__action {
        pointer-events: none;
        opacity: 0.3;
      }
    }
  }

  &__action {
    padding: 0 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;

    &:hover {
      opacity: 0.8;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    i {
      font-size: 16px;
    }
  }
}
</style>
