import { i18n } from '@cargill/shared'
import ControlTower from '@cargill/controltower'
import workflow from '@supply/workflow'

import en from './en'
import ptBR from './pt-BR'

import { sharedLocales } from '@cargill/shared'

const locales = {
  en,
  'pt-BR': ptBR
}

import integrationEn from './integration-en'
import integrationPtBR from './integration-pt-BR'

const integrationLocales = {
  en: integrationEn,
  'pt-BR': integrationPtBR
}
const prefixServicesPages = [
  (x) => x?.controlTower?.pages,
  (x) => x?.application?.pages,
  (x) => x?.integrationService?.pages
]

const processLocales = (locales) => {
  const languages = Object.keys(locales ?? {})
  for (const language of languages) {
    processLocalesLang(locales[language])
  }
}

const processLocalesLang = (locale) => {
  if (!locale.application) {
    locale.application = {}
  }
  if (!locale.application.pages) {
    locale.application.pages = {}
  }
  const defaultLocale = locale.application.pages
  for (const prefixServicesPage of prefixServicesPages) {
    processLocalePages(defaultLocale, prefixServicesPage(locale))
  }
}

const processLocalePages = (localePagesDefault, localesPages) => {
  for (const key of Object.keys(localesPages ?? {})) {
    if (localesPages[key]?.title != null) {
      if (!localePagesDefault[key]) {
        localePagesDefault[key] = {}
      }
      localePagesDefault[key].title = localesPages[key].title
      processLocalePages(localePagesDefault, localesPages[key])
    }
  }
}

processLocales(locales)
processLocales(ControlTower.locales)
processLocales(integrationLocales)
processLocales(sharedLocales.default)

// Tem que registrar workflow primeiro, pois ele sobrescreve internacionalizações do core para en
i18n.registerLocale(workflow.locales)
i18n.registerLocale(locales)
i18n.registerLocale(ControlTower.locales)
i18n.registerLocale(integrationLocales)
i18n.registerLocale(sharedLocales.default)

export default locales
