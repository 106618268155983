<template>
  <bc-wizard-step-panel>
    <div class="default">
      <bc-dropzone
        v-model="files"
        :accept="['.xlsx', '.xls', '.pdf', '.PDF', '.XLSX', '.XLS']"
        :title="$t('core.import.select.description')"
        :action-label="$t('core.import.select.button')"
        height="600px"
      />
    </div>
    <div class="default">
      <bc-select
        v-model="template"
        :label="$t('application.misc.selectTemplate') + ' *'"
        :items="templateOptions"
        item-text="label"
        item-value="value"
        :errorMessages="errorMessages"
        @clearErrors="errorMessages = []"
      />
    </div>
  </bc-wizard-step-panel>
</template>

<script>
import { BcWizardStepPanel, BcDropzone, BcSelect } from '@brain/ui'
import _ from 'lodash'

export default {
  name: 'SelectFileStep',
  components: { BcWizardStepPanel, BcDropzone, BcSelect },
  props: {
    value: {
      type: Array
    },
    templateOptions: {
      type: Array
    }
  },
  data() {
    return {
      templateInput: {},
      errorMessages: [],
      hasValidate: false
    }
  },
  mounted() {
    this.hasValidate = false
  },
  methods: {
    getSelectedTemplate() {
      return this.template
    },
    isAllValid() {
      return this.files.length > 0 && this.requiredFieldValid()
    },
    isTemplateValid() {
      return !_.isEmpty(this.template?.value)
    },
    requiredFieldValid() {
      return this.isTemplateValid()
    },
    updateTemplateValidate() {
      this.errorMessages =
        !this.hasValidate || this.isTemplateValid()
          ? []
          : [this.$t('application.validation.validateFieldRequired')]
    },
    validate() {
      this.hasValidate = true
      if (this.isAllValid()) {
        return true
      }
      this.updateTemplateValidate()
      if (!this.requiredFieldValid()) {
        this.notify.error({
          title: this.$t('application.validation.validateFieldRequired')
        })
      }
      return false
    }
  },
  computed: {
    template: {
      get() {
        return this.templateInput
      },
      set(value) {
        this.templateInput = value
        this.updateTemplateValidate()
      }
    },
    files: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
