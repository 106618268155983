<template>
  <div class="list-wrapper">
    <div class="notification-label-container">
      <span class="notification-name-container">
        <div>
          <bc-icon class="notification-icon" color="#959DB5">{{
            notificationIcon
          }}</bc-icon>
          {{ notificationEventLabel(notification) }}
        </div>
        <div class="notification-name">
          <div>
            {{ formatMessage(notification) }}
          </div>
          <div>
            <bc-btn
              color="link-gray"
              id="btn-cancel"
              @click="markAsRead(notification)"
            >
              {{ $t('application.actions.markAsRead') }}
            </bc-btn>
          </div>
        </div>
        <div class="notification-status">
          <div>
            {{ `${$t('application.misc.module')}: ` }}
            {{ notificationModuleLabel(notification) }}
          </div>
          <div>{{ updateDateLabel(notification) }}</div>
        </div>
      </span>
    </div>
  </div>
</template>
<script>
import { useModuleOpen } from '@cargill/shared'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'NotificationBox',
  data() {
    return {}
  },
  props: {
    custom: {
      type: Boolean,
      default: false
    },
    notification: {
      type: Object,
      default: new Object()
    }
  },
  computed: {
    notificationDate() {
      return this.formatDate(this.notification.notificationDate)
    },
    translatePrefix() {
      const { getTranslatePrefix } = useModuleOpen()
      return getTranslatePrefix()
    },
    notificationIcon() {
      var icon = 'fas fa-info-circle'
      const notificationType =
        this.notification?.notificationType?.toUpperCase()
      if (notificationType === 'Information'.toUpperCase()) {
        icon = 'fas fa-info-circle'
      } else if (notificationType === 'Warning'.toUpperCase()) {
        icon = 'fas fa-exclamation-triangle'
      } else if (notificationType === 'Error'.toUpperCase()) {
        icon = 'fas fa-do-not-enter'
      }
      return icon
    }
  },
  methods: {
    formatMessage(notification) {
      const messageKey = notification.message
        .split('.')
        .map((x) => _.camelCase(x))
        .join('.')
      const translateKey = `${this.translatePrefix}.notificationMessages.${messageKey}`
      const args = _.isEmpty(notification.arguments)
        ? null
        : { ...JSON.parse(notification.arguments) }
      const result = this.$te(translateKey)
        ? this.$t(translateKey, args)
        : notification.message
      return result
    },
    updateDateLabel(notification) {
      const updateOn = this.$t('application.misc.notifiedOn')
      const dateTimeFormat = this.$t('application.dateTimeFullFormat')
      const formatedDate = moment(notification.notificationDate).format(
        dateTimeFormat
      )
      return `${updateOn}: ${formatedDate}`
    },
    markAsRead(notification) {
      this.$emit('mark-as-read', notification)
    },
    notificationEventLabel(notification) {
      return this.$t(
        `application.enums.notificationEvent.${_.camelCase(
          notification.notificationEvent
        )}`
      )
    },
    notificationModuleLabel(notification) {
      return this.$t(
        `application.enums.notificationModule.${_.camelCase(
          notification.notificationModule
        )}`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-icon {
  font-size: 15px;
  padding-right: 5px;
}
.list-wrapper {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  align-items: center;
  padding: 1rem;
  .action-icons {
    i {
      margin-left: 0.3rem;
      padding: 0.5rem;
      color: #959db5;
      cursor: pointer;
    }
  }
  .notification-label-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .notification-name-container {
      .notification-name {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 690.44px;
        align-items: center;
      }
      .notification-status {
        display: flex;
        justify-content: space-between;
        .status-container {
          display: flex;
        }
        .status-circle {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
      font-size: 14px;
      color: #959db5;
      display: flex;
      flex-direction: column;
      width: 100%;
      grid-gap: 10px;
    }
    span {
      font-weight: bold;
      color: #24bbef;
      letter-spacing: 0px;
    }
    i {
      color: #959db5;
    }
  }
}
</style>
