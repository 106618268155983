<template>
  <div v-if="metadata != null && gridReady" class="h-100 hide-scrollbar">
    <bc-form-header-title :title="getTitle()"></bc-form-header-title>
    <bc-card class="panel-crud-list">
      <div>
        <div class="table-toolbar">
          <div class="table-toolbar__end">
            <bc-crud-action-toolbar
              :actions="toolbarActions"
            ></bc-crud-action-toolbar>
          </div>
        </div>
        <bc-grid
          inline
          ref="treeGridComponent"
          :gridOptions="{
            treeData: true,
            rowModelType: 'serverSide',
            pagination: false,
            getServerSideGroupKey,
            isServerSideGroup,
            autoGroupColumnDef,
            ...defaultGridOptions
          }"
          :getItems="getItems"
          :countItems="getTreeDataCount"
          :columnDefs="columns"
          :filter="filter"
          :mode="gridMode"
          :pageSize="pageSize"
          :style="`height: ${635}px`"
          :customSetSize="customSetSize"
          @rowGroupOpened="onGroupOpened"
        />
      </div>
    </bc-card>
  </div>
</template>

<script>
import { BcGrid, helpers } from '@brain/grid'
import { createCrudColumns } from '@brain/crud'
import BcCrudActionToolbar from './CustomCargillCrud/BcCrudActionsToolbar.vue'
import BcFormHeaderTitle from './CustomCargillCrud/BcFormHeaderTitle.vue'
import FileDownload from 'js-file-download'
import { metadataHelper, gridUtils } from '@cargill/shared'

export default {
  components: { BcGrid, BcCrudActionToolbar, BcFormHeaderTitle },
  name: 'CargillTreeView',
  data() {
    return {
      currentParentId: null,
      isOnGroupOpenedRequest: {
        count: false,
        get: false
      },
      columns: null,
      gridMode: 'serverSide',
      translatePrefix: 'application.pages',
      filter: {
        masterId: 0
      },
      rowData: [],
      pageSize: 10000,
      name: null,
      gridReady: false,
      updatedMetadata: null,
      defaultGridOptions: gridUtils.defaultGridOptions
    }
  },
  props: {
    gridHeight: {
      type: Number,
      default: 635
    },
    metadata: {
      require: true
    },
    service: {
      require: true
    },
    getServerSideGroupKey: {
      require: true,
      type: Function
    },
    isServerSideGroup: {
      require: true,
      type: Function
    },
    autoGroupColumnDef: {
      require: true,
      type: Object
    }
  },
  methods: {
    customSetSize(aggridOptions) {
      gridUtils.resize(aggridOptions)
    },
    getTitle() {
      return this.translate(`${this.name}.title`)
    },
    onSelectMultipleHandler(selectAll) {
      const aggridApi = this.$refs.treeGridComponent.aggridApi
      if (selectAll) {
        const firstRow = aggridApi.getFirstDisplayedRow()
        const lastRow = aggridApi.getLastDisplayedRow()

        if (this.gridMode === 'serverSide') {
          let shiftIndex = 0
          aggridApi.forEachNode((node, index) => {
            let rowNode = aggridApi.getDisplayedRowAtIndex(index + shiftIndex)
            while (
              rowNode.data === undefined &&
              index + shiftIndex <= lastRow
            ) {
              shiftIndex++
              rowNode = aggridApi.getDisplayedRowAtIndex(index + shiftIndex)
            }
            if (
              index + shiftIndex >= firstRow &&
              index + shiftIndex <= lastRow
            ) {
              node.setSelected(true)
            }
          })
        } else {
          aggridApi.forEachNodeAfterFilterAndSort((node, index) => {
            if (index >= firstRow && index <= lastRow) {
              node.setSelected(true)
            }
          })
        }
      } else {
        aggridApi.deselectAll()
      }
    },
    onGroupOpened(event) {
      this.isOnGroupOpenedRequest.count = true
      this.isOnGroupOpenedRequest.get = true
      this.currentParentId = event.data.id
    },
    translate(key) {
      const translateKey = this.translatePrefix
        ? `${this.translatePrefix}.${key}`
        : key
      return this.$t(translateKey)
    },
    getTreeDataCount(filter) {
      const parentId = this.isOnGroupOpenedRequest.count
        ? this.currentParentId
        : null
      try {
        const count = this.service.getTreeDataCount(parentId, filter)
        return count
      } catch (error) {
        this.isOnGroupOpenedRequest.get = false
        throw error
      } finally {
        this.isOnGroupOpenedRequest.count = false
      }
    },
    async getItems(params) {
      const parentId = this.isOnGroupOpenedRequest.get
        ? this.currentParentId
        : null
      try {
        const data = await this.service.getTreeData(parentId, params)
        await metadataHelper.getPostProcessing(data, this.updatedMetadata)
        return data
      } catch (error) {
        this.isOnGroupOpenedRequest.count = false
        throw error
      } finally {
        this.isOnGroupOpenedRequest.get = false
      }
    },
    onReloadHandler() {
      this.$refs.treeGridComponent.loadData(this.filter)
    },
    async onExportHandler() {
      const result = await this.service.export(this.getFilterQueryParams())
      FileDownload(result.data, result.filename ?? `${this.name}.xlsx`)
    },
    getFilterQueryParams() {
      const aggridApi = this.$refs.treeGridComponent.aggridApi
      const filterModel = aggridApi?.getFilterModel() ?? {}
      const queryParamsObject = helpers.buildQueryObject({
        filterModel: filterModel
      })
      return helpers.buildQueryParams(queryParamsObject)
    }
  },
  provide() {
    return {
      validationResult: {}
    }
  },
  computed: {
    toolbarActions() {
      return [
        {
          canShow: true,
          titleKey: 'core.misc.refresh',
          onClick: this.onReloadHandler,
          iconColor: '#959DB5',
          faIcon: 'fa-redo'
        },
        {
          canShow: true,
          titleKey: 'core.misc.export',
          onClick: this.onExportHandler,
          iconColor: '#959DB5',
          faIcon: 'fa-download'
        }
      ]
    }
  },
  async created() {
    this.gridReady = false
    this.name = this.metadata.id
    await metadataHelper.buildMetadata(this.metadata, this.service)
    this.updatedMetadata = this.metadata
    this.columns = createCrudColumns(
      this.updatedMetadata,
      this.gridMode,
      this.translate,
      false
    )
    this.gridReady = true
  }
}
</script>

<style lang="scss" scoped>
.panel-crud-list {
  margin-top: 4px;
}

.table-toolbar {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: flex-end;

  &__select {
    padding-left: 20px;
    display: flex;
    align-items: center;

    &__checkbox {
      &.v-input {
        height: unset;
        margin: 0;
        padding: 0;
      }
    }
  }

  &__action-container {
    display: flex;

    &.disabled {
      .table-toolbar__action {
        pointer-events: none;
        opacity: 0.3;
      }
    }
  }

  &__action {
    padding: 0 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;

    &:hover {
      opacity: 0.8;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    i {
      font-size: 16px;
    }
  }
}

.hide-scrollbar {
  overflow-y: hidden;
}
</style>
