<template>
  <bc-card v-if="title">
    <span class="form-header__title">
      {{ title }}
    </span>
  </bc-card>
</template>

<style lang="scss" scoped>
.form-header__title {
  text-align: left;
  font: 14px Aller;
  letter-spacing: 0px;
  color: var(--bc-title-color);
  text-transform: uppercase;
}
</style>

<script>
import { BcCard } from '@brain/ui'
export default {
  name: 'BcFormHeaderTitle',
  components: { BcCard },
  props: {
    title: {
      type: String,
      require: false
    }
  }
}
</script>
