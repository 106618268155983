import VueCompositionAPI from '@vue/composition-api'
import BrainCore from '@brain/core'
import Vue from 'vue'
import { useCrudFilters } from '../stores'

Vue.use(VueCompositionAPI)
Vue.use(BrainCore)

export const brainCore = new BrainCore({
  i18n: {
    messages: {}
  },
  router: {
    routes: [],
    disableBreadCrumbGeneration: false,
    disablePrefixChildRoutesAutoComplete: false
  },
  grid: {
    aggridLicenseKey: process.env.VUE_APP_AGGRID_LICENSE_KEY,
    useCrudFilters: useCrudFilters
  }
})

export const router = brainCore.router
export const i18n = brainCore.i18n
export const vuetify = brainCore.vuetify
