import { defineStore } from 'pinia'
import { CrudAction } from '@brain/core'

let supplyTransactionStore

export const createSupplyTransactionStore = ({ fetchTransactions, pinia }) => {
  const useSupplyTransactionStore = defineStore('supplyTransactions', {
    state: () => ({
      /**
       * @type {Transactions | undefined}
       */
      transactions: undefined
    }),

    getters: {
      /**
       * Determines whether the current user has permission to create the
       * `transaction` resource.
       *
       * @param {String} transaction The resource to check
       */
      canCreate: (state) => (transaction) =>
        state.transactions &&
        !!state.transactions[transaction]?.includes(CrudAction.CREATE),

      /**
       * Determines whether the current user has permission to read the
       * `transaction` resource.
       *
       * @param {String} transaction The resource to check
       */
      canRead: (state) => (transaction) =>
        (state.transactions &&
          state.transactions[transaction] &&
          !!state.transactions[transaction]?.includes('read')) ||
        !!state.transactions[transaction]?.includes('view'),

      /**
       * Determines whether the current user has permission to update the
       * `transaction` resource.
       *
       * @param {String} transaction The resource to check
       */
      canUpdate: (state) => (transaction) =>
        state.transactions &&
        state.transactions[transaction] &&
        !!state.transactions[transaction]?.includes(CrudAction.UPDATE),

      /**
       * Determines whether the current user has permission to import the
       * `transaction` resource.
       *
       * @param {String} transaction The resource to check
       */
      canImport: (state) => (transaction) =>
        state.transactions &&
        state.transactions[transaction] &&
        !!state.transactions[transaction]?.includes(CrudAction.IMPORT),

      /**
       * Determines whether the current user has permission to export the
       * `transaction` resource.
       *
       * @param {String} transaction The resource to check
       */
      canExport: (state) => (transaction) =>
        state.transactions &&
        state.transactions[transaction] &&
        !!state.transactions[transaction]?.includes(CrudAction.EXPORT),

      /**
       * Determines whether the current user has permission to delete the
       * `transaction` resource.
       *
       * @param {String} transaction The resource to check
       */
      canDelete: (state) => (transaction) =>
        state.transactions &&
        state.transactions[transaction] &&
        !!state.transactions[transaction]?.includes(CrudAction.DELETE),

      /**
       * return the transactions by transaction resource
       *
       * @param {String} transaction The resource to get transactions type
       */
      getSupplyTransactions: (state) => (transaction) =>
        state.transactions ? state.transactions[transaction] : [],

      /**
       * return the transactions by transaction resource
       */
      getAllSupplyTransactions: (state) => () => state.transactions
    },

    actions: {
      async fetchSupplyTransactions() {
        let response = await fetchTransactions()
        this.transactions = response
      }
    }
  })
  supplyTransactionStore = useSupplyTransactionStore(pinia)
}

export { supplyTransactionStore }
