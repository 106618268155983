<template>
  <div class="dashboard">
    <div class="dashboard__chart-container">
      <div class="chart">
        <pie-chart
          :key="totalRows"
          :width="176"
          :height="176"
          :dataset="chartDataset"
        />
      </div>
      <div class="chart__rows-count">
        <div class="chart__rows-count__value">
          {{ totalRows }}
        </div>
        <div class="chart__rows-count__title">
          <div class="text-uppercase light-grey--text">
            {{ $t('core.import.result.lines') }}
          </div>
          <div class="text-uppercase light-grey--text">
            {{ $t('core.import.result.processed') }}
          </div>
        </div>
      </div>
      <div class="left-item total-rows"></div>
      <div class="left-item desc-text upper-case"></div>
    </div>
    <div class="dashboard__kpis-container">
      <div
        class="kpi"
        v-for="(item, i) in getDashboardItens()"
        :key="i"
        :style="{ borderColor: item.borderColor }"
      >
        <div class="kpi__caption">
          <span class="text-uppercase font-weight-bold light-grey--text">
            {{ item.total }}
          </span>
        </div>
        <div class="kpi__value" :style="{ color: item.borderColor }">
          <span class="kpi__value__icon">
            <i :class="`fas ${item.icon}`"></i>
          </span>
          <bc-tooltip top :color="item.borderColor">
            <template v-slot:activator="{ on, attrs }">
              <span class="kpi__value__text" v-bind="attrs" v-on="on">
                {{ Math.round(item.percent).toFixed(0) }}%
              </span>
            </template>
            <span class="kpi__value__text">
              {{ item.percent.toFixed(2) }}%
            </span>
          </bc-tooltip>
        </div>
        <div class="kpi__title">
          <div class="text-uppercase light-grey--text">
            {{ $t('core.import.result.lines') }}
          </div>
          <div class="text-uppercase light-grey--text">
            {{ item.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BcTooltip } from '@brain/ui'

import PieChart from './PieChart.vue'

export default {
  name: 'ImportResultKpis',
  components: { PieChart, BcTooltip },
  props: {
    resultData: { type: Object, require: true }
  },
  computed: {
    totalRows() {
      return this.resultData?.totalRows || 0
    },
    chartDataset() {
      return [
        {
          color: 'rgb(137, 205, 77)',
          value: (this.resultData?.insertedRows || 0) / this.totalRows
        },
        {
          color: 'rgb(85, 141, 238)',
          value: (this.resultData?.updatedRows || 0) / this.totalRows
        },
        {
          color: 'rgb(210, 53, 79)',
          value: (this.resultData?.errorRows || 0) / this.totalRows
        }
      ]
    }
  },
  methods: {
    getPercent(value, key) {
      if (!value || value.totalRows === 0) return 0
      const percent = (value[key] * 100) / value.totalRows
      return percent
    },
    getDashboardItens() {
      return [
        {
          icon: 'fa-times-octagon',
          borderColor: '#D2354F',
          total: this.resultData?.errorRows || 0,
          percent: this.getPercent(this.resultData, 'errorRows'),
          description: this.$t('core.import.result.withErrors')
        },
        {
          icon: 'fa-sync',
          borderColor: '#558DEE',
          total: this.resultData?.updatedRows || 0,
          percent: this.getPercent(this.resultData, 'updatedRows'),
          description: this.$t('core.import.result.updated')
        },
        {
          icon: 'fa-check',
          borderColor: '#89CD4D',
          total: this.resultData?.insertedRows || 0,
          percent: this.getPercent(this.resultData, 'insertedRows'),
          description: this.$t('core.import.result.inserted')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  display: flex;
  justify-content: space-between;
  min-height: 176px;
  color: #959db5;

  &__chart-container {
    display: flex;
    align-items: center;
  }

  &__kpis-container {
    display: flex;

    & > :not(:first-child) {
      margin-left: 20px;
    }
  }

  .chart {
    width: 176px;
    height: 176px;
    margin-left: 20px;
    margin-right: 35px;

    &__rows-count {
      display: flex;
      align-items: flex-end;

      &__title {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
      }

      &__value {
        color: #89cd4d;
        font-size: 62px;
        line-height: 1em;
      }
    }
  }

  .kpi {
    padding: 35px;
    min-width: 240px;
    border: 2px solid;
    display: flex;
    flex-direction: column;

    &__value {
      display: flex;
      align-items: center;
      font-size: 32px;

      &__text {
        font-weight: bold;
      }

      &__icon {
        width: 40px;
        margin-right: 10px;
      }
    }

    &__title {
      margin-left: 50px;
    }

    &__caption {
      margin-left: 50px;
    }
  }
}
</style>
