import { FieldType, createCrudFormFields, parseToYup } from '@brain/crud'
import { i18n } from '@cargill/shared'

const createTranslate = (translatePrefix) => {
  return (key, locale) => {
    const translateKey = translatePrefix ? `${translatePrefix}.${key}` : key
    return i18n.t(translateKey, locale)
  }
}

export const createMetaDataFields = (metadata, translatePrefix, service) => {
  const ctx = {}
  processMetadata(translatePrefix, metadata, service)
  const translate = createTranslate(translatePrefix)
  ctx.name = metadata.id
  ctx.formFields = createCrudFormFields(metadata, translate)
  ctx.validationSchema =
    metadata.validationSchema ||
    parseToYup(metadata, (key) => translate(`${ctx.name}.${key}`))
  ctx.translate = translate
  ctx.service = service

  return ctx
}

const processMetadata = (translatePrefix, metadata, service) => {
  setupFormOptionsLoader(metadata, service)
  setupDefaultValueFormatter(translatePrefix, metadata)
}

const setupFormOptionsLoader = (metadata, service) => {
  metadata.fields
    .filter(
      (field) =>
        [FieldType.SELECT, FieldType.MULTISELECT].includes(field.type) &&
        field.options !== null &&
        typeof field.options === 'object' &&
        !Array.isArray(field.options)
    )
    .forEach((field) => {
      const options = field.options
      field.options = async (params) => {
        return await service.getOptions(options.endpoint, params.filterQuery)
      }
    })
}

const setupDefaultValueFormatter = (translatePrefix, metadata) => {
  metadata.fields.forEach((field) => {
    if (field.propertyType === 'enum' && field.valueFormatter == null) {
      field.valueFormatter = (param) => {
        const translateKey = `${translatePrefix}.${metadata.id}.enums.${field.id}.${param.value}`
        return i18n.te(translateKey) ? i18n.t(translateKey) : param.value
      }
      const options = field.options
      field.returnObject = false
      field.options = () => {
        return options.map((value) => ({
          text: field.valueFormatter({ value }),
          value
        }))
      }
    }
  })
}
