<template>
  <div v-if="data !== null">
    <bc-numeric-field
      :label="getLabel()"
      :placeholder="getPlaceholder()"
      v-model="data"
    ></bc-numeric-field>
  </div>
</template>

<script>
import { BcNumericField } from '@brain/ui'

export default {
  name: 'ScenarioParameterInteger',
  components: { BcNumericField },
  data() {
    return {
      data: null
    }
  },
  props: {
    parameterData: {
      type: Object,
      require: true
    }
  },
  created() {
    this.data = this.parameterData.value
  },
  methods: {
    getLabel() {
      return this.$t(`application.parameters.items.${this.parameterData.code}`)
    },
    getPlaceholder() {
      const placeholderStart = this.$t(
        `application.parameters.misc.placeholder`
      )
      const parameter = this.$t(
        `application.parameters.items.${this.parameterData.code}`
      )
      return `${placeholderStart} ${parameter}`
    },
    getData() {
      return this.data
    }
  },
  watch: {
    data: function () {
      // if (this.data.includes('.')) {
      //   this.data = this.data.replace('.', '')
      // } else if (this.data.includes(',')) {
      //   this.data = this.data.replace(',', '')
      // }
    }
  }
}
</script>
