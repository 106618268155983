import Vue from 'vue'
import App from './App.vue'
import { authService, router, pinia, brainCore, notify } from '@cargill/shared'
import CargillShared from '@cargill/shared'
import { createCrudRoutes } from '@brain/core'
import { pt } from 'yup-locales'
import { setLocale } from 'yup'
import en from 'yup/lib/locale'
import Highcharts from 'highcharts'
import Stock from 'highcharts/modules/stock'
import HighchartsVue from 'highcharts-vue'
import More from 'highcharts/highcharts-more'
import highchartsGauge from 'highcharts/modules/solid-gauge'
import routes from './router/routes'
import _ from 'lodash'
import './router'
import './locales'
// import workflow from '@supply/workflow'
import plugins from './plugins'
import controltower from '@cargill/controltower'

Vue.use(plugins)
Vue.use(controltower.plugins)
// TODO: Remoção Workflow - Voltar quando voltar workflow
// Vue.use(workflow.plugins)

More(Highcharts)
Stock(Highcharts)
highchartsGauge(Highcharts)

Vue.use(HighchartsVue, { Highcharts })

Vue.config.productionTip = false

Vue.prototype.notify = notify
Vue.prototype.highchart = Highcharts

Vue.use(CargillShared, {
  supplyTransactionStoreSettings: {
    fetchTransactions: async () => {
      const userSettings = await userSettingsService.get()
      return userSettings.permissions
    }
  }
})

import {
  useTransactions,
  useUserSettings,
  userSettingsService,
  detailsDynamicRouterService,
  supplyTransactionStore
} from '@cargill/shared'

const boostrap = async () => {
  try {
    // Await fot the auth server to initialize before mounting the application
    await authService.init()

    const app = new Vue({
      pinia,
      ...brainCore,
      render: (h) => h(App)
    })

    addDynamicRoutes()
    await loadUserSettings()
    app.$mount('#app')
  } catch (ex) {
    console.error('Failed to bootstrap the application', ex)
  }
}

const addDynamicRoutes = async () => {
  const detailsRouters =
    await detailsDynamicRouterService.getAllDetailsRouters()
  const createDynamicRoutes = (route) => {
    const details = route.details
      .map((detail) => {
        const routers = _.get(
          detailsRouters,
          `${route.moduleName}.${route.name}.${detail}`,
          null
        )
        return routers == null ? detail : routers.map((x) => _.camelCase(x))
      })
      .flat()
    return createCrudRoutes(
      route.name,
      route.component,
      details,
      route.customUrlPath,
      route.translatePrefix
    )
  }
  const dynamicRoutes = routes.map((x) => {
    return {
      ...x,
      children: x.children
        .filter((child) => child.isDynamicRoute)
        .map((route) => createDynamicRoutes(route))
        .flat()
    }
  })

  router.addRoutes(dynamicRoutes)
}

const loadUserSettings = async () => {
  if (!authService.isLoggedIn()) {
    return
  }
  let [userSettings] = await Promise.all([
    userSettingsService.get(),
    supplyTransactionStore.fetchSupplyTransactions()
  ])

  if (userSettings?.permissions) {
    const { updateTransactions } = useTransactions()
    const { updateUser } = useUserSettings()
    const transactions = userSettingsService.createTransactions(userSettings)
    updateTransactions(transactions)
    updateUser(userSettings)
  }

  if (userSettings?.language == 'pt-BR') {
    setLocale(pt)
    brainCore.i18n.setLanguage('pt-BR')
  } else {
    setLocale(en)
    brainCore.i18n.setLanguage('en')
  }
}

boostrap()
