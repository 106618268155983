<template>
  <bc-wizard-step-panel>
    <div class="default">
      <div class="conclusion-box">
        <p class="icon"><i class="fas fa-check-square"></i></p>
        <p class="title">
          {{ $t('core.import.conclusion.importSuccess') }}
        </p>
        <p class="subtitle">{{ redirectMesage }}</p>
        <p class="ask">{{ $t('core.import.conclusion.ask') }}</p>

        <div class="buttons-bar">
          <bc-btn @click="onNewImport()" class="btn-import" color="link-blue">
            <span>{{ $t('core.import.conclusion.goToImport') }}</span>
          </bc-btn>

          <bc-btn @click="onReturnToListHandler" color="primary">
            <span>{{
              `${$t('core.import.conclusion.goToBegin')} ${name}`
            }}</span>
          </bc-btn>
        </div>
      </div>
    </div>
  </bc-wizard-step-panel>
</template>

<script>
import { BcBtn, BcWizardStepPanel } from '@brain/ui'

export default {
  name: 'ImportConclusionStep',
  components: { BcBtn, BcWizardStepPanel },
  data() {
    return {
      redirectCount: 10,
      intervalRef: null,
      teste: false
    }
  },
  props: {
    name: {
      type: String,
      require: true
    },
    label: {
      type: String,
      require: true
    }
  },
  mounted() {
    this.startRedirecCounter()
  },
  computed: {
    redirectMesage() {
      const args = [this.name, this.redirectCount]
      let msg = this.$t('core.import.conclusion.redirect')
      for (var arg in args) {
        msg = msg.replace(`{${arg}}`, args[arg])
      }
      return msg
    }
  },
  methods: {
    onReturnToListHandler() {
      this.$emit('close')
    },
    onNewImport() {
      this.stopRedirecCounter()
      this.$emit('resetImport')
    },
    startRedirecCounter() {
      this.redirectCount = 10
      this.intervalRef = setInterval(() => {
        if (this.redirectCount == 0) {
          this.onReturnToListHandler()
        }
        this.redirectCount--
      }, 1000)
    },
    stopRedirecCounter() {
      clearInterval(this.intervalRef)
    }
  },
  beforeDestroy() {
    this.stopRedirecCounter()
  }
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

.conclusion-box {
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;

  .icon,
  .title {
    color: #89cd4d;
    font-size: 26px;
    font-weight: bold;
    margin-top: 20px;
  }

  .icon {
    margin-top: 0px;
    font-size: 62px;
    line-height: 1em;
  }

  .subtitle,
  .ask {
    color: #959db5;
    font-size: 16px;
    margin-top: 20px;
  }

  .ask {
    color: #959db5;
    font-size: 16px;
    font-weight: normal;
  }

  .buttons-bar {
    margin-top: 20px;

    & > :not(:first-child) {
      margin-left: 20px;
    }

    .btn-import {
      border: 1px var(--bc-primary) dashed !important;
      background: none !important;
      color: #fff;
    }
  }
}

::v-deep .step-container {
  min-height: 640px;
  justify-content: center;
}
</style>
