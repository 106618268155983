import { defineStore } from 'pinia'
import { supplyTransactionStore } from './supplyTransactions'
import {
  moduleApiRefCargillEnum,
  moduleTranslatePrefixCargillEnum
} from '../constants'
import _ from 'lodash'
import { router } from '../brainCore'

export const useModuleOpen = defineStore('moduleOpen', {
  state: () => ({
    moduleOpen: null,
    apiRef: '',
    translatePrefix: 'application.pages',
    menuItems: []
  }),

  getters: {
    getModuleOpen: (state) => () => state.moduleOpen,
    getApiRef: (state) => () => state.apiRef,
    getTranslatePrefix: (state) => () => state.translatePrefix,
    isModuleOpen: (state) => () => state.moduleOpen != null,
    getMenuItems: (state) => () => state.menuItems
  },

  actions: {
    updateMenuItems(menuItems, localization) {
      this.menuItems = this.translateMenuItems(menuItems, localization)
    },
    updateModuleOpen(moduleOpen, menuItems, localization) {
      if (this.moduleOpen != moduleOpen) {
        this.moduleOpen = moduleOpen
        this.apiRef = moduleApiRefCargillEnum[this.moduleOpen]
        this.translatePrefix = moduleTranslatePrefixCargillEnum[this.moduleOpen]
        if (moduleOpen != null) {
          this.menuItems = this.translateMenuItems(menuItems, localization)
        }
        if (router && router.history.current.path != '/') {
          router.push('/')
        }
      }
    },
    translateMenuItems(menuItems, localization) {
      const menuItemsClone = _.cloneDeep(menuItems)
      return this.translateMenuRecursiveItems(menuItemsClone, localization)
    },
    translateMenuRecursiveItems(menus, localization) {
      const result = menus?.filter((menu) => {
        menu.name = localization.$t(menu.name)

        var hasChildrenBefore = this.hasChildren(menu)
        menu.children = this.translateMenuRecursiveItems(
          menu.children,
          localization
        )
        var hasChildrenAfter = this.hasChildren(menu)

        if (hasChildrenBefore && !hasChildrenAfter) {
          return false
        }
        return this.hasMenu(menu) && this.hasModuleVisibility(menu)
      })
      return result
    },
    hasChildren(menu) {
      return menu.children && menu.children.length > 0
    },
    hasTransaction(item) {
      return (
        !item.transaction || supplyTransactionStore.canRead(item.transaction)
      )
    },
    hasVisibility(item) {
      return item.isVisible == null || item.isVisible()
    },
    hasModuleVisibility(item) {
      return !item.module || item.module.includes(this.moduleOpen)
    },
    hasMenu(item) {
      return this.hasTransaction(item) && this.hasVisibility(item)
    }
  },
  persist: true
})
