import { render, staticRenderFns } from "./BcFormHeaderTitle.vue?vue&type=template&id=4479c249&scoped=true&"
import script from "./BcFormHeaderTitle.vue?vue&type=script&lang=js&"
export * from "./BcFormHeaderTitle.vue?vue&type=script&lang=js&"
import style0 from "./BcFormHeaderTitle.vue?vue&type=style&index=0&id=4479c249&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4479c249",
  null
  
)

export default component.exports