import { api } from '@cargill/shared'

const endpoint = '/api/scenarioParameters'

const scenarioParametersService = {
  getParameters: async () => {
    var response = await api.get(`${endpoint}/getParameters`)
    return response.data
  },
  updateParameters: async (parametersToUpdate) => {
    var response = await api.post(
      `${endpoint}/updateParameters`,
      parametersToUpdate
    )
    return response.data
  }
}

export default scenarioParametersService
