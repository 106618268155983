<template>
  <bc-calendar-events-provider :events="workflowEvents">
    <div class="content-site">
      <router-view />
    </div>
  </bc-calendar-events-provider>
</template>

<script>
import './styles/styles.scss'
import { BcCalendarEventsProvider } from '@brain/core'
import { defineComponent } from '@vue/composition-api'
// import workflow from '@supply/workflow'
export default defineComponent({
  name: 'App',
  components: { BcCalendarEventsProvider },
  setup() {
    // TODO: Remoção Workflow - Voltar quando voltar workflow
    // const workflowEvents = workflow.composables.useWorkflowCalendarEvents()
    const workflowEvents = []
    return { workflowEvents }
  }
})
</script>
<style lang="scss" scoped>
.content-site {
  grid-area: content;
  width: 100%;
  background: var(--bc-layer-1-bg);
}
</style>
