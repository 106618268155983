<template>
  <div>
    <bc-wizard-step-panel v-if="displayKpis">
      <import-result-kpis :resultData="resultData" />
    </bc-wizard-step-panel>
    <bc-wizard-step-panel v-if="displayTable">
      <import-result-table
        :resultData="resultData"
        :service="service"
        :name="name"
      />
    </bc-wizard-step-panel>
  </div>
</template>

<script>
import ImportResultKpis from './ImportResultKpis.vue'
import ImportResultTable from './ImportResultTable.vue'
import { BcWizardStepPanel } from '@brain/ui'

export default {
  name: 'ImportResultStep',
  components: { ImportResultKpis, ImportResultTable, BcWizardStepPanel },
  props: {
    resultData: { type: Object, require: true },
    name: {
      type: String,
      require: true
    },
    service: {
      type: Object,
      require: true
    }
  },
  computed: {
    displayTable() {
      return this.resultData?.hideTable !== true
    },
    displayKpis() {
      return this.resultData?.hideKpis !== true
    }
  }
}
</script>
