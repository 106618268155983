<template>
  <div>
    <vue-cron-editor-buefy
      v-model="cronExpression"
      locale="pt"
      cronSyntax="quartz"
    />
  </div>
</template>

<script>
import VueCronEditorBuefy from 'vue-cron-editor-buefy'

export default {
  name: 'CargillCron',
  components: {
    VueCronEditorBuefy
  },
  props: {
    locale: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      cronExpression: ''
    }
  }
}
</script>

<style>
.enable-bulma .card {
  background-color: #30343f;
}

/* Color do texto */
.enable-bulma span {
  color: #959db5;
}

.enable-bulma .tabs a:hover {
  border-bottom-color: #fff;
  color: #fff;
}

.enable-bulma .tabs li.is-active a {
  border-bottom-color: #24bbef;
  color: #24bbef;
}

.enable-bulma .b-numberinput input[type='number'] {
  -moz-appearance: textfield;
  text-align: center;
  background-color: #30343f;
  border-color: #30343f;
  border-bottom: #fff;
  color: #24bbef;
}

.enable-bulma .field.has-addons .control:last-child .control .button,
.enable-bulma .field.has-addons .control:last-child .control .input,
.enable-bulma .field.has-addons .control:last-child .control .select select,
.enable-bulma
  .field.has-addons
  .control:last-child
  .control
  .taginput
  .taginput-container.is-focusable,
.enable-bulma
  .taginput
  .field.has-addons
  .control:last-child
  .control
  .taginput-container.is-focusable {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #30343f;
  border-color: #30343f;
  border-bottom: #fff;
  color: #24bbef;
}

.enable-bulma .dropdown-content {
  background-color: #30343f;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 3px rgb(10 10 10 / 10%),
    0 0 0 1px rgb(10 10 10 / 10%);
  box-shadow: 0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 10%);
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.enable-bulma .select select option {
  color: #24bbef;
  padding: 0.25em 0.5em;
}

.enable-bulma .field.has-addons .control .button:not([disabled]).is-hovered,
.enable-bulma .field.has-addons .control .button:not([disabled]):hover,
.enable-bulma .field.has-addons .control .input:not([disabled]).is-hovered,
.enable-bulma .field.has-addons .control .input:not([disabled]):hover,
.enable-bulma
  .field.has-addons
  .control
  .select
  select:not([disabled]).is-hovered,
.enable-bulma .field.has-addons .control .select select:not([disabled]):hover,
.enable-bulma
  .field.has-addons
  .control
  .taginput
  .taginput-container.is-focusable:not([disabled]).is-hovered,
.enable-bulma
  .field.has-addons
  .control
  .taginput
  .taginput-container.is-focusable:not([disabled]):hover,
.enable-bulma
  .taginput
  .field.has-addons
  .control
  .taginput-container.is-focusable:not([disabled]).is-hovered,
.enable-bulma
  .taginput
  .field.has-addons
  .control
  .taginput-container.is-focusable:not([disabled]):hover {
  z-index: 2;
  background-color: #30343f;
  color: #24bbef;
}

.enable-bulma
  .field.has-addons
  .control:not(:first-child):not(:last-child)
  .button,
.enable-bulma
  .field.has-addons
  .control:not(:first-child):not(:last-child)
  .input,
.enable-bulma
  .field.has-addons
  .control:not(:first-child):not(:last-child)
  .select
  select,
.enable-bulma
  .field.has-addons
  .control:not(:first-child):not(:last-child)
  .taginput
  .taginput-container.is-focusable,
.enable-bulma
  .taginput
  .field.has-addons
  .control:not(:first-child):not(:last-child)
  .taginput-container.is-focusable {
  border-radius: 0;
  background-color: #30343f;
  color: #24bbef;
  border-color: #30343f;
}

.enable-bulma .b-checkbox.checkbox input[type='checkbox']:checked + .check {
  background: #24bbef
    url(
      data:image/svg + xml;charset=utf-8,
      %3Csvgxmlns='http://www.w3.org/2000/svg'viewBox='0 0 1 1'%3E%3Cpathd='M.04.627L.146.52.43.804.323.91zm.177.177L.854.167.96.273.323.91z'fill='%24bbef'/%24bbef/svg%3E
    )
    no-repeat 50%;
  border-color: #24bbef;
}
</style>
