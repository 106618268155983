import { defineStore } from 'pinia'

export const scenarioStore = defineStore('scenarioStore', {
  state: () => ({
    scenario: {}
  }),

  getters: {
    getScenario() {
      return this.scenario
    }
  },

  actions: {
    resetScenario() {
      this.scenario = {}
    },
    setName(_name) {
      this.scenario.name = _name
    },
    setDescription(_description) {
      this.scenario.description = _description
    },
    setPublic(_public) {
      this.scenario.public = _public
    },
    setStart(_start) {
      this.scenario.beginHorizon = _start
    },
    setEnd(_end) {
      this.scenario.endHorizon = _end
    },
    setType(_type) {
      this.scenario.type = _type
    },
    setSource(_scenarioSource) {
      this.scenario.scenarioSource = _scenarioSource
    },
    setFromScenario(_fromScenario) {
      this.scenario.fromScenario = _fromScenario
    },
    setRangeFrom(_rangeFrom) {
      this.scenario.rangeFrom = _rangeFrom
    }
  },
  persist: true
})
