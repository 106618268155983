<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="service"
      :metadata="metadata"
      :gridMode="'clientSide'"
      translatePrefix="integrationService.pages"
    />
  </div>
</template>

<script>
import { CargillCrudMetaView } from '@cargill/shared'
import createService from '../api/integrationInboundService'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      service: null
    }
  },
  methods: {
    async getMeta() {
      const meta = await this.service.getMetaWithValidation()
      return meta
    }
  },
  created() {
    this.service = createService()
    this.getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
