<template>
  <div class="table-container" v-if="hasHeader">
    <div class="table-container_label_wrapper">
      <div class="table-container_label">
        <div
          class="table-container_icon"
          :style="{ backgroundColor: color }"
        ></div>
        <div class="table-container_description">
          {{ $t(title) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CargillColorTabsHeader',
  props: {
    color: {
      type: String
    },
    title: {
      type: String
    },
    hasHeader: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.table-container {
  &_label {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &_description {
    color: #959db5;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75vw;
  }
  &_icon {
    width: 15px;
    height: 15px;
    border-radius: 20%;
    background-color: red;
  }
  &_filter {
    display: flex;
    color: #959db5;
    font-size: 0.75vw;
    font-weight: bold;
    text-transform: uppercase;
    align-items: center;

    i {
      color: #959db5 !important;
      font-size: 1rem !important;
      cursor: pointer;
    }
  }
}
</style>
