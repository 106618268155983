<template>
  <div class="color-tabs__tabs-wrapper" v-if="tabsReady">
    <!-- Tabs -->
    <bc-tabs v-model="tab">
      <v-tabs-slider></v-tabs-slider>

      <bc-tab
        v-for="(tabItem, index) in tabs"
        :key="tabItem.title"
        class="tab-width"
        @change="reloadCurrentTab ? reloadCurrentTab(index) : null"
      >
        <div class="tabs-container">
          <div
            class="tab-icon"
            :style="{ backgroundColor: tabItem.tabColor }"
          ></div>
          {{ $t(tabItem.title) }}
        </div>
      </bc-tab>
    </bc-tabs>
    <!-- Table contents -->
    <bc-tabs-items v-model="tab">
      <bc-tab-item v-for="(item, index) in tabs" :key="item.title">
        <cargill-color-tabs-header
          :title="item.title"
          :color="item.tabColor"
          :hasHeader="hasHeader"
        />
        <slot :item="item" :index="index"></slot>
      </bc-tab-item>
    </bc-tabs-items>
  </div>
</template>

<script>
import CargillColorTabsHeader from './CargillColorTabsHeader.vue'

export default {
  name: 'CargillColorTabs',
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    tabsReady: {
      type: Boolean,
      default: true
    },
    reloadCurrentTab: {
      type: Function
    },
    hasHeader: {
      type: Boolean,
      default: true
    }
  },
  components: { CargillColorTabsHeader },
  data() {
    return {
      tab: 0
    }
  },
  methods: {
    getCurrentTabIndex() {
      return this.tab
    }
  }
}
</script>

<style lang="scss" scoped>
.color-tabs {
  &__tabs-wrapper {
    padding: 1rem;
    .tabs-container {
      display: flex;
      grid-gap: 10px;
      white-space: nowrap;
      .tab-icon {
        width: 15px;
        height: 15px;
        border-radius: 20%;
      }
    }
  }
}

::v-deep {
  .v-tabs {
    background-color: #262c39 !important;
  }
  .v-tabs-items {
    padding: 12px 16px !important;
  }
  .tab-width {
    width: min-content !important;
  }
}
</style>
