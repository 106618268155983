<template>
  <bc-container>
    <cg-crud-list
      v-if="mode === 'List'"
      :name="name"
      :label="label"
      :columns="gridColumns"
      :actions="actions"
      :grid-mode="gridMode"
      :grid-data="gridData"
      :grid-height="gridHeight"
      :service="service"
      :selectableRow="selectableRow"
      :translate="translate"
      :validation-schema="validationSchema"
      :details="details"
      :customGridSetSize="customGridSetSize"
      :gridOptions="gridOptions"
      :customToolbarActions="customToolbarActions"
      :deleteFiltered="deleteFiltered"
      :keepFilters="keepFilters"
      :keepFiltersOnExitCrud="keepFiltersOnExitCrud"
      @create="navigateToCreateForm"
      @edit="navigateToEditForm"
      @import="navigateToImportRoute"
      @gridReady="$emit('gridReady')"
      @clientSideLoadData="$emit('clientSideLoadData')"
      @lineSelected="onSelectedLine"
    />
    <bc-crud-from
      v-else-if="mode === 'Create' || mode === 'Edit'"
      :name="name"
      :fields="formFields"
      :service="service"
      :translate="translate"
      :validation-schema="validationSchema"
      :details="details"
      @close="navigateToList"
    />

    <cg-crud-import
      v-else-if="mode === 'Import' && !defaultImport"
      :name="name"
      :label="label"
      :fields="formFields"
      :service="service"
      :translate="translate"
      :tryTranslateOtherLocale="tryTranslateOtherLocale"
      :details="details"
      @close="navigateToList"
      :masterLineId="selectedLineId"
    />

    <bc-crud-import
      v-else-if="mode === 'Import' && defaultImport"
      :name="name"
      :label="label"
      :fields="formFields"
      :service="service"
      :translate="translate"
      :tryTranslateOtherLocale="tryTranslateOtherLocale"
      :details="details"
      @close="navigateToList"
    />
  </bc-container>
</template>

<script>
// import { BreadcrumbInstance } from '../bc-breadcrumb'
import { BcCrudView, BcCrudImport } from '@brain/crud'

import { BcContainer } from '@brain/ui'
import { BcCrudFrom } from '@brain/crud'
import CgCrudImport from './CgCrudImport.vue'
import CgCrudList from './CgCrudList.vue'

export default {
  name: 'CgCrudView',
  extends: BcCrudView,
  components: {
    BcCrudFrom,
    CgCrudImport,
    BcContainer,
    CgCrudList,
    BcCrudImport
  },
  props: {
    defaultImport: { type: Boolean, default: true },
    customToolbarActions: { type: Array, default: () => [] },
    deleteFiltered: { type: Boolean, default: true }
  },
  data() {
    return {
      selectedLineId: 0
    }
  },
  methods: {
    onSelectedLine(event) {
      this.selectedLineId = event.masterId
    }
  }
}
</script>
