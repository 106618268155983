import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { authService } from '@cargill/shared'

export default {
  install(Vue) {
    // use a new Vue instance as the interface for Vue components to receive/send SignalR events
    // this way every component can listen to events or send new events using this.$notification
    const signalRUrl = '/api/notification'
    const connection = new HubConnectionBuilder()
      .withUrl(signalRUrl, {
        accessTokenFactory: () => {
          const token = authService.getToken()
          return token
        }
      })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build()

    function start() {
      if (!authService.isLoggedIn()) {
        console.log(
          `Esperando login para iniciar conexão com SignalR ${signalRUrl}...`
        )
        setTimeout(() => start(), 2000)
        return
      }
      console.log(`Iniciando conexão com SignalR ${signalRUrl}...`)
      connection
        .start()
        .then(() => {
          console.log(`Conectado com o SignalR ${signalRUrl}`)
        })
        .catch((err) => {
          console.error(`Failed to connect with SignalR ${signalRUrl}`, err)
          return new Promise((resolve, reject) =>
            setTimeout(() => start().then(resolve).catch(reject), 5000)
          )
        })
    }
    connection.onclose(() => start())

    start()
    Vue.prototype.$notification = {
      // Forward hub events through the event, so we can listen for them in the Vue components
      on(method, callback) {
        connection.on(method, callback)
      },
      off(method, callback) {
        connection.off(method, callback)
      },
      // Provide methods for components to send messages back to server[]
      // Make sure no invocation happens until the connection is established
      async invoke(method, ...message) {
        await connection.invoke(method, ...message)
      }
    }
  }
}
