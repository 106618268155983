export default {
  application: {
    enums: {
      notificationModule: {
        appService: 'SCO',
        controlTower: 'Control Tower',
        integrationService: 'Integration'
      },
      notificationType: {
        error: 'Error',
        information: 'Information',
        warning: 'Warning'
      }
    },
    pages: {
      notificationUser: {
        title: 'Notifications'
      },
      notification: {
        title: 'Notifications',
        markAllAsRead: 'Mark all notifications as read',
        markAllAsReadMessage:
          'Are you sure you want to mark all unread notifications as read?'
      }
    },
    misc: {
      allNotifications: 'See all notifications',
      emptyNotifications: 'You have no notification',
      notifications: 'Notifications',
      notifiedOn: 'Notified on',
      module: 'Module'
    },
    actions: {
      markAsRead: 'Mark as read'
    }
  }
}
