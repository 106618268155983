import api from './api'
import { moduleCargillEnum, moduleApiRefCargillEnum } from '../constants'

const service = {}

service.sync = async (moduleKey) => {
  try {
    const response = await api.get(
      `/api/${moduleApiRefCargillEnum[moduleKey]}dataPermissionSync/sync`
    )
    return response?.data
  } catch (e) {
    console.error(
      `Não foi possível sincronizar o módulo ${moduleCargillEnum[moduleKey]}, { key: ${moduleKey}, api: ${moduleApiRefCargillEnum[moduleKey]}}`
    )
    console.error(e)
  }
  return null
}

window.dataPermissionSync = service

export default service
