import axios from 'axios'
import { router } from '../brainCore'
import { authService } from '../authService'
import { useScenarioOpen } from '../stores/scenarioOpen'
import { useUserSettings } from '../stores/userSettings'
import { eventHub } from '../bus/eventhub'

const api = axios.create({})

const isBrainRequest = (config) => {
  const url = config?.url
  return (
    url?.includes('/count') ||
    url?.includes('?limit') ||
    url?.includes('/filterOptions')
  )
}

const showLoading = (config) => {
  return !isBrainRequest(config) && config?.showLoading !== false
}

api.interceptors.request.use(
  async (config) => {
    const token = authService.getToken()
    const { headers } = config
    const { getLanguage } = useUserSettings()
    const { getScenarioOpen } = useScenarioOpen()
    const scenarioId = getScenarioOpen()?.id
    headers['Accept-Language'] = getLanguage()
    headers['Time-Zone'] = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (showLoading(config)) {
      eventHub.$emit('before-request')
    }
    return {
      ...config,
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
        Scenario: scenarioId
      }
    }
  },
  (error) => {
    eventHub.$emit('response-error')
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    if (showLoading(response.config)) {
      eventHub.$emit('after-response')
    }
    return response
  },
  (error) => {
    if (showLoading(error.response.config)) {
      eventHub.$emit('response-error')
    }
    if (error.response.status === 401 || error.response.status === 403) {
      router.push('/')
    }
    return Promise.reject(error)
  }
)

export default api
