export default {
  crud: {
    filter: {
      selectAllSearchResults: 'Selecionar Filtrados',
      addCurrentSelectionToFilter: 'Adicionar seleção atual ao filtro',
      maxFilterQuery:
        'Erro! Não pode selecionar mais de {maxFilterQuery} itens por filtro'
    }
  },
  core: {
    misc: {
      deleteFiltered: 'Deletar filtrados'
    },
    crud: {
      alertRemoveFilteredMessage:
        'Tem certeza que deseja remover itens filtrados?',
      alertRemoveFilteredWithoutFilterMessage:
        'Não há filtro aplicado! Tem certeza que deseja remover todos os itens?'
    }
  },
  application: {
    name: 'Supply Brain - ptBR',
    dateFormat: 'DD/MM/YYYY',
    dateTimeFormat: 'DD/MM/YYYY HH:mm',
    dateTimeFullFormat: 'DD/MM/YYYY HH:mm:ss',
    monthYearChart: 'MMM/YY',
    fullMonthYearChart: 'MMMM [de] YYYY',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    crud: {
      uniqueKeyError: 'Erro! Duplicação de chave única!',
      foreignKeyViolationError: 'Violação de chave estrangeira!'
    },
    terms: {
      restrictions:
        'Restrições: O Cliente  deverá fazer uso do Software nos termos e finalidades expressamente previstos no contrato vigente. O Cliente não poderá usar, copiar, modificar ou distribuir os Materiais Licenciados (por via eletrônica ou qualquer outro meio), nem qualquer cópia, adaptação, transcrição ou parte fundida dos mesmos, salvo conforme expressamente autorizado por este Contrato.  O Cliente não fará (e não permitirá que outros façam) agrupamento reverso, engenharia reversa, compilação reversa ou tradução dos Materiais Licenciados de nenhuma maneira, exceto nos casos em que a legislação aplicável expressamente proibir tais restrições.  Os direitos do Cliente ora concedidos não podem ser transferidos, locados, gravados nem cedidos. A Licença não inclui o direito de reproduzir, distribuir ou desenvolver obras derivados.  A Licença ora concedida não autorizada nenhum trabalho hospedado, licença de usuários múltiplos, acordo de acesso de diversas partes, agregação ou propósito semelhante em benefício de terceiros.',
      accentureRights:
        'Direitos de Auditoria da Accenture. O Cliente fará auditoria do uso por ele feito do Software anualmente e deverá comprovar para a Accenture que o uso do Software feito por ele está em conformidade com as obrigações estabelecidas no contrato vigente e Adendos aplicáveis.  Na eventual identificação por parte do Cliente sobre o uso do Software em divergência ou impacto com a finalidade e definição previstas nesta Proposta, o Cliente deverá comunicar expressa e imediatamente à Accenture. Além disto, a Accenture reserva-se o direito de, mediante notificação razoável ao Cliente e durante o horário normal de trabalho, com obrigações de confidencialidade razoáveis e sem interrupção despropositada das operações do Cliente, auditar o uso dos Materiais Licenciados para o propósito de verificar o cumprimento dos termos dos Contratos aplicáveis por parte do Cliente.',
      clientContent:
        'Conteúdo do Cliente: O Cliente (e licenciadores do Cliente, quando aplicável) detém todos os direitos, posse e participação sobre o Conteúdo do Cliente. Compartilhamentos do Conteúdo do Cliente (dados e informações), somente poderão ser feitas no mesmo ambiente produtivo em que se encontram.  O Cliente declara que, durante a vigência deste Contrato e da Proposta aplicável, obterá todas as autorizações requeridas nos termos das leis previamente ao Conteúdo do Cliente ser inserido ou processado pelos Serviços de Plataforma. Em particular, o Cliente declara que não compartilhará com a Accenture Dados Pessoais, assim entendidos como sendo qualquer informação, mesmo pública, que possa identificar uma pessoa física, direta ou indiretamente. O Cliente concede à Accenture o direito não exclusivo de hospedar, armazenar, processar e transferir o Conteúdo do Cliente para a Accenture fornecer, nos termos descritos no Contrato, os Serviços de Plataforma e para o Cliente usar tais serviços conforme estabelecido neste Contrato, assim como para o aprimoramento dos Serviços de Plataforma pela Accenture relacionado a esse uso. A Accenture concorda em destruir qualquer Conteúdo do Cliente. A Accenture pode: (i) usar padrões de uso, tendências, estatísticas e outros dados derivados do uso dos Serviços de Plataforma (mas não do próprio Conteúdo do Cliente) para fins de desenvolvimento ou melhoria dos Serviços de Plataforma e de outros produtos e serviços da Accenture, desde que não envolvam Dados Pessoais; e (ii) fornecer informações ao Fornecedor da Nuvem em relação ao uso dos seus serviços de Infraestrutura em nuvem pelo Cliente, sujeito às obrigações de confidencialidade do Fornecedor de Nuvem, para que o uso do Fornecedor de Nuvem melhore seus serviços de infraestrutura em nuvem, desde que não envolvam Dados Pessoais.',
      host: 'Hospedagem: A nuvem em que a plataforma e o algoritmo de propriedade da Accenture será hospedado está sujeita a paradas programadas para manutenção, manutenção não programada e falhas no sistema. No entanto, as partes reconhecem que, à medida que a Internet não é propriedade nem controlada por qualquer entidade específica, a Accenture não pode garantir que qualquer Usuário Final poderá acessar os serviços em qualquer momento. Além disso, não há garantia de que o acesso aos serviços estará sempre disponível ou será ininterrupto e a Accenture não será responsável perante ao Cliente ou terceiros por falhas em acessibilidade aos serviços, quando tais falhas resultem de falhas da Internet e problemas de Conectividade além do controle razoável da Accenture; Falha na rede ou no usuário final; Erros ou redes de hardware, quando o hardware e / ou a rede não são detidos ou controlados pela Accenture e são fornecidos como parte dos serviços; Negação de serviço ou ataques similares destinados a "sobrecarregar" um site com solicitações de informações, conteúdo ou respostas; Falha em aplicações, códigos ou interfaces associados a terceiros e não controlados pela Accenture; Vírus, malware ou outros códigos de desativação que são introduzidos nos serviços; Evento de força maior conforme definido no Código Civil Brasileiro; Use isso que exceda os limites de capacidade ou largura de banda descritos nesta proposta ou em especificações técnicas complementares a esta proposta; Ou erros causados pelo Conteúdo Cliente;'
    },
    misc: {
      inlineMultiUpdateSuccess: 'Itens Atualizados com Sucesso',
      inlineUpdateSuccess: 'Item Atualizado com Sucesso',
      success: 'Sucesso',
      profile: 'Perfil',
      settings: 'Configuração',
      logout: 'Sair',
      unidentifiedUser: 'Usuário não identificado!',
      moduleSelected: 'Módulo selecionado!',
      selectAModule: 'Selecione o módulo',
      dashboards: 'Dashboards',
      till: 'Até',
      period: 'Período',
      error: 'Erro ao executar ação',
      comments: 'Comentários',
      others: 'Outros',
      registration: 'Cadastros',
      deleteSuccess: 'Deleção realizada com Sucesso',
      deleteError: 'Deleção falhou',
      emptyOptimizations: 'Você não tem nenhuma tarefa em andamento'
    },
    general: {
      registration: 'Cadastros Gerais',
      logistics: 'Logística',
      demand: 'Demanda',
      several: 'Diversos',
      origination: 'Originação',
      true: 'Sim',
      false: 'Não',
      ibpDashboardMenu: 'Cadastro Dashboard Mensal',
      weeklyDashboardMenu: 'Cadastro Dashboard Semanal',
      fullfilledDashboardMenu: 'Cadastro Dashboard Realizado',
      logs: 'Logs',
      itas: 'ITAS'
    },
    validation: {
      validateFieldRequired: 'Campo obrigatório',
      validateFieldEmail: 'E-mail inválido',
      phoneIsNotValid: 'Este número de telefone não é válido',
      lengthIsNotValid: '',
      minLengthIsNotValid: '',
      maxLengthIsNotValid: '',
      validateRequiredFieldsAll: 'Os campos obrigatórios devem ser preenchidos'
    },
    errors: {
      period: {
        beginHorizonGreaterThanEnd:
          'O fim do período deve ser posterior ao início',
        overlappingPeriods:
          'O período está sobrepondo outro período {begin} - {end}'
      },
      locality: {
        fillLocality: 'Preencha os campos de localidade',
        fillLatitudeAndLongitude: 'Preencha os campos de latitude e longitude'
      },
      unity: {
        invalid: 'Unidades inválidas: {arg}'
      },
      unityConversion: {
        invalid: 'Conversões de unidades não cadastradas: {arg}'
      },
      noOpenScenario: 'Nenhum cenário aberto!',
      importError: 'Arquivo fora do template'
    },
    actions: {
      create: 'Criar',
      back: 'Voltar',
      cancel: 'Cancelar',
      next: 'Avançar',
      save: 'Salvar',
      finish: 'Concluir',
      close: 'Fechar',
      yes: 'Sim',
      no: 'Não',
      update: 'Atualizar',
      delete: 'Deletar'
    },
    pages: {
      stuffingPortalCompanies: {
        stuffingPortalCompaniesUsers: {
          title: 'Usuários'
        }
      },
      takeUpQualityAndContamination: {
        takeUpQualityAndContaminationTranslation: {
          title: 'Grafias de Qualidade e Contaminação'
        }
      },
      takeUpCompany: {
        takeUpCompaniesUsers: {
          title: 'Usuários'
        }
      },
      takeUpQualityAndContaminationInformationContracts: {
        takeUpQualityAndContaminationInformation: {
          title: 'Informações de Qualidade e Contaminação'
        }
      },
      takeUpReferenceTable: {
        takeUpEvaluationCriteria: {
          title: 'Critérios de Avaliação'
        }
      },
      takeUpInformationMaster: {
        takeUpInformation: {
          title: 'Take-Up'
        },
        takeUpInformationSummary: {
          title: 'Resumo'
        }
      },
      takeUpReportInformationMaster: {
        takeUpReportInformation: {
          title: 'Informações de Relatório de Take-up'
        }
      },
      takeUpPremiumAndDiscountInformation: {
        takeUpPremiumAndDiscountCalculationDetails: {
          title: 'Detalhes de cálculo'
        },
        takeUpPremiumAndDiscountInformationSummary: {
          title: 'Resumo'
        }
      },
      takeUpLotInformationItas: {
        takeUpLotInformationItasDetail: {
          title: 'Informações de Relatório de Take-up'
        }
      },
      takeUpNegotiationInformationMaster: {
        takeUpNegotiationInformationSummary: {
          title: 'Resumo'
        },
        takeUpNegotiationInformationCharacteristics: {
          title: 'Caracteristicas'
        }
      },
      inventoryItas: {
        title: 'ITAS - Estoque'
      },
      takeUpInventoryItas: {
        title: 'Take-Up - ITAS - Estoque'
      },
      purchasesAndSalesItas: {
        title: 'ITAS - Contrato (Compra/Venda)'
      },
      bookingItas: {
        title: 'ITAS - Booking'
      },
      openContractItas: {
        title: 'ITAS - Open Contract'
      },
      notificationUser: {
        title: 'Notificações'
      },
      scoNotification: {
        title: 'Notificações',
        message: 'Mensagem',
        notificationDate: 'Data de Notificação',
        notificationEvent: 'Evento',
        notificationModule: 'Módulo',
        notificationType: 'Tipo',
        readDate: 'Lida em',
        isRead: 'Lida'
      },
      optimizationQueueEntity: {
        title: 'Tarefas em andamento',
        operation: 'Tipo',
        scenarioName: 'Cenário',
        status: 'Status',
        startDate: 'Submetido em',
        startOptimization: 'Iniciado em',
        endDate: 'Finalizado em',
        duration: 'Duração',
        optimization: 'Otimização',
        showAllOngoingTasks: 'Ver todas as tarefas em andamento',
        stopOptimization: 'Cancelar otimização',
        exportLogs: 'Exportar Logs',
        visualize: 'Visualizar',
        updateOn: 'Atualizado em',
        requestingUserName: 'Solicitado por',
        round: 'Round',
        percentageAttendance: '% de Atendimento do Cenário',
        pd: 'P/D',
        demandAttendedIM: 'Demanda atendida MI',
        demandAttendedEM: 'Demanda atendida ME'
      },
      optimization: {
        title: 'Otimização',
        parameters: 'Parâmetros da otimização',
        validateInput: 'Validar dados de entrada',
        optimization: 'Otimização',
        scenarioName: 'Nome do Cenário',
        period: 'Período',
        type: 'Tipo',
        objectiveFunction: 'Função Objetivo',
        maxProfit: 'Max Profit',
        minCosts: 'Min Costs',
        penaltyWeight: 'Peso das penalidades',
        generalParameters: 'Parâmetros gerais',
        externalMarket: 'Mercado Externo',
        internalMarket: 'Mercado Interno',
        monthly: 'Mensal',
        weekly: 'Semanal',
        till: 'Até',
        exportPenalty: 'Penalidade para exportação fora do prazo limite (ICMS)',
        boardingDate: 'Data embarque exportação',
        clientArrivingDate: 'Data chegada no Cliente',
        riskySales: 'Vendas Risco',
        vipClients: 'Clientes Vip',
        creditCardDate: 'Data de carta de crédito',
        productAvailability: 'Disponibilidade do produto',
        contractMargin: 'Margem do contrato',
        startOptimization: 'Iniciar Otimização',
        loadingOptimization: 'Otimizando Cenário',
        backgroundOptimization: 'Otimizar em segundo plano',
        cancelOptimization: 'Cancelar otimização',
        askCancelOptimization: 'Deseja cancelar a otimização?',
        cancelDescription:
          'Ao cancelar a otimização todos os dados serão descartados!',
        scenarioQueued: 'Cenário enviado com sucesso para otimização',
        scenarioQueuedDescription:
          'A otimização será realizada em segundo plano e quando finalizada será enviada uma mensagem informativa',
        onGoingTasks: 'Tarefas em andamento',
        homepage: 'Ir para tela inicial',
        requestToStopOptimizationSent:
          'Solicitação para cancelar otimização enviada',
        couldNotRequestOptimizationStop:
          'Não foi possível solicitar cancelamento da otimização',
        finishedWithError:
          'Otimização do cenário {scenario} finalizada com erro!',
        finishedSuccessfully:
          'Otimização do cenário {scenario} finalizada com sucesso!',
        interruptedSuccessfully:
          'Otimização do cenário {scenario} cancelado com sucesso!'
      },
      scenarioParameters: {
        title: 'Parâmetros'
      },
      ibpWarehouseCapacityUtilizationDashboard: {
        title: 'Projeção de Armazenagem',
        planningMonth: 'Mês do Planejamento',
        centerCode: 'Centro',
        modalCode: 'Modal',
        plannedCapacity: 'Capacidade Planejada',
        plannedCapacityUnit: 'Unidade de Medida da Capacidade Planejada',
        operationCapacity: 'Capacidade da Operação',
        operationCapacityUnit: 'Unidade de Medida da Capacidade da Operação',
        incoterm: 'Incoterm',
        isTakeOrPay: 'Take or Pay',
        marketType: 'Market Type',
        purchaseCompany: 'Company',
        purchaseContractReference: 'Purchase Contract/Split',
        purchaseCropYear: 'Crop Year',
        usBucket: 'US Bucket'
      },
      ibpCarrierCapacityUtilizationDashboard: {
        title: 'Projeção de utilização da capacidade de Armador',
        carrierName: 'Nome do Armador',
        planningMonth: 'Mês do Planejamento',
        originPort: 'Porto de Origem',
        destinationRegion: 'Região de Destino',
        destinationCountry: 'País de Destino',
        plannedCapacity: 'Volume Planejado',
        plannedCapacityUnit: 'Unidade do Volume Planejado',
        carrierCapacity: 'Volume Contratado',
        carrierCapacityUnit: 'Unidade do Volume Contratado'
      },
      performanceDashboard: {
        title: 'Performance',
        unityMeasure: 'Unidade de Medida',
        filter: 'Filtro',
        ton: 'Metric Ton (MT)',
        highCube: 'Container (Cntr)',
        monthly: 'Mensal',
        weekly: 'Semanal',
        plan: 'Plano'
      },
      monthlyPlanningDashboardRevised: {
        title: 'Planejamento Mensal',
        headerTitle: 'Painel Mensal (IBP)',
        horizon: 'Horizonte',
        unityMeasure: 'Unidade de Medida',
        selectedPeriod: 'Periodo Selecionado',
        ton: 'Metric Ton (MT)',
        highCube: 'Container (Cntr)'
      },
      weeklyPlanningDashboard: {
        title: 'Planejamento Semanal',
        headerTitle: 'Planejamento Semanal',
        unityMeasure: 'Unidade de Medida',
        details: 'Detalhes',
        filter: 'Filtro',
        ton: 'Metric Ton (MT)',
        highCube: 'Container (Cntr)'
      },
      booking: {
        title: 'Bookings',
        bookingRef: 'Booking Ref #',
        code: 'Booking',
        originPort: 'Porto de Origem',
        departureTerminal: 'Terminal de Embarque',
        destinationPort: 'Porto de Destino',
        trip: 'Viagem',
        ship: 'Navio',
        draftDeadline: 'Deadline de Draft',
        projection: 'Projeção',
        containerQuantity: 'Quantidade de Containers',
        costByContainer: 'Custo por Container',
        costByContainerUnit: 'Unidade de Medida - Custo por Container',
        carrierId: 'Armador',
        startLoadingDate: 'Início Free time',
        endLoadingDate: 'DeadlineCargo',
        originDepartureDate: 'ETS',
        destinationArrivalDate: 'ETA',
        active: 'Ativo',
        carrier: 'Armador',
        transshipment: 'Transbordo'
      },
      cargillTeam: {
        title: 'Cargill Team'
      },
      controlTower: {
        title: 'Torre de Controle'
      },
      integratedPlanning: {
        title: 'Planejamento Integrado'
      },
      workingHours: {
        title: 'Dias de Operação',
        locality: 'Localidade',
        region: 'Região',
        country: 'País',
        state: 'UF',
        city: 'Cidade',
        center: 'Centro',
        centerType: 'Tipo de Centro',
        beginHorizon: 'Início',
        endHorizon: 'Fim',
        weekBegin: 'Início Semana',
        weekEnd: 'Fim Semana',
        active: 'Ativo'
      },
      center: {
        centers: 'Centros',
        title: 'Centros',
        locality: 'Localidade',
        code: 'Código',
        description: 'Descrição',
        centerType: 'Tipo de Centro',
        active: 'Ativo',
        region: 'Região',
        subRegion: 'Sub-Regional',
        country: 'País',
        city: 'Cidade',
        longitude: 'Longitude',
        latitude: 'Latitude',
        state: 'UF',
        port: 'Porto',
        roadMap: 'Roteiro'
      },
      centerInformation: {
        title: 'Capacidades Armazém e Fazenda',
        active: 'Ativo',
        beginHorizon: 'Início',
        center: 'Centro',
        centerType: 'Tipo de Centro',
        endHorizon: 'Fim',
        modal: 'Modal',
        modalType: 'Tipo de Modal',
        receivingCapacity: 'Cap.Receb',
        receivingShippingCapacityUnity: 'Unid.Receb/Exped',
        shippingCapacity: 'Cap.Exped',
        storageCapacity: 'Cap.Az',
        storageCapacityUnity: 'Unid.Az',
        centerAvailableCapacity: {
          title: 'Capacidade Disponível do Centro',
          centerInformation: 'Capacidade de Carregamento',
          referenceDate: 'Data',
          receivingUsedCapacity: 'Cap.Utilizada Receb',
          receivingUsedCapacityUnity: 'Unid.Utilizada Receb',
          azUsedCapacity: 'Cap.Utilizada Az',
          azUsedCapacityUnity: 'Unid.Utilizada Az',
          expedUsedCapacity: 'Cap.Utilizada Exped',
          expedUsedCapacityUnity: 'Unid.Utilizada Exped',
          receivingAvailableCapacity: 'Cap.Disponível Receb',
          receivingAvailableCapacityUnity: 'Unid.Disponível Receb',
          azAvailableCapacity: 'Cap.Disponível Az',
          azAvailableCapacityUnity: 'Unid.Disponível Az',
          expedAvailableCapacity: 'Cap.Disponível Exped',
          expedAvailableCapacityUnity: 'Unid.Disponível Exped',
          itasPeriodStartDate: 'Data',
          itasPeriodEndDate: 'Data',
          lastItasUpdatedDate: 'Data'
        }
      },
      locality: {
        title: 'Localidade',
        code: 'Código',
        description: 'Descrição',
        region: 'Região',
        country: 'País',
        state: 'UF',
        city: 'Cidade',
        street: 'Rua',
        district: 'Bairro',
        number: 'Número',
        complement: 'Complemento',
        latitude: 'Latitude',
        longitude: 'Longitude',
        active: 'Ativo'
      },
      home: {
        title: 'Início'
      },
      security: {
        title: 'Segurança'
      },
      transactions: {
        title: 'Transações'
      },
      users: {
        title: 'Usuários'
      },
      sample: {
        title: 'Exemplo',
        code: 'Código',
        name: 'Nome',
        description: 'Descrição',
        sampleGroup: 'Grupo de Exemplo'
      },
      sampleGroup: {
        title: 'Grupo de Exemplo',
        code: 'Código',
        name: 'Nome'
      },
      scenarioCargill: {
        title: 'Cenário',
        scenarios: 'Cenários',
        name: 'Código',
        description: 'Descrição',
        createdDate: 'Data de criação',
        updateDate: 'Data de atualização',
        startDate: 'Data início',
        endDate: 'Data fim',
        lastUpdatedDate: 'Data da última atualização',
        lastOptimizationDate: 'Data da última Otimização',
        createdByName: 'Criado por',
        lastUpdatedByName: 'Atualizado por',
        lastOptimizedByName: 'Otimizado por',
        public: 'Público',
        scenarioPublic: 'Cenário Público',
        private: 'Privado',
        optimized: 'Otimizado',
        type: 'Tipo',
        beginHorizon: 'Início do horizonte',
        endHorizon: 'Fim do horizonte',
        planningHorizon: 'Horizonte de Planejamento',
        originalScenarioCreatedDate: 'Data de criação do cenário de origem',
        originalScenarioName: 'Nome do cenário de origem',
        horizon: 'Horizonte e Data',
        createScenario: 'Criar Cenário',
        scenarioName: 'Nome do Cenário',
        start: 'Início',
        end: 'Fim',
        blank: 'Em branco',
        copy: 'Cópia',
        master: 'Master',
        dataSource: 'Fonte de Dados',
        selectSource: 'Selecione a fonte de dados',
        newScenario: 'Novo Cenário',
        publish: 'Oficializar',
        openScenario: 'Abrir Cenário',
        confirmPublishTitle: 'Oficializar cenário',
        published: 'Oficializado',
        confirmPublish:
          'Deseja oficializar este cenário? Os dados serão desabilitados para alteração. Qualquer cenário oficializado que seja do mesmo tipo e tenha o mesmo horizonte será desoficializado.',
        scenarioNotFoundToPublish:
          'O cenário não foi encontrado para ser oficializado.',
        scenarioNotOptimizedToPublish:
          'O cenário precisa estar otimizado para ser oficializado.',
        permissionDeniedToPublish:
          'Não tem permissão para oficializar este cenário',
        unpublish: 'Cancelar a oficialização',
        confirmUnpublishTitle: 'Cancelar a oficialização do cenário',
        confirmUnpublish:
          'Deseja cancelar a oficialização deste cenário? Os dados poderão ser alterados novamente.',
        scenarioNotFoundToUnpublish:
          'O cenário não foi encontrado para cancelar a oficialização.',
        permissionDeniedToUnpublish:
          'Não tem permissão para cancelar a oficialização deste cenário',
        scenarioNotFoundToPublic:
          'O cenário não foi encontrado para tornar público.',
        permissionDeniedToPublic: 'Não tem permissão tornar o cenário público',
        permissionDeniedToDelete: 'Não tem permissão para deletar o cenário',
        scenarioNotFoundToDelete:
          'O cenário não foi encontrado para ser deletado.',
        importTitle: 'Importar Cenário',
        export: 'Exportar',
        import: 'Importar',
        importError:
          'Erro na importação de cenário. Entre em contato com o administrador do sistema.',
        importSelectDescription: 'Arraste o arquivo para iniciar importação',
        selectedScenario: 'Cenário selecionado',
        showAllScenarios: 'Mostrar todos os cenários',
        viewAllScenarios: 'Visualizar todos os cenários',
        closeScenario: 'Fechar cenário',
        confirmOpenScenario: 'Deseja abrir o cenario {scenario}?',
        confirmCloseScenario: 'Deseja fechar o cenario {scenario}?',
        scenarioWillBeClosed:
          'Ao abrir o cenário, automaticamente o cenário {scenario} será fechado.',
        scenarioClose:
          'Ao fechar o cenário, será aberto automaticamente o cenáro Master Data.',
        notShowAgain: 'Não mostrar novamente',
        identifyScenario:
          'Informe a identificação do cenário e avance para criá-lo.',
        confirmScenarioData: 'Confirme os dados do cenário',
        createdSuccessfully: 'Cenário {scenario} criado com sucesso',
        scenarioIsBeingCreated: 'Cenário está sendo criado',
        scenarioIsBeingCreatedDescription:
          'A criação do cenário será realizada em segundo plano e quando finalizada será enviada uma notificação',
        scenarioIsBeingImported: 'Cenário está sendo importado',
        scenarioIsBeingImportedDescription:
          'A importação do cenário será realizada em segundo plano e quando finalizada será enviada uma notificação',
        errorOnCreated: 'Não foi possível criar o cenário {scenario}',
        redirecting:
          'Você será redirecionado para listagem de cenários em {seconds} segundos...',
        scenarioList: 'Listagem de cenários',
        createNewScenario: 'Criar um novo cenário',
        monthly: 'Mensal',
        weekly: 'Semanal',
        masterData: 'MasterData',
        confirmation: 'Confirmação',
        exportDateScenario:
          'Exportação de todos os dados de entrada exportáveis',
        selectMonthlyDate: 'Referência Para Início Do Horizonte',
        MonthlyM1: 'M +1',
        MonthlyM2: 'M +2',
        scenarioPublishingMessage:
          'Solicitação de publicação realizada com sucesso. Uma notificação será enviada após a conclusão.'
      },
      importLog: {
        title: 'Log de Importação',
        date: 'Data',
        onlyDate: 'Data',
        hour: 'Hora',
        user: 'Usuário',
        entity: 'Tela',
        scenario: 'Cenário',
        insertedRows: 'Linhas inseridas',
        updatedRows: 'Linhas atualizadas',
        errorRows: 'Linhas com erro',
        actions: 'Ações',
        downloadLog: 'Download log'
      },
      material: {
        title: 'Tipo de Algodão',
        code: 'Código',
        description: 'Descrição',
        active: 'Ativo'
      },
      unity: {
        title: 'Unidade',
        code: 'Código',
        symbol: 'Símbolo',
        description: 'Descrição',
        category: 'Categoria'
      },
      unityConversion: {
        title: 'Conversão de Unidades',
        beginHorizon: 'Período Início',
        endHorizon: 'Período Fim',
        fromUnity: 'Unidade De',
        toUnity: 'Unidade Para',
        active: 'Ativo',
        factor: 'Fator de Conversão',
        errors: {
          sameUnits: 'Não é possível realizar conversão entre a mesma unidade'
        },
        hints: {
          factor:
            'O fator de conversão é uma multiplicação em relação a unidade DE'
        }
      },
      optimizationParameters: {
        title: 'Parâmetros de Otimização'
      },
      modal: {
        title: 'Modal',
        code: 'Código',
        description: 'Descrição',
        modalType: 'Tipo de Modal',
        active: 'Ativo'
      },
      workException: {
        title: 'Feriados e Paradas',
        beginHorizon: 'Início do Horizonte',
        endHorizon: 'Fim do Horizonte',
        region: 'Região',
        country: 'País',
        city: 'Cidade',
        state: 'UF',
        center: 'Centro',
        centerType: 'Tipo de Centro',
        exceptionDate: 'Dia de Exceção',
        active: 'Ativo',
        numberOfDays: 'Número de Dias'
      },
      customerCargill: {
        title: 'Cliente',
        code: 'Código',
        description: 'Descrição',
        vip: 'VIP',
        region: 'Região',
        country: 'País',
        state: 'UF',
        city: 'Cidade',
        longitude: 'Longitude',
        latitude: 'Latitude',
        marketType: 'Tipo de Mercado',
        active: 'Ativo',
        customerCargillReceivingCapacity: {
          title: 'Capacidade Nominal de Recebimento do Cliente',
          customer: 'Cliente',
          beginHorizon: 'Início',
          endHorizon: 'Fim',
          receivingCapacity: 'Cap.Receb',
          receivingCapacityUnit: 'Unid.Receb',
          active: 'Ativo'
        },
        customerCargillAvailableCapacity: {
          title: 'Capacidade Disponível de Recebimento do Cliente',
          customer: 'Cliente',
          customerReceivingCapacity:
            'Capacidade Nominal de Recebimento do Cliente',
          referenceDate: 'Data',
          receivingUsedCapacity: 'Cap.Utilizada Receb',
          receivingUsedCapacityUnity: 'Unid.Utilizada Receb',
          receivingAvailableCapacity: 'Cap.Disponível Receb',
          receivingAvailableCapacityUnity: 'Unid.Disponível Receb'
        }
      },
      centerCost: {
        title: 'Armazém de Interior',
        beginHorizon: 'Início',
        endHorizon: 'Fim',
        centerType: 'Tipo de Centro',
        center: 'Centro',
        material: 'Tipo Algodão',
        modalType: 'Tipo de Modal',
        modal: 'Modal',
        fixedCost: 'Custo Fixo',
        fixedCostUnity: 'Unidade - Custo Fixo',
        receivingCost: 'Custo de Recebimento',
        receivingCostUnity: 'Unidade - Custo de Recebimento',
        storageCost: 'Custo de Armazenamento',
        storageCostUnity: 'Unidade - Custo de Armazenamento',
        shippingCost: 'Custo de Expedição',
        shippingCostUnity: 'Unidade - Custo de Expedição',
        active: 'Ativo'
      },
      stretch: {
        title: 'Bogey List e Trechos',
        centerOrigin: 'Warehouse',
        centerTypeOrigin: 'Tipo Centro Origem',
        regionOrigin: 'Região Origem',
        countryOrigin: 'País Origem',
        stateOrigin: 'P Part 4',
        cityOrigin: 'Cidade Origem',
        regionDestination: 'Região Destino',
        centerDestination: 'Centro Destino',
        centerTypeDestination: 'Tipo Centro Destino',
        countryDestination: 'País Destino',
        stateDestination: 'UF Destino',
        cityDestination: 'Cidade Destino',
        modal: 'Modal',
        modalType: 'Tipo Modal',
        beginHorizon: 'Início',
        endHorizon: 'Fim',
        freightCost: 'Custo do Frete',
        freightCostUnity: 'Unidade de Custo do Frete',
        transitTime: 'Tempo de Trânsito',
        incoterm: 'Incoterm',
        operationType: 'Tipo de Operação',
        port: 'Porto Brasil',
        active: 'Ativo',
        customer: 'Cliente',
        errors: {
          atLeastOneGroupDestinationLocation:
            'Ao menos um grupo de localização de destino deve ser preenchido <br> Grupo1 - (País Destino - Estado  Destino - Cidade Destino) <br> Grupo 2 - (Tipo Centro  Destino, Centro  Destino) <br> Grupo 3 - (Cliente), <br> Grupo 4 - (Porto Brasil)',
          onlyOneGroupDestinationLocation:
            'Apenas um grupo de localização de destino deve ser preenchido <br> Grupo1 - (País Destino - Estado  Destino - Cidade Destino) <br> Grupo 2 - (Tipo Centro  Destino, Centro  Destino) <br> Grupo 3 - (Cliente), <br> Grupo 4 - (Porto Brasil)',
          atLeastOneGroupOriginLocation:
            'Ao menos um grupo de localização de origem deve ser preenchido <br> Grupo1 - (País Origem - Estado Origem - Cidade Origem) <br> Grupo 2 - (Tipo Centro Origem, Centro Origem)',
          onlyOneGroupOriginLocation:
            'Apenas um grupo de localização de origem deve ser preenchido <br> Grupo1 - (País Origem - Estado Origem - Cidade Origem) <br> Grupo 2 - (Tipo Centro Origem, Centro Origem)'
        }
      },
      modalInformation: {
        title: 'Capacidade Modal',
        beginHorizon: 'Início',
        endHorizon: 'Fim',
        modalType: 'Tipo de Modal',
        modal: 'Modal',
        capacity: 'Capacidade',
        capacityUnity: 'Unidade - Capacidade',
        active: 'Ativo',
        isTakeorPay: 'Take or Pay'
      },
      portStuffingTerminal: {
        title: 'Terminal de Estufagem',
        terminalCenterType: 'Tipo de Terminal',
        terminalCenter: 'Terminal',
        leadTimeDays: 'Lead Time (Dias)',
        freeTimeDays: 'Free Time (Dias)',
        isTakeOrPay: 'Take or Pay',
        active: 'Ativo',
        portStuffingTerminalInformation: {
          title: 'Capacidade Nominal de Terminal de Estufagem',
          beginHorizon: 'Início',
          endHorizon: 'Fim',
          portStuffingTerminal: 'Terminal de Estufagem',
          stuffingCapacity: 'Cap.Estufagem',
          stuffingCapacityUnity: 'Unid.Estufagem',
          active: 'Ativo'
        },
        portStuffingTerminalCost: {
          title: 'Custos de Terminal de Estufagem',
          beginHorizon: 'Início',
          endHorizon: 'Fim',
          portStuffingTerminal: 'Terminal de Estufagem',
          stuffingCost: 'Custo de Estufagem',
          stuffingCostUnity: 'Unidade - Custo de Estufagem',
          active: 'Ativo'
        },
        portStuffingTerminalAdditionalInformation: {
          title: 'Informações Adicionais de Terminal de Estufagem',
          portStuffingTerminal: 'Terminal de estufagem',
          beginHorizon: 'Início',
          endHorizon: 'Fim',
          destinationPort: 'Porto de Destino',
          transitTime: 'Tempo de Trânsito',
          transferTerminal: 'Terminal de Transbordo Portuário',
          active: 'Ativo'
        },
        portStuffingTerminalAvailableCapacity: {
          title: 'Capacidade Disponível do Terminal de Estufagem',
          portStuffingTerminal: 'Terminal de estufagem',
          portStuffingTerminalInformation:
            'Capacidade de Terminal de Estufagem',
          referenceDate: 'Data',
          stuffingUsedCapacity: 'Cap.Utilizada Estuf',
          stuffingUsedCapacityUnity: 'Unid.Utilizada Estuf',
          stuffingAvailableCapacity: 'Cap.Disponível Estuf',
          stuffingAvailableCapacityUnity: 'Unid.Disponível Estuf'
        },
        portStuffingTerminalVolumeTakeOrPay: {
          title: 'Volume Take or Pay',
          portStuffingTerminal: 'Terminal de Estufagem',
          begin: 'Inicio',
          end: 'Fim',
          volumeTakeOrPay: 'Volume Take Or Pay',
          volumeTakeOrPayUnity: 'Unidade de Medida  - Volume Take or Pay',
          active: 'Ativo'
        },
        portStuffingTerminalTakeOrPayCost: {
          title: 'Custos Take or Pay',
          portStuffingTerminal: 'Terminal de Estufagem',
          begin: 'Inicio',
          end: 'Fim',
          takeOrPayCost: 'Custo Take or Pay',
          takeOrPayCostUnity: 'Unidade de Medida  - Volume Take or Pay',
          active: 'Ativo'
        },
        portStuffingTerminalFreeTimeLogisticPortal: {
          title: 'Free Time Portal Logístico',
          portStuffingTerminal: 'Terminal de Estufagem',
          beginHorizon: 'Início',
          endHorizon: 'Fim',
          freeTimeDays: 'Free Time (Dias)',
          condition: 'Condição',
          active: 'Ativo'
        }
      },
      carrier: {
        title: 'Armador',
        code: 'Código',
        description: 'Descrição',
        nationalRegistry: 'Registro Nacional',
        active: 'Ativo',
        carrierFreeTimeInformation: {
          title: 'Informação de Free Time do Armador',
          beginHorizon: 'Início',
          endHorizon: 'Fim',
          carrier: 'Armador',
          originPort: 'Porto Origem',
          portStuffingTerminal: 'Terminal de Estufagem',
          subRegion: 'Sub-Regional',
          state: 'UF',
          city: 'Cidade',
          freeTime: 'Free Time',
          active: 'Ativo'
        },
        carrierCostAndTransitTimeInformation: {
          title: 'Custo e Transit Time',
          beginHorizon: 'Início',
          endHorizon: 'Fim',
          carrier: 'Armador',
          originPort: 'Porto Origem',
          destinationPort: 'Porto Destino',
          shippingCost: 'Custo',
          shippingCostUnity: 'Unidade - Custo',
          transitTime: 'Transit Time',
          active: 'Ativo'
        },
        carrierCapacityInformation: {
          title: 'Capacidade de Exportação',
          beginHorizon: 'Início',
          endHorizon: 'Fim',
          carrier: 'Armador',
          originPort: 'Porto Origem',
          region: 'Região Destino',
          shippingCapacity: 'Capacidade de Exportação',
          shippingCapacityUnity: 'Unidade - Capacidade de Exportação',
          active: 'Ativo'
        },
        carrierTerminalRestriction: {
          title: 'Restrição de Terminal de Estufagem',
          carrier: 'Armador',
          beginHorizon: 'Início',
          endHorizon: 'Fim',
          stuffingTerminal: 'Terminal de estufagem'
        }
      },
      impurityGreatness: {
        title: 'Contaminações',
        code: 'Código',
        description: 'Descrição'
      },
      optimizationConstraintMinimumWithdrawal: {
        title: 'Retirada Mínima',
        centerTypeId: 'Tipo de Centro',
        centerId: 'Centro',
        modalTypeId: 'Tipo de Modal',
        modalId: 'Modal',
        minimumWithdrawal: 'Quantidade Mínima',
        minimumWithdrawalUnityId: 'Unidade - Quantidade Mínima',
        active: 'Ativo',
        centerType: 'Tipo de Centro',
        center: 'Centro',
        modalType: 'Tipo de Modal',
        modal: 'Modal',
        minimumWithdrawalUnity: 'Unidade - Quantidade Mínima'
      },
      qualityGreatness: {
        title: 'Unidades de HVI',
        code: 'Código',
        description: 'Descrição',
        spreadReference: 'Referência para match de qualidade'
      },
      purchaseContract: {
        title: 'Contrato de Compra',
        company: 'Companhia',
        contractReference: 'Código de Referência',
        cropYear: 'Safra',
        marketType: 'Tipo de Mercado',
        material: 'Tipo Algodão',
        quantity: 'Quantidade',
        quantityUnity: 'Unidade de Medida - Quantidade',
        center: 'Counterparty Code',
        counterPartyName: 'Counterparty Name',
        incoterm: 'Incoterm',
        origin: 'Warehouse',
        producer: 'Counterparty Ref',
        uf: 'UF',
        counterPartySector: 'Modelo de Faturamento',
        contractPrice: 'Preço',
        contractPriceUnityCode: 'Unidade de Medida - Preço',
        contractDate: 'Data Contrato',
        positionMonth: 'Position Month',
        positionMonthProjection: 'Projeção Position Month',
        beginShipPeriod: 'Início Período Embarque',
        endShipPeriod: 'Fim Período Embarque',
        contractPricePoints: 'P/D',
        contractPricePointsUnity: 'Unidade de Medida - P/D',
        isContractProjection: 'Projeção',
        hasInventory: 'Estoque',
        active: 'Ativo',
        invoicedQuantity: 'Quantidade Faturada',
        usBucketProjectionOption1: 'Projeção de US Bucket (opção 1)',
        usBucketProjectionOption2: 'Projeção de US Bucket (opção 2)',
        usBucketProjectionOption3: 'Projeção de US Bucket (opção 3)',
        usBucketProjectionOption4: 'Projeção de US Bucket (opção 4)',
        usBucketProjectionOption5: 'Projeção de US Bucket (opção 5)',
        usBucketProjectionOption6: 'Projeção de US Bucket (opção 6)',
        usBucketProjectionOption7: 'Projeção de US Bucket (opção 7)',
        usBucketProjectionOption8: 'Projeção de US Bucket (opção 8)',
        volumeProjectionOption1: 'Projeção de Volume (opção 1)',
        volumeProjectionOption2: 'Projeção de Volume (opção 2)',
        volumeProjectionOption3: 'Projeção de Volume (opção 3)',
        volumeProjectionOption4: 'Projeção de Volume (opção 4)',
        volumeProjectionOption5: 'Projeção de Volume (opção 5)',
        volumeProjectionOption6: 'Projeção de Volume (opção 6)',
        volumeProjectionOption7: 'Projeção de Volume (opção 7)',
        volumeProjectionOption8: 'Projeção de Volume (opção 8)',
        volumeProjection: 'Projeção de Volume (total)',
        check: 'Check',
        lastItasUpdatedDate: 'Última Atualização ITAS',
        part5: 'Part5',
        purchaseContractQualitySpecification: {
          title: 'Especificação de Qualidade',
          purchaseContract: 'Contrato de Compra',
          qualityGreatness: 'Unidades de HVI',
          minValue: 'Valor Mínimo',
          maxValue: 'Valor Máximo',
          avgValue: 'Valor Médio'
        }
      },
      purchaseContractQualitySpecification: {
        title: 'Especificação de Qualidade'
      },
      flowProhibition: {
        title: 'Restrições de Fluxo',
        beginHorizon: 'Início',
        endHorizon: 'Fim',
        customerVIP: 'Indicador Cliente VIP',
        active: 'Ativo',
        purchaseContract: 'Contrato de Compra',
        modalType: 'Tipo de Modal',
        modal: 'Modal',
        centerTypeOrigin: 'Tipo de Centro Origem',
        centerOrigin: 'Warehouse',
        regionOrigin: 'Região Origem',
        countryOrigin: 'País Origem',
        stateOrigin: 'P Part 4',
        cityOrigin: 'Cidade Origem',
        centerTypeDestination: 'Tipo de Centro Destino',
        centerDestination: 'Centro Destino',
        regionDestination: 'Região Destino',
        countryDestination: 'País Destino',
        stateDestination: 'UF Destino',
        cityDestination: 'Cidade Destino',
        customer: 'Cliente',
        incoterm: 'Incoterm',
        errors: {
          atLeastOneGroupDestinationLocation:
            'Ao menos um grupo de localização de destino deve ser preenchido <br> Grupo 1 - (Região Destino - País Destino - Estado  Destino - Cidade Destino) <br> Grupo 2 - (Tipo Centro  Destino, Centro  Destino)',
          onlyOneGroupDestinationLocation:
            'Apenas um grupo de localização de destino deve ser preenchido <br> Grupo 1 - (Região Destino - País Destino - Estado  Destino - Cidade Destino) <br> Grupo 2 - (Tipo Centro  Destino, Centro  Destino)',
          atLeastOneGroupOriginLocation:
            'Ao menos um grupo de localização de origem deve ser preenchido <br> Grupo 1 - (Região Origem - País Origem - Estado Origem - Cidade Origem) <br> Grupo 2 - (Tipo Centro Origem, Centro Origem)',
          onlyOneGroupOriginLocation:
            'Apenas um grupo de localização de origem deve ser preenchido <br> Grupo 1 - (Região Origem - País Origem - Estado Origem - Cidade Origem) <br> Grupo 2 - (Tipo Centro Origem, Centro Origem)',
          onlyOneCenterOrCustomer:
            'Centro Origem e Cliente não podem ser cadastrados simultâneamente'
        }
      },
      limitedDaysWithoutCharge: {
        title: 'Prazo de ICMS',
        beginHorizon: 'Início',
        endHorizon: 'Fim',
        region: 'Região',
        country: 'País',
        state: 'P Part 4',
        limitedDays: 'Quantidade de dias limitante',
        active: 'Ativo',
        errors: {
          requiredLocality:
            'Ao menos uma informação de localização deve ser preenchida <br> (Região - País - Estado)'
        }
      },
      saleContract: {
        title: 'Contrato de Venda',
        company: 'Companhia',
        contractReference: 'Código de Referência',
        cropYear: 'Safra',
        marketType: 'Tipo de Mercado',
        material: 'Tipo Algodão',
        quantity: 'Quantidade',
        quantityUnity: 'Unidade de Medida - Quantidade',
        customer: 'Cliente',
        destination: 'Localidade Destino',
        port: 'Porto Destino',
        country: 'País',
        incoterm: 'Incoterm',
        counterPartySector: 'Modelo de Faturamento',
        contractPrice: 'Preço',
        contractPriceUnity: 'Unidade de Medida - Preço',
        contractDate: 'Data Contrato',
        positionMonth: 'Position Month',
        positionMonthProjection: 'Projeção Position Month',
        beginShipPeriod: 'Início Período Embarque',
        endShipPeriod: 'Fim Período Embarque',
        cakLSD: 'Cak LSD',
        minPercentageQuantityTolerance: 'Tolerância Atendimento Mínima (%)',
        maxPercentageQuantityTolerance: 'Tolerância Atendimento Máxima (%)',
        isContractProjection: 'Projeção',
        active: 'Ativo',
        isRiskySale: 'Venda de Risco',
        biDate: 'Data de BI',
        partialAllowed:
          'Permitir solver definir/sugerir parcelas de atendimento',
        usBucket: 'US Bucket',
        penaltyType: 'Tipo de Penalidade',
        customerReceiptExpectation: 'Expectativa de Recebimento no Cliente',
        penaltyAmountPercentage: 'Valor (%) penalidade',
        status: 'Status',
        invoicedQuantity: 'Quantidade Faturada',
        saleContractPremiumAndDiscount: 'Prêmio e Desconto Contrato de Venda',
        part5: 'Part5',
        lastItasUpdatedDate: 'Última Atualização ITAS',
        hints: {
          partialAllowed:
            'Deve ser utilizado em casos em que o contrato permite entrega parcial, porém, sem regra definida.'
        }
      },
      demandFulfillmentProhibition: {
        title: 'Restrições de Alocação',
        beginHorizon: 'Início',
        endHorizon: 'Fim',
        customerVIP: 'Indicador Cliente VIP',
        active: 'Ativo',
        purchaseContractCompany: 'Contrato de Compra - Companhia',
        purchaseContractCropYear: 'Contrato de Compra - Safra',
        purchaseContract: 'Contrato de Compra - Split',
        purchaseContractCak: 'Contrato de Compra - CAK',
        centerTypeOrigin: 'Tipo de Centro Origem',
        centerOrigin: 'Warehouse',
        centerTypeDestination: 'Tipo de Centro Destino',
        centerDestination: 'Centro Destino',
        saleContractCompany: 'Contrato de Venda - Companhia',
        saleContractCropYear: 'Contrato de Venda - Safra',
        saleContract: 'Contrato de Venda - Split',
        saleContractCak: 'Contrato de Venda - CAK',
        baleType: 'Tipo de Fardo',
        customer: 'Cliente',
        carrier: 'Armador',
        regionOrigin: 'Região Origem',
        countryOrigin: 'País Origem',
        stateOrigin: 'P Part 4',
        cityOrigin: 'Cidade Origem',
        regionDestination: 'Região Destino',
        countryDestination: 'País Destino',
        stateDestination: 'UF Destino',
        cityDestination: 'Cidade Destino',
        errors: {
          atLeastOneGroupDestinationLocation:
            'Ao menos um grupo de localização de destino deve ser preenchido <br> Grupo 1 - (Região Destino - País Destino - Estado  Destino - Cidade Destino) <br> Grupo 2 - (Indicador Cliente VIP - Cliente) <br> Grupo 3 - (Contrato de Venda)',
          onlyOneGroupDestinationLocation:
            'Apenas um grupo de localização de destino deve ser preenchido <br> Grupo 1 - (Região Destino - País Destino - Estado  Destino - Cidade Destino) <br> Grupo 2 - (Indicador Cliente VIP - Cliente) <br> Grupo 3 - (Contrato de Venda)',
          atLeastOneGroupOriginLocation:
            'Ao menos um grupo de localização de origem deve ser preenchido <br> Grupo 1 - (Região Origem - País Origem - Estado Origem - Cidade Origem) <br> Grupo 2 - (Tipo Centro Origem, Centro Origem) <br> Grupo 3 - (Contrato de Compra) <br> Grupo 4 - (Armador)',
          onlyOneGroupOriginLocation:
            'Apenas um grupo de localização de origem deve ser preenchido <br> Grupo 1 - (Região Origem - País Origem - Estado Origem - Cidade Origem) <br> Grupo 2 - (Tipo Centro Origem, Centro Origem) <br> Grupo 3 - (Contrato de Compra) <br> Grupo 4 - (Armador)'
        }
      },
      inventory: {
        title: 'Estoque',
        purchaseContract: 'Código de Referência',
        lotReference: 'Lot Ref',
        warehouse: 'Warehouse',
        ukGin: 'UK Gin',
        usBucket: 'US Bucket',
        material: 'Tipo Algodão',
        weight: 'Peso',
        weightUnit: 'Unidade de Medida - Peso',
        marketType: 'Tipo de Mercado',
        counterPartySector: 'Modelo de Faturamento',
        balesQuantity: 'Quantidade de Fardos por Lote',
        balesPerContainer: 'Fardos por Container',
        invoiceDate: 'Data de Faturamento',
        originState: 'UF de Origem',
        contractPricePoints: 'P/D',
        contractPricePointsUnit: 'Unidade de Medida - P/D',
        availabilityDate: 'Início da Disponibilidade',
        active: 'Ativo',
        lotId: 'ID do Lote',
        mark: 'Mark',
        part4: 'Part4',
        part5: 'Part5',
        lastItasUpdatedDate: 'Última Atualização ITAS',
        purchaseContractCompany: 'Contrato/Split - Companhia',
        purchaseContractCropYear: 'Contrato/Split - Safra',
        purchaseContractReference: 'Contrato/Split - Código de Referência',
        inventoryQualitySpecification: {
          title: 'Especificação de Qualidade',
          inventory: 'Estoque',
          qualityGreatness: 'Unidades de HVI',
          minValue: 'Valor Mínimo',
          avgValue: 'Valor Médio',
          maxValue: 'Valor Máximo',
          infValue: 'Inf',
          supValue: 'Sup'
        },
        inventoryImpuritySpecification: {
          title: 'Especificação de Impurezas',
          inventory: 'Estoque',
          impurityGreatness: 'Contaminações',
          maxValue: 'Nível'
        }
      },
      inventoryQualitySpecification: {
        title: 'Especificação de Qualidade'
      },
      inventoryImpuritySpecification: {
        title: 'Especificação de Impurezas'
      },
      incoterm: {
        title: 'Configuração Incoterm',
        type: 'Código',
        bookingPrecedingDays: 'Quantidade de Dias',
        rule: 'Regra',
        incotermRuleIncoterm: {
          title: 'Especificação de Regra Incoterm',
          incoterm: 'Incoterm',
          ruleIncoterm: 'Regra incoterm',
          active: 'Ativo'
        }
      },
      incotermRuleIncoterm: {
        title: 'Especificação de Regra Incoterm'
      },
      ibpIndicatorsDashboard: {
        title: 'Consolidado',
        indicatorName: 'Nome do Indicador',
        indicatorValue: 'Valor do Indicador',
        indicatorUnit: 'Unidade do Indicador'
      },
      ibpPurchasePlanDashboard: {
        title: 'Plano de Originação',
        purchaseCompany: 'Companhia',
        purchaseContractReference: 'Contrato de Compra/Split',
        purchaseCropYear: 'Safra',
        marketType: 'Tipo de Mercado',
        positionMonth: 'Position Month',
        incoterm: 'incoterm',
        usBucket: 'US Bucket',
        totalQuantity: 'Volume Originado',
        totalQuantityUnit: 'Unidade do Volume Originado',
        plannedQuantity: 'Volume Planejado',
        plannedQuantityUnit: 'Unidade do Volume Planejado',
        remainingQuantity: 'Volume Não Planejado',
        remainingQuantityUnit: 'Unidade do Volume Não Planejado',
        isProjection: 'Indicador Projeção',
        farmerName: 'Produtor',
        farmName: 'Fazenda/Algodoeira',
        originSubRegion: 'Sub-Regional',
        originState: 'UF',
        originCity: 'Cidade'
      },
      ibpDemandFullfilmentDashboard: {
        title: 'Atendimento Demanda - Dashboard IBP',
        saleCompany: 'Companhia',
        saleContractReference: 'Contrato de Venda/Split',
        saleCropYear: 'Safra',
        positionMonth: 'Position Month',
        shippingDate: 'Mês Planejado de Embarque',
        marketType: 'Tipo de Mercado',
        customer: 'Cliente',
        saleType: 'Tipo de Contrato',
        usBucket: 'US Bucket',
        totalQuantity: 'Volume Total',
        totalQuantityUnit: 'Unidade do Volume Total',
        plannedQuantity: 'Volume Planejado',
        plannedQuantityUnit: 'Unidade do Volume Planejado',
        remainingQuantity: 'Volume Não Atendido',
        remainingQuantityUnit: 'Unidade do Volume Não Atendido',
        minimumQuantity: 'Volume Mínimo',
        minimumQuantityUnit: 'Unidade do Volume Mínimo',
        maximumQuantity: 'Volume Máximo',
        maximumQuantityUnit: 'Unidade do Volume Máximo',
        isProjection: 'Projeção',
        destinationRegion: 'Região Destino',
        destinationCountry: 'País Destino',
        destinationState: 'UF Destino',
        destinationSubRegion: 'Sub-Regional Destino'
      },
      ibpDemandNotFullfiledByCottonConsolidated: {
        customer: 'Cliente',
        remainingQuantity: 'Volume (T)',
        destinationCountry: 'País'
      },
      ibpSupplyChainCostProjectionDashboard: {
        title: 'Projeção Custo Cadeia - Dashboard IBP',
        operationType: 'Tipo de Operação',
        planningMonth: 'Mês',
        plannedCost: 'Custo Planejado',
        plannedCostUnit: 'Unidade do Custo Planejado',
        plannedQuantity: 'Volume Movimentado',
        plannedQuantityUnit: 'Unidade do Volume Movimentado'
      },
      supplyChainBudget: {
        title: 'Budget',
        beginHorizon: 'Início',
        endHorizon: 'Fim',
        operationType: 'Tipo de Operação',
        budgetValue: 'Valor Estimado',
        budgetValueUnity: 'Unidade do Valor Estimado'
      },
      weeklyOpenContractsProjectionDashboard: {
        title: 'Projeção de Carta de Crédito',
        marketType: 'Tipo de Mercado',
        saleContractReference: 'Contrato de Venda/Split',
        shippingDate: 'Data de Embarque',
        creditDate: 'Data de Abertura',
        contractQuantity: 'Quantidade do Contrato',
        contractQuantityUnit: 'Unidade da Quantidade do Contrato',
        allocatedQuantity: 'Quantidade do Contrato Alocada',
        allocatedQuantityUnit: 'Unidade da Quantidade do Contrato Alocada',
        destinationRegion: 'Região Destino do Produto',
        destinationCountry: 'País Destino do Produto',
        customer: 'Nome do Cliente',
        contractProduct: 'Tipo de Algodão',
        isCustomerVIP: 'Cliente VIP'
      },
      weeklyBookingProjectionDashboard: {
        title: 'Projeção de Booking',
        carrierName: 'Nome do Armador',
        bookingRef: 'Booking Ref #',
        bookingCode: 'Id do Booking',
        bookingDate: 'Data do Booking',
        burdenType: 'Tipo de Fardo',
        expirationDate: 'Prazo de Vencimento',
        weekNumber: 'Semana relativa à data do Booking',
        destinationRegion: 'Região Destino do Booking',
        destinationCountry: 'País Destino do Booking',
        originPort: 'Porto de Origem',
        destinationPort: 'Porto de Destino',
        totalContainerQuantity: 'Quantidade Total de Containers',
        containerVolume: 'Volume do Container',
        containerVolumeUnit: 'Unidade de Medida do Volume do Container',
        usedContainerQuantity: 'Quantidade de Containers Utilizados',
        terminal: 'Terminal de Estufagem',
        saleContractReference: 'Código do Contrato de Venda'
      },
      weeklyPurchaseProjectionDashboard: {
        title: 'Projeção de Originação',
        purchaseContractReference: 'Contrato de Compra/Split',
        positionMonth: 'Position Month',
        shippingDateStart: 'Início planejado de Carregamento',
        shippingDateEnd: 'Fim planejado de Carregamento',
        availabilityDate: 'Data Disponibilidade Algodão',
        lotReference: 'Lote',
        planningWeek: 'Semana Relativa à Data Planejada de Carregamento',
        incoterm: 'Contract Term',
        contractProduct: 'Tipo de Algodão',
        totalQuantity: 'Volume Comprado',
        totalQuantityUnity: 'Unidade do Volume Comprado',
        plannedQuantity: 'Volume Planejado',
        plannedQuantityUnity: 'Unidade do Volume Planejado',
        remainingQuantity: 'Volume Remanescente',
        remainingQuantityUnit: 'Unidade do Volume Remanescente',
        allocatedQuantity: 'Volume Alocado',
        allocatedQuantityUnity: 'Unidade do Volume Alocado',
        farmerName: 'Nome do Produtor',
        originCenter: 'Centro',
        originCenterType: 'Tipo de Centro',
        originRegion: 'Região',
        originState: 'UF',
        originSubRegion: 'Sub-Regional',
        originCity: 'Cidade',
        isProjection: 'Projeção'
      },
      weeklyDemandFullfilmentDashboard: {
        title: 'Atendimento de Demanda',
        saleContractReference: 'Contrato de Venda/Split',
        positionMonth: 'Position Month',
        plannedServiceStartDate: 'Início do Período de Atendimento Planejado',
        plannedServiceEndDate: 'Fim do Período de Atendimento Planejado',
        marketType: 'Tipo de Mercado',
        contractProduct: 'Tipo de Algodão',
        carrierName: 'Armador',
        terminal: 'Terminal de Estufagem',
        customer: 'Cliente',
        minimumQuantity: 'Quantidade Mínima do Contrato',
        minimumQuantityUnit: 'Unidade da Quantidade Mínima do Contrato',
        maximumQuantity: 'Quantidade Máxima do Contrato',
        maximumQuantityUnit: 'Unidade da Quantidade Máxima do Contrato',
        totalQuantity: 'Quantidade Total do Contrato',
        totalQuantityUnity: 'Unidade da Quantidade Total do Contrato',
        plannedQuantity: 'Quantidade Planejada do Contrato',
        plannedQuantityUnity: 'Unidade da Quantidade Planejada do Contrato',
        remainingQuantity: 'Quantidade Remanescente do Contrato',
        remainingQuantityUnit: 'Unidade da Quantidade Remanescente do Contrato',
        destinationPort: 'Porto Destino',
        destinationRegion: 'Região Destino',
        destinationState: 'Estado Destino',
        destinationSubRegion: 'Sub-Regional Destino',
        destinationCountry: 'País Destino',
        isProjection: 'Projeção',
        saleType: 'Tipo de Contrato'
      },
      weeklyCapacityUtilizationDashboard: {
        title: 'Projeção de Capacidade Utilizada',
        operationType: 'Operação Logística',
        purchaseContractReference: 'Contrato de Compra/Split',
        shippingStartDate: 'Início do Carregamento',
        shippingEndDate: 'Fim do Carregamento',
        operatorName: 'Centro',
        carrierName: 'Armador',
        planningDate: 'Data do Planejamento',
        planningWeek: 'Semana do Planejamento',
        plannedCapacity: 'Volume Planejado',
        plannedCapacityUnity: 'Unidade do Volume Planejado',
        operationCapacity: 'Volume Contratado',
        operationCapacityUnity: 'Unidade do Volume Contratado'
      },
      reports: {
        title: 'Relatórios'
      },
      servicePlan: {
        title: 'Plano de Atendimento'
      },
      transferPlan: {
        title: 'Plano de Transferência'
      },
      portPlan: {
        title: 'Plano Portuário'
      },
      carrierCapacityUtilization: {
        title: 'Utilização Capacidade Armador'
      },
      inventoryProjection: {
        title: 'Projeção de Estoque'
      },
      bookingUtilization: {
        title: 'Use of Booking'
      },
      leadtimebyCountry: {
        title: 'Lead Time Adicional País de Destino',
        destinationCountry: 'País',
        leadtime: 'Lead Time (dias)',
        active: 'Ativo',
        beginPeriodDate: 'Início',
        endPeriodDate: 'Fim',
        leadtimeCapacityContainer: {
          title: 'Capacidade de Não Utilização de Containers',
          capacityContainer: 'Capacidade Container (Ton)',
          minBales110: 'Min. Fardos 110',
          maxBales110: 'Max. Fardos 110',
          minBales124: 'Min. Fardos 124',
          maxBales124: 'Max. Fardos 124',
          leadtimebyCountry: 'Lead Time Adicional País de Destino'
        }
      },
      leadtimeCapacityContainer: {
        title: 'Capacidade de Não Utilização de Containers'
      },
      fullfilledContractsDashboard: {
        title: 'Contratos Realizados Dashboard',
        saleContractReference: 'Código do Split do Contrato de Venda',
        positionMonth: 'Position Month',
        invoiceDate: 'Data de Execução',
        invoiceWeek: 'Número da Semana',
        marketType: 'Tipo de Mercado',
        contractProduct: 'Tipo de Algodão',
        carrierName: 'Nome do Armador',
        terminal: 'Terminal de Estufagem',
        customer: 'Cliente',
        invoicedQuantity: 'Quantidade Faturada',
        invoicedQuantityUnit: 'Unidade de Medida da Quantidade Faturada',
        totalQuantity: 'Quantidade Total',
        totalQuantityUnit: 'Unidade de Medida da Quantidade Total',
        destinationPort: 'Porto de Destino',
        destinationCountry: 'País do Cliente',
        lastUpdate: 'Data da Última Atualização'
      },
      programmedContractsDashboard: {
        title: 'Contratos Programados Dashboard'
      },
      programmedContractsShipmentsDashboard: {
        title: 'Contratos Programados Shipments Dashboard'
      },
      port: {
        title: 'Portos',
        code: 'Código',
        observation: 'Observação',
        region: 'Região',
        country: 'País',
        active: 'Ativo'
      },
      departureTerminal: {
        title: 'Terminal de Embarque',
        code: 'Código',
        port: 'Porto',
        description: 'Descrição',
        active: 'Ativo',
        departureTerminalStuffingTerminal: {
          title: 'Terminais de Estufagem',
          departureTerminal: 'Terminal de embarque',
          stuffingTerminal: 'Terminal de estufagem',
          active: 'Ativo'
        },
        departureTerminalFreeTime: {
          title: 'Free Time',
          departureTerminal: 'Terminal de Embarque',
          beginHorizon: 'Início',
          endHorizon: 'Fim',
          freeTimeDays: 'Dias de Free Time',
          active: 'Ativo'
        }
      },
      movementItas: {
        title: 'Movimentações ITAS',
        groupNumber: 'Group Id',
        planId: 'Plan Id',
        originCenter: 'Warehouse',
        expectedStartLoadDate: 'Início data esperada carregamento origem',
        expectedEndLoadDate: 'Fim data esperada carregamento origem',
        destinationCenter: 'Destino Centro',
        destinationCustomer: 'Destino Cliente',
        arrivalMinimumDate: 'Início data esperada chegada destino',
        arrivalMaximumDate: 'Fim data esperada chegada destino',
        netWeight: 'Peso líquido',
        netWeightUnity: 'Unidade - Peso líquido',
        purchaseContractReference: 'Contrato de Compra',
        lotReference: 'Lote',
        marks: 'Marks',
        saleContractReference: 'Contrato de Venda Alocado',
        lastItasUpdatedDate: 'Data de última atualização do Itas',
        calculateAvailableCapacityButton: {
          label: 'Calcular capacidades disponíveis'
        },
        events: {
          availableCapacitiesGeneratedSuccessfully:
            'As capacidades disponíveis foram calculadas com sucesso',
          errorOnGenerateAvailableCapacities:
            'Erro ao realizar cálculo de capacidades disponíveis'
        }
      },
      storageTerminal: {
        title: 'Terminal de Armazenagem',
        code: 'Código',
        description: 'Descrição',
        terminalCenter: 'Terminal de Estufagem',
        active: 'Ativo',
        isTakeOrPay: 'Take or Pay',
        storageTerminalInformation: {
          title: 'Capacidade do Terminal de Armazenagem',
          storageTerminal: 'Terminal de Armazenagem',
          beginHorizon: 'Início',
          endHorizon: 'Fim',
          storageCapacity: 'Capacidade Armazenagem',
          storageCapacityUnit: 'Unidade de Medida - Capacidade Armazenagem',
          active: 'Ativo'
        },
        storageTerminalCost: {
          title: 'Custos do Terminal de Armazenagem',
          storageTerminal: 'Terminal de Armazenagem',
          beginHorizon: 'Início',
          endHorizon: 'Fim',
          storageCost: 'Custo Armazenagem',
          storageCostUnit: 'Unidade de Medida - Custo Armazenagem',
          active: 'Ativo'
        },
        storageTerminalVolumeTakeOrPay: {
          title: 'Volume Take or Pay',
          storageTerminal: 'Terminal de Armazenagem',
          begin: 'Inicio',
          end: 'Fim',
          volumeTakeOrPay: 'Volume Take Or Pay',
          volumeTakeOrPayUnity: 'Unidade de Medida  - Volume Take or Pay',
          active: 'Ativo'
        },
        storageTerminalTakeOrPayCost: {
          title: 'Custos Take or Pay',
          storageTerminal: 'Terminal de Armazenagem',
          begin: 'Inicio',
          end: 'Fim',
          takeOrPayCost: 'Custo Take or Pay',
          takeOrPayCostUnity: 'Unidade de Medida  - Volume Take or Pay',
          active: 'Ativo'
        }
      },
      usBucket: {
        title: 'US Bucket e PD',
        code: 'US Bucket Código',
        description: 'Descrição',
        active: 'Ativo',
        usBucketPD: {
          title: 'Prêmio e Desconto US Bucket',
          pdPurchase: 'P/D Compra',
          pdPurchaseUnity: 'Unidade de Medida - P/D Compra',
          pdSale: 'P/D Venda',
          pdSaleUnity: 'Unidade de Medida - P/D Venda',
          harvest: 'Safra',
          active: 'Ativo',
          usBucket: 'US Bucket'
        },
        usBucketCountry: {
          title: 'País US Bucket',
          country: 'País',
          maxLimit: 'Limite Máximo Aceitável',
          active: 'Ativo',
          usBucket: 'US Bucket'
        }
      },
      usBucketPD: {
        title: 'Prêmio e Desconto US Bucket'
      },
      usBucketCountry: {
        title: 'País US Bucket'
      },
      saleContractPremiumAndDiscount: {
        title: 'Aplicação e PD',
        company: 'Companhia',
        contractReference: 'Contrato',
        premiumDiscount: 'P/D',
        premiumDiscountUnity: 'Unidade de Medida - P/D',
        flexibilityToExceedMaxApplication:
          'Flexidade para ultrapassar % Max Aplicação (Qualidade e Tipo)',
        saleContractPremiumAndDiscountUSBucketAplication: {
          title: 'Aplicação de US Bucket',
          usBucket: 'US Bucket',
          maxPercentage: 'Percentual Máximo de Atendimento',
          saleContractPremiumAndDiscount: 'Aplicação e PD',
          active: 'Ativo'
        },
        saleContractPremiumAndDiscountTypeAplication: {
          title: 'Aplicação de tipo',
          material: 'Tipo Algodão',
          maxPercentage: 'Percentual Máximo de Atendimento',
          saleContractPremiumAndDiscount: 'Aplicação e PD',
          active: 'Ativo'
        },
        saleContractPremiumAndDiscountQualityAplication: {
          title: 'Aplicação de Qualidade',
          qualityGreatness: 'Unidades de HVI',
          minValue: 'Valor Mínimo',
          maxValue: 'Valor Máximo',
          lowerAvgValue: 'Valor Médio Inferior',
          upperAvgValue: 'Valor Médio Superior',
          maxPercentage: 'Percentual Máximo de Atendimento',
          saleContractPremiumAndDiscount: 'Aplicação e PD',
          active: 'Ativo'
        },
        saleContractPremiumAndDiscountImpurityAplication: {
          title: 'Grau Permitido de Impureza',
          impurityGreatness: 'Contaminações',
          maxValue: 'Grau Máximo',
          saleContractPremiumAndDiscount: 'Aplicação e PD',
          active: 'Ativo'
        }
      },
      saleContractPremiumAndDiscountUSBucketAplication: {
        title: 'Aplicação de US Bucket'
      },
      saleContractPremiumAndDiscountTypeAplication: {
        title: 'Aplicação de tipo'
      },
      saleContractPremiumAndDiscountQualityAplication: {
        title: 'Aplicação de Qualidade'
      },
      saleContractPremiumAndDiscountImpurityAplication: {
        title: 'Grau Permitido de Impureza'
      },
      configurationArrivalWindow: {
        title: 'Janela de Descarga',
        limitInfBooking: 'Limit Inf. Ctnr Booking',
        limitSupBooking: 'Limite Sup. Ctnr Booking',
        breakDays: 'Dias de Intervalo'
      },
      monthlyDemandAttendanceReport: {
        title: `Plano de Atendimento Mensal`,
        marketType: `Tipo de Mercado Venda`,
        carrier: `Armador`,
        originPort: `Porto Origem`,
        exportationReferencePeriod: `ETS`,
        carrierAvailableContainers: `Capacidade de Containers`,
        plannedContainersUsed: `Utilização Planejada (Cntr)`,
        saleContractCode: `Venda`,
        solverSaleSplitId: `Split Venda`,
        saleContractType: `Tipo de Contrato`,
        client: `Cód. Cliente`,
        destinationRegion: `Região do Destino`,
        vip: `VIP`,
        saleRisk: `Venda Risco`,
        saleCottonType: `US Bucket Venda`,
        saleDemandValue: `Volume Total`,
        saleContractPositionMonth: `Position Month Venda`,
        shipmentDeadline: `Data de Limite de Embarque`,
        expectationOfReceipt: `Mês Limite Penalidade`,
        premiumDiscountSale: `P/D Venda`,
        purchaseContractCode: `Compra`,
        purchaseContractCompany: `Companhia`,
        purchaseContractType: `Tipo de Contrato`,
        producer: `Produtor`,
        purchaseContractIncoterm: `Incoterm Compra`,
        productionOrigin: `Warehouse`,
        city: `Cidade`,
        state: `P Part 4`,
        country: `País`,
        region: `Sub-Regional UK Gin`,
        purchaseSaleType: `Tipo de Algodão`,
        contractVolume: `Volume Contrato`,
        contractPlannedUsedVolume: `Volume Planejado`,
        stuffingTerminalUsed: `Terminal de Estufagem Utilizado`,
        carrierPlannedCapacityUtilizationValue: `Capacidade do Terminal de Estufagem`,
        carrierPlannedCapacityUtilizationUnit: `Unidade - Capacidade do Terminal de Estufagem`,
        destinationArrivalExpectationDate: `Lead Time da Entrega`,
        saleContractPenaltyCost: `Custo PNL Venda`,
        plannedPenaltyCost: `Custo Penalidade Planejado`,
        carrierCosts: `Custo Armador (R$)`,
        contractPlannedUsedVolumeUnit: 'Unidade - Volume Planejado',
        contractVolumeUnit: 'Unidade - Volume Contrato',
        plannedPenaltyCostUnit: 'Unidade - Custo Penalidade Planejado',
        premiumDiscountSaleUnit: 'Unidade - P/D Venda',
        saleContractPenaltyCostUnit: 'Unidade - Custo PNL Venda',
        saleDemandValueUnit: 'Unidade - Volume Total',
        purchaseCottonType: 'US Bucket Compra',
        carrierDestinationRegion: 'Região Armador',
        splitSalePd: 'P/D Venda',
        splitSalePdUnit: 'Unidade - P/D Venda',
        splitSalePlannedPd: 'Ganho de P/D',
        splitSalePlannedPdUnit: 'Unidade - Ganho de P/D',
        purchaseContractPd: 'P/D Compra',
        purchaseContractPdUnit: 'Unidade - P/D Compra',
        attendanceMonth: 'Mês de Atendimento',
        attendanceType: 'Estoque',
        destination: 'Destino',
        destinationCountry: 'País de Destino',
        purchaseContractBeginShipPeriod: 'Data de Limite de Recebimento',
        purchaseContractCounterPartyName: 'Counter Party Code',
        purchaseContractCounterPartyRef: 'Counter Party Ref',
        purchaseContractCounterPartySector: 'FAS Indicator',
        purchaseContractCropYear: 'Safra Compra',
        purchaseContractPositionMonth: 'Position Month Compra',
        saleContractCropYear: 'Safra Venda',
        saleContractIncoterm: 'Incoterm Venda',
        saleDemandAttendedValue: 'Demanda Atendida',
        saleDemandAttendedValueUnit: 'Unidade - Demanda Atendida',
        saleDemandAttendedValueUnitCode: 'Unidade - Demanda Atendida',
        saleMaxPercentageQuantityTolerance: 'Tolerância Máxima',
        saleMinPercentageQuantityTolerance: 'Tolerância Mínima',
        stuffingCosts: 'Custo de Estufagem (R$)',
        stuffingPlannedVolume: 'Estufagem Planejada',
        stuffingPlannedVolumeUnit: 'Unidade - Estufagem Planejada',
        stuffingPlannedVolumeUnitCode: 'Unidade - Estufagem Planejada'
      },
      weeklyDemandAttendanceReport: {
        title: 'Plano de Atendimento Semanal',
        saleContractCropYear: 'Safra Venda',
        splitSaleContract: 'Venda',
        saleContractCompany: 'Companhia Venda',
        saleSplit: 'Split Venda',
        saleMarketType: 'Tipo de Mercado Venda',
        client: 'Cliente',
        clientLocation: 'Localidade Cliente',
        vip: 'Vip',
        saleRisk: 'Venda Risco',
        cottonType: 'Tipo de Algodão Venda',
        volume: 'Volume',
        volumeUnit: 'Unidade de Medida - Volume',
        minimumTolerance: 'Tolerância Mínima',
        maximumTolerance: 'Tolerância Máxima',
        positionMonth: 'Position Month',
        shipmentDeadline: 'Limite para Embarque',
        salePd: 'P/D Venda',
        salePdUnit: 'Unidade de Medida - P/D Venda',
        purchaseContractCropYear: 'Safra Compra',
        splitPurchaseContract: 'Compra',
        purchaseContractCompany: 'Companhia Compra',
        lotRef: 'Lote Ref',
        counterParty: 'Contraparte',
        producer: 'Counterparty Ref',
        warehouse: 'Warehouse',
        lotBales: 'Fardos Lote',
        lotVolume: 'Volume Lote',
        lotVolumeUnit: 'Unidade de Medida - Volume Lote',
        plannedBales: 'Fardos Planejados',
        plannedVolume: 'Volume Planejado',
        plannedVolumeUnit: 'Unid. Medida - Volume Planejado',
        exportDeadline: 'Prazo Exportação',
        plannedOverLimit: 'Planejado Over limite',
        baleType: 'Tipo de Fardo',
        cottonTypeLot: 'Tipo de Algodão Lote',
        lotPd: 'P/D Lote',
        lotPdUnit: 'Unidade de Medida - P/D Lote',
        plannedPd: 'P/D Planejado',
        plannedPdUnit: 'Unidade de Medida - P/D Planejado',
        cauleNivel: 'CauleNivel',
        picaoNivel: 'PicaoNivel',
        fumaginaIncid: 'FumaginaIncid',
        capimIncid: 'CapimIncid',
        sementeIncid: 'SementeIncid',
        infLength: 'InfLength',
        minimumLength: 'MinimumLength',
        maximumLength: 'MaximumLength',
        length: 'Length',
        infStrength: 'InfStrength',
        minimumStrength: 'MinimumStrength',
        maximumStrength: 'MaximumStrength',
        averageStrength: 'AverageStrength',
        infMic: 'InfMic',
        minimumMicronaire: 'MinimumMicronaire',
        maximumMicronaire: 'MaximumMicronaire',
        averageMicronaire: 'AverageMicronaire',
        supMic: 'SupMic',
        shortFibre: 'ShortFibre',
        part4: 'Part4',
        part5: 'Part5',
        marketSectorCode: 'MarketSectorCode',
        incoterm: 'Incoterm',
        loadDate: 'Data Carregamento',
        loadWeek: 'Semana Carregamento',
        originCenter: 'Warehouse',
        originCenterType: 'Tipo Centro Origem',
        originCity: 'Cidade Origem',
        originState: 'UF Origem',
        subRegional: 'Sub-Regional UK Gin',
        roadMap: 'Roteiro',
        modal: 'Modal',
        modalType: 'Tipo Modal',
        unloadDate: 'Data Descarga',
        unloadWeek: 'Semana Descarga',
        destinationCenter: 'Centro Destino',
        destinationCenterType: 'Tipo Centro Destino',
        pol: 'POL',
        destinationCity: 'Cidade Destino',
        destinationState: 'UF Destino',
        freightCost: 'Custo Frete',
        freightCostUnit: 'Unidade de Medida - Custo Frete',
        plannedFreightCost: 'Custo Frete Planejado (R$)',
        stuffingTerminal: 'Terminal Estufagem',
        stuffingLeadTimeDays: 'LeadTime Estufagem (dias)',
        saleLeadTimeDays: 'LeadTime Venda (dias)',
        freeTimeStuffingTerminalDays: 'FreeTime Terminal de Estufagem (dias)',
        overtimeStuffingDays: 'Overtime Estufagem (dias)',
        ddlCargoBalanceDays: 'Saldo DDL Carga (dias)',
        bookingRef: 'Booking Ref#',
        bookingCode: 'Código Booking',
        splitBooking: 'Split Booking',
        ship: 'Navio',
        trip: 'Viagem',
        carrier: 'Armador',
        departureTerminal: 'Terminal de Embarque',
        destinationRegion: 'Região de Destino',
        destinationCountry: 'Região de País',
        pod: 'POD',
        portTransshipment: 'Porto Transbordo',
        deadlineDraft: 'Deadline Draft',
        deadlineCargo: 'Deadline Cargo',
        ets: 'ETS',
        etsWeek: 'Semana ETS',
        etaPod: 'ETA POD',
        availableCntrs: 'Cntrs Disponíveis',
        availableBookingVolume: 'Volume Disponível do Booking (MT)',
        plannedCntrs: 'Cntrs Planejados',
        plannedVolumeMt: 'Volume Planejado (MT)',
        carrierFreeTime: 'Free Time Armador',
        cntrsWithdrawalDate: 'Data retirada Cntrs',
        seaFreight: 'Frete Marítimo',
        seaFreightUnit: 'Unidade de Medida - Frete Marítimo',
        plannedCostBkg: 'Custo Planejado BKG',
        plannedCostBkgUnit: 'Unidade de Medida - Custo Planejado BKG',
        overtimeCost: 'Custo Overtime',
        overtimeCostUnit: 'Unidade de Medida - Custo Overtime',
        plannedOvertimeCost: 'Custo Overtime Planejado',
        plannedOvertimeCostUnit: 'Unidade de Medida - Custo Overtime Planejado',
        costPenaltySalesContract: 'Custo PNL Venda',
        costPenaltySalesContractUnit: 'Unidade - Custo PNL Venda',
        plannedPenaltyCost: 'Custo Penalidade Planejado',
        plannedPenaltyCostUnit:
          'Unidade de Medida - Custo Penalidade Planejado',
        totalPlannedCost: 'Custo Total Planejado',
        totalPlannedCostUnit: 'Unidade de Medida - Custo Total Planejado'
      },
      weeklyDemandAttendanceResumeReport: {
        title: 'Plano de Atendimento Semanal Resumo',
        purchaseSplitCode: 'Purchase Split',
        warehouse: 'Warehouse',
        lot: 'Lote',
        balesNumber: 'No. Of Bales',
        mark1: 'Mark',
        mark2: 'Mark',
        brainMark: 'Mark Brain'
      },
      weeklyTabularReportsCostProjection: {
        title: 'Projeção de Custos',
        logistics: 'Logística BR',
        storage: 'Armazenagem',
        stuffing: 'Estufagem',
        maritimeBooking: 'Marítimo',
        weeklyTabularReportsCostProjectionLogistics: {
          departure: 'Saída',
          arrival: 'Chegada',
          weekDeparture: 'Semana Saída',
          weekArrival: 'Semana Chegada',
          subRegionOrigin: 'Sub-Regional UK Gin',
          stateOrigin: 'UF Origem',
          cityOrigin: 'Cidade Origem',
          route: 'Roteiro',
          lat: 'Lat',
          long: 'Long',
          originCenter: 'Warehouse',
          originCenterType: 'Tipo de Centro Origem',
          company: 'Companhia',
          purchaseSplitContract: 'Contrato de Compra/Split',
          cropYear: 'Safra',
          lotRef: 'Lote Ref',
          cottonType: 'Tipo de Algodão',
          baleType: 'Tipo de Fardo',
          incoterm: 'Incoterm',
          modal: 'Modal',
          stateDestination: 'UF Destino',
          cityDestination: 'Cidade Destino',
          destinationCenter: 'Centro Destino',
          destinationCenterType: 'Tipo de Centro Destino',
          originPort: 'Porto Origem',
          client: 'Cliente',
          plannedBalesQuantity: 'Qtde Fardos Planejado',
          plannedVolume: 'Volume Planejado (Ton)',
          cost: 'Custo (R$)'
        },
        weeklyTabularReportsCostProjectionStorage: {
          referenceMonth: 'Mês Referência',
          day: 'Dia',
          week: 'Semana',
          center: 'Centro',
          centerType: 'Tipo Centro',
          centerState: 'UF do Centro',
          contractedVolume: 'Vol. Contratado',
          takeOrPayVolumeMonth: 'Vol. Take or Pay Mês',
          autoUpdateVolume: 'Vol. Auto-Update',
          plannedVolume: 'Vol. Planejado',
          availableVolume: 'Vol. Disponível',
          takeOrPayCostMonth: 'Custo Take or Pay Mês (R$)',
          additionalCostDay: 'Custo Adicional Dia (R$)',
          nonUtilizationPenaltyTakeOrPayMonth:
            'Penalidade Não Utilização Take or Pay Dia (R$)',
          takeOrPay: 'Take or Pay'
        },
        weeklyTabularReportsCostProjectionStuffing: {
          referenceMonth: 'Mês Referência',
          day: 'Dia',
          week: 'Semana',
          center: 'Centro',
          centerType: 'Tipo Centro',
          centerState: 'UF do Centro',
          contractedVolume: 'Vol. Contratado',
          contractedVolumeUnit: 'Unidade Vol. Contratado',
          takeOrPayVolumeMonth: 'Vol. Take or Pay Mês',
          autoUpdateVolume: 'Vol. Auto-Update',
          plannedVolume: 'Vol. Planejado',
          availableVolume: 'Vol. Disponível',
          contractedCntr: 'Cntr Contratado',
          takeOrPayCntrMonth: 'Cntr Take Or Pay Mês',
          autoUpdateCntr: 'Cntr Auto Update ',
          plannedCntr: 'Cntr Planejado',
          availableCntr: 'Cntr Disponível',
          takeOrPayCostMonth: 'Custo Take or Pay Mês (R$)',
          additionalCostDay: 'Custo Adicional Dia (R$)',
          nonUtilizationPenaltyTakeOrPayMonth:
            'Penalidade Não Utilização Take or Pay Mês (R$)',
          takeOrPay: 'Take or Pay'
        },
        weeklyTabularReportsCostProjectionMaritimeBooking: {
          ets: 'ETS',
          week: 'Semana',
          originPort: 'Porto Origem',
          loadingTerminal: 'Terminal de Embarque',
          bookingRef: 'Booking Ref#',
          bookingCode: 'Código do Booking',
          splitBooking: 'Split Booking',
          vessel: 'Navio',
          voyage: 'Viagem',
          carrier: 'Armador',
          destinationRegion: 'Região Destino',
          destinationCountry: 'País Destino',
          destinationPort: 'Porto Destino',
          company: 'Companhia',
          saleContractSplit: 'Contrato Venda/Split',
          cropYear: 'Safra',
          saleSplit: 'Split Venda',
          plannedVolume: 'Vol. Planejado (Mt)',
          plannedContainerQty: 'Qtde. Cntr. Planejado',
          cost: 'Custo (R$)'
        }
      },
      weeklyPlanStuffingReport: {
        title: 'Plano de Estufagem',
        company: 'Companhia',
        instruction: 'Instrução',
        cropYear: 'Safra',
        splitSale: 'Split Venda',
        cntr: '# Cntr',
        fdsCntr: '# Fds/Cntr',
        total: 'Total',
        type: 'Tipo'
      },
      weeklySummaryPlanAllocationReport: {
        title: 'Plano Alocação Resumo',
        splitPurchase: 'Split de Compra',
        warehouse: 'Warehouse',
        lotReference: 'Lote Ref',
        numBales: 'Nº de fardos',
        mark: 'Mark',
        mark1: 'Mark',
        markBrain: 'Mark Brain'
      }
    },
    enums: {
      notificationEvent: {
        general: 'Geral',
        scenario: 'Cenário'
      },
      scoNotificationEvent: {
        general: 'Geral',
        scenario: 'Cenário'
      },
      integrationType: {
        executedBooking: 'ITAS - Booking Executado',
        executedSale: 'ITAS - Venda Executada',
        inventory: 'ITAS - Estoque',
        planningBooking: 'ITAS - Planejamento de Bookings',
        planningOpenContracts: 'ITAS - Planejamento de Contratos Abertos',
        planningPurchase: 'ITAS - Planejamento de Compra',
        planningSale: 'ITAS - Planejamento de Venda',
        takeUpPurchaseContract: 'ITAS - Take-Up - Contrato Compra',
        inventoryTakeUp: 'ITAS - Take-Up - Estoque',
        bookingsStuffingPortal: 'ITAS - Bookings - Portal Estufagem',
        bookingsStuffingPortalCasa: 'ITAS - Bookings - Portal Estufagem - CASA',
        bookingsStuffingPortalFob: 'ITAS - Bookings - Portal Estufagem - FOB',
        brLogOnTime: 'ITAS - Br Log On Time',
        shipmentsStuffingPortal: 'ITAS - Shipments - Portal Estufagem',
        shipmentsStuffingPortalFob: 'ITAS - Shipments - Portal Estufagem - FOB',
        shipmentsStuffingPortalCasa:
          'ITAS - Shipments - Portal Estufagem - CASA',
        inventoryLogisticPortal: 'ITAS - Inventory - Portal Logístico',
        shipmentsLogisticPortal: 'ITAS - Shipments - Portal Logístico',
        shipmentsCockpit: 'ITAS - Shipments - Cockpit',
        inventoryCockpit: 'ITAS - Inventory - Cockpit',
        bookingsCockpit: 'ITAS - Bookings - Cockpit',
        deleteNotification: 'Deletar Notificação',
        shipmentsMovementItas: 'ITAS - Shipments - Movimentações Itas',
        programmedBooking: 'ITAS - Booking Programado',
        programmedShipments: 'ITAS - Shipments Programado',
        inventoryIbpMonthlyReview: 'ITAS - Inventory - IBP Monthly Review',
        purchaseAndSalesIbpMonthlyReview:
          'ITAS - Purchase and Sales - IBP Monthly Review',
        bookingsIbpMonthlyReview: 'ITAS - Bookings - IBP Monthly Review',
        shipmentsIbpMonthlyReview: 'ITAS - Shipments - IBP Monthly Review'
      },
      integrationEvent: {
        created: 'Criado',
        updated: 'Atualizado',
        deleted: 'Excluído',
        ignored: 'Ignorado'
      },
      scenarioCargillType: {
        monthly: 'Mensal',
        weekly: 'Semanal',
        masterData: 'Dados Mestre'
      },
      scenarioDataSourceType: {
        blank: 'Em Branco',
        copy: 'Cópia',
        master: 'Dados Mestre'
      },
      weekDaysType: {
        sunday: 'Domingo',
        monday: 'Segunda',
        tuesday: 'Terça',
        wednesday: 'Quarta',
        thursday: 'Quinta',
        friday: 'Sexta',
        saturday: 'Sábado'
      },
      goalType: {
        salesForecastAccuracy: 'Acurácia da Previsão de Venda',
        originationForecastAccuracy: 'Aderência ao Plano de Originação',
        onTimeInFull: 'On Time In Full'
      },
      capacityUtilizationType: {
        railway: 'Ferrovia',
        waterway: 'Hidrovia',
        stuffing: 'Estufagem',
        shipowner: 'Armador',
        warehouse: 'Armazém'
      },
      target: {
        weekly: 'Semanal',
        monthly: 'Mensal',
        yearly: 'Anual'
      },
      unityCategory: {
        mass: 'Massa',
        time: 'Tempo',
        currency: 'Moeda',
        length: 'Comprimento',
        percentage: 'Porcentagem',
        volume: 'Volume',
        area: 'Área',
        unit: 'Unidade',
        energyConsumption: 'Consumo de energia',
        massPerTime: 'Massa / Tempo',
        currencyPerTime: 'Moeda / Tempo',
        currencyPerMass: 'Moeda / Massa',
        currencyPerUnit: 'Moeda / Unidade',
        timePerTime: 'Tempo / Tempo',
        massPerCurrency: 'Massa / Moeda',
        currencyPointsPerMass: 'Pontos de Moeda / Massa',
        centsPerMass: 'Centavos / Massa'
      },
      ruleType: {
        previousDaysBookingAppointment:
          'Quantidade de dias precedentes à nomeação do booking',
        previousDaysReceiptSalesContract:
          'Quantidade de dias precedentes à expectativa de recebimento do contrato de venda'
      },
      incotermRuleType: {
        cargillBRLogisticsCapacityCostControl:
          'Custo/Controle Logística BR são de Responsabilidade Cargill',
        cargillBRReceivingCapacityCostControl:
          'Custo/Controle de Capacidade de Recebimento/Estufagem são de responsabilidade Cargill',
        producerTotalCostControl:
          'Custos/Controle do envio para o Cliente são de Responsabilidade do Produtor',
        cargillMaritimeCostControl:
          'Apenas o Custo e Controle Marítimo (frete e capacidades de exportação) são de Responsabilidade Cargill',
        cargillMinimumWithdrawalControl:
          'Controle de Retirada Mínima é Responsabilidade Cargill'
      },
      operationType: {
        transfer: 'Transferência',
        sale: 'Venda'
      },
      marketType: {
        bt: 'Ambos',
        em: 'Mercado Externo',
        im: 'Mercado Interno'
      },
      checkType: {
        ok: 'Ok',
        bottom: 'Bottom',
        higher: 'Higher'
      },
      saleType: {
        projection: 'Projeção',
        real: 'Real',
        both: 'Ambos'
      },
      contractType: {
        projection: 'Projeção',
        real: 'Real'
      },
      attendanceType: {
        lot: 'Lote',
        purchaseContract: 'Contrato de Compra'
      },
      company: {
        br: 'BR',
        rg: 'RG'
      },
      spreadReference: {
        minAvg: 'Mín. e Média',
        minMax: 'Mín. e Máx.'
      },
      consolidatedType: {
        ibpVendasAtendidas: 'Vendas Atendidas',
        ibpAtendimentoDemandaMi: 'Atendimento da Demanda',
        ibpAtendimentoDemandaMe: 'Atendimento da Demanda Mercado Externo',
        ibpTotalOriginado: 'Total Originado',
        ibpVolumeOriginado: 'Utilização do Volume Originado',
        ibpAbertoParaVenda: 'Algodão Disponível',
        ibpEstufagem: 'Estufagem',
        ibpArmador: 'Armador',
        ibpArmazem: 'Armazém',
        ibpProjecaoCustoCadeia: 'Projeção do Custo da Cadeia',
        ibpBudget: 'Budget',
        ibpCapacityUtilization: 'Capacidade de Utilização'
      },
      operationTypeOutput: {
        roadFreightCostType: 'Frete Rodoviário',
        railwayFreightCostType: 'Frete Ferroviário',
        waterwayFreightCostType: 'Frete Hidroviário',
        stuffingPortTerminalCostType: 'Terminal de Estufagem Portuário',
        stuffingRailwayTerminalCostType: 'Terminal de Estufagem Ferroviário',
        stuffingWaterwayTerminalCostType: 'Terminal de Estufagem Hidroviário',
        storageCostType: 'Armazém de Interior',
        shipownerCapacityCostType: 'Armador'
      },
      yesOrNo: {
        yes: 'Sim',
        no: 'Não',
        both: 'Ambos'
      },
      bookingTypes: {
        withoutAlocation: 'Sem Amarração',
        partialAlocation: 'Com Amarração Parcial',
        both: 'Ambos'
      },
      weeklyConsolidatedType: {
        demandFullfilmentExternalMarket:
          'Projeção Atendimento de Demanda (Mercado Externo)',
        demandFullfilmentInternalMarket:
          'Projeção Atendimento de Demanda (Mercado Interno)',
        originationProjectionCif: 'Projeção de Originação (CIF)',
        originationProjectionFob: 'Projeção de Originação (FOB)',
        originationProjectionExWorks: 'Projeção de Originação (ExWorks)',
        originationProjectionOthers: 'Projeção de Originação (Outros)',
        bookingsUsedProjection: 'Projeção de Quantidade de Bookings Utilizados',
        bookingsUsedProjectionNoBinding: 'Projeção de Bookings  Sem Amarração',
        openContractsProjection: 'Projeção de Carta de Crédito Alocada',
        openContractsWithoutAllocation:
          'Projeção de Carta de Crédito Sem Alocação',
        allocatedCottonProjection: 'Projeção Volume de Algodão Alocado',
        cottonWithoutAllocation: 'Projeção Volume de Algodão Sem Alocação',
        stuffingCapacityProjection: 'Projeção de Capacidade de Estufagem',
        stuffingCapacityNotUsed:
          'Projeção de Capacidade de Estufagem Não Utilizada'
      },
      penaltyType: {
        shippingPeriod: 'Período de Embarque',
        arrivalCustomerPeriod: 'Período de Chegada no Cliente'
      },
      optimizationOperation: {
        optimization: 'Otimização',
        validation: 'Validação'
      },
      optimizationStatus: {
        optimal: 'Otimizado',
        error: 'Erro',
        creatingInput: 'Criando input',
        queued: 'Na fila',
        pending: 'Em andamento',
        readingSolution: 'Leitura da solução',
        userInterrupted: 'Cancelado pelo usuário',
        requestingOptimizationStop: 'Solicitando cancelamento de otimização',
        finished: 'Finalizado',
        writingResults: 'Escrevendo resultados',
        errorWritingInput: 'Erro na escrita do input',
        errorAutomaticCapacity: 'Erro ao calcular capacidades disponíveis',
        inputCreationQueued: 'Na fila de criação de input',
        waitingForLogs: 'Esperando logs',
        logReading: 'Leitura de logs',
        writingLogs: 'Escrevendo logs'
      },
      alertType: {
        warning: 'Alerta',
        error: 'Erro',
        information: 'Aviso'
      },
      bookingSplitType: {
        allocation: 'Alocação',
        notUsed: 'Saldo'
      },
      portStuffingTerminalFreeTimeCondition: {
        fromContainerRetreat: 'A partir da Retirada',
        fromContainerStuffing: 'A partir da Estufagem'
      },
      baleType: {
        bigBale: '124',
        smallBale: '110'
      }
    },
    parameters: {
      options: {
        WEEK_BEGINNING: 'Início da Semana',
        WEEK_ENDING: 'Fim da Semana',
        MIDDLE_WEEK: 'Meio da Semana',
        MIN_COSTS: 'Minimização de Custos',
        SUPER_HIGH: 'Super Alto',
        HIGH: 'Alto',
        MEDIUM: 'Médio',
        LOW: 'Baixo'
      },
      items: {
        CropYear: 'Safra',
        PurchaseContractProjConsumption:
          'Relevância sobre utilização de contrato de compra firmado',
        PurchaseContractUtilization:
          'Relevância sobre preços de contratos/projeções de compra',
        ICMSLimitDaysWeight: 'Relevância sobre prazos de ICMS na exportação',
        PDCost: 'Relevância sobre Prêmio/Desconto',
        ReleaseDateMaterialWeight:
          'Relevância sobre a utilização de algodão disponível a mais tempo',
        PenaltyByDeadline:
          'Relevância sobre penalidades dos contratos de venda',
        PenaltyByPositionMonth:
          'Relevância sobre Position Month dos Contrato de Venda',
        StuffedMaterialEarly:
          'Relevância sobre utilização de Free Time de Armador',
        MinimumContainerOccupancy: 'Ocupação Mínima Container',
        BarrierAndCrossOver: 'Barrier and Cross Over',
        Gap: 'Nível de Precisão',
        ConsiderMinimizingUseBatchesFulfillingSale:
          'Considerar minimizar utilização de lotes no atendimento de uma venda',
        ConsiderMinimizingPurchaseUsageServicingSale:
          'Considerar minimizar utilização de compra no atendimento de uma venda',
        VIPClientsNotAttendance: 'Atendimento de Cliente VIP',
        RiskSaleContract: 'Atendimento de Venda Risco',
        RealSaleContractNotAttendance:
          'Relevância sobre atendimento de contrato de venda firmado',
        BIReceiptDate: 'Data recebimento BI de contrato de venda',
        ReleaseMaterialDeadlineCalculationType:
          'Referência para chegada em cliente do Mercado Interno',
        DefaultValuesParameters: 'Capacidade de Container (ton)',
        FulfillEMBeforePositionMonthStartDate:
          'Permitir atender vendas antes do Position Month',
        FulfillIMBeforePositionMonthStartDate:
          'Permitir atender vendas antes do Position Month',
        ConsiderCropRestrictionOnService:
          'Considerar Restrição de Safra no Atendimento',
        FlexibilityToExceedMaxApplication:
          'Flexidade para ultrapassar % Max Aplicação (Qualidade e Tipo)',
        DisconsiderTheTypeOfBale:
          'Desconsiderar o tipo de fardo (permitindo misturar)',
        MaximumTimeToUsePurchaseContractOrLot:
          'Tempo máximo (meses) para tentar utilizar um contrato de compra ou lote partindo da fazenda',
        EMAllowedMonthsInAdvanceBeforePositionMonthStartDate:
          'Meses de antecedência permitidos para atendimento antes do Position Month',
        IMAllowedMonthsInAdvanceBeforePositionMonthStartDate:
          'Meses de antecedência permitidos para atendimento antes do Position Month',
        EMMaximumNumberAttemptFulfillSale:
          'Nº Máximo (meses) para tentar atender uma venda (após Position Month)',
        IMMaximumNumberAttemptFulfillSale:
          'Nº Máximo (meses) para tentar atender uma venda (após Position Month)',
        ObjectiveFunction: 'Função Objetivo',

        PrioritizeBatchesICMS:
          'Priorizar lotes cujo ICMS está próximo da data de Vencimento',
        RestrictionDaysCarrierFreeTime:
          'Restrição de Dias após Free Time Armador',
        RestrictionDeadlineCargoBooking:
          'Restrição de Dias Antes do DeadlineCargo Booking',

        ConsiderStorage: 'Considerar Armazenagem',
        StorageTimeMIMonth: 'Tempo de Armazenagem MI (Meses)',
        StorageTimeMEMonth: 'Tempo de Armazenagem ME (Meses)',
        StorageTimeMEMTMonth: 'Tempo de Armazenagem ME  - MT (Meses)',
        EMMinimumLimitContainersSplitSalePartialAllowed:
          'Limite mínimo de Containers para Split de Venda ME Partial Allowed',
        EMMaximumLimitContainersSplitSalePartialAllowed:
          'Limite máximo de Containers para Split de Venda ME Partial Allowed',
        PrioritizeInitialStockUsage: 'Priorizar escoamento de estoque inicial'
      },
      groups: {
        ObjectiveFunction: 'Função Objetivo',
        GeneralParameters: 'Parâmetros Gerais',
        PenaltyWeight: 'Prioridade de Atendimento',
        ExternalMarket: 'Mercado Externo',
        InternalMarket: 'Mercado Interno',
        Storage: 'Armazenagem'
      },
      misc: {
        placeholder: 'Digite a'
      },
      errors: {
        notInteger: 'O valor do campo {field} não é um inteiro',
        penaltyWeight: {
          outOfRange: 'O peso do campo {field} está fora do range 1 a 1000',
          outOfRangeFlexibility:
            'O peso do campo {field} está fora do range maior ou igual a 5',
          duplicateWeights: 'Os pesos dos campos {fields} são iguais'
        }
      }
    }
  }
}
