export default {
  application: {
    enums: {
      notificationModule: {
        appService: 'SCO',
        controlTower: 'Torre de Controle',
        integrationService: 'Integração'
      },
      notificationType: {
        error: 'Erro',
        information: 'Informação',
        warning: 'Alerta'
      }
    },
    pages: {
      notificationUser: {
        title: 'Notificações'
      },
      notification: {
        title: 'Notificações',
        markAllAsRead: 'Marcar todas as notificações como lidas',
        markAllAsReadMessage:
          'Tem certeza que deseja marcar todas notificações não lidas como lidas?'
      }
    },
    misc: {
      allNotifications: 'Ver todas notificações',
      emptyNotifications: 'Você não tem nenhuma notificação',
      notifications: 'Notificações',
      notifiedOn: 'Notificado em',
      module: 'Módulo'
    },
    actions: {
      markAsRead: 'Marcar como lida'
    }
  }
}
