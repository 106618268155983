import { createCrudRoutes } from '@brain/core'
import ControlTower from '@cargill/controltower'
import workflow from '@supply/workflow'
import {
  integrationRoutes,
  importLogRoutes,
  notificationRoutes
} from '@cargill/shared'

export default [
  {
    path: '/',
    public: true,
    component: () =>
      import(/* webpackChunkName: "main" */ '../layouts/Main.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () =>
          import(/* webpackChunkName: "home" */ '../views/Home.vue')
      },
      {
        path: '/optimization',
        name: 'Optimization',
        title: 'application.pages.optimization.title',
        public: true,
        component: () => import('../views/Optimization')
      },
      {
        path: '/ongoingTasks',
        name: 'OngoingTasks',
        title: 'application.pages.optimizationQueueEntity.title',
        public: true,
        component: () => import('../views/OngoingTasks')
      },
      ...createCrudRoutes('scenarioCargill', () =>
        import('@/views/ScenarioCargill')
      ),
      ...createCrudRoutes('material', () => import('@/views/Material')),
      ...createCrudRoutes('unity', () => import('@/views/Unity')),
      ...createCrudRoutes('unityConversion', () =>
        import('@/views/UnityConversion')
      ),

      ...createCrudRoutes('center', () => import('@/views/Center')),
      ...createCrudRoutes('modal', () => import('@/views/Modal')),
      ...createCrudRoutes('workingHours', () => import('@/views/WorkingHours')),

      ...createCrudRoutes(
        'centerInformation',
        () => import('@/views/CenterInformation'),
        ['centerAvailableCapacity']
      ),
      ...createCrudRoutes('workException', () =>
        import('@/views/WorkException')
      ),
      ...createCrudRoutes(
        'customerCargill',
        () => import('@/views/CustomerCargill'),
        ['customerCargillReceivingCapacity', 'customerCargillAvailableCapacity']
      ),
      ...createCrudRoutes('centerCost', () => import('@/views/CenterCost')),
      ...createCrudRoutes('stretch', () => import('@/views/Stretch')),
      ...createCrudRoutes('modalInformation', () =>
        import('@/views/ModalInformation')
      ),
      ...createCrudRoutes(
        'portStuffingTerminal',
        () => import('@/views/PortStuffingTerminal'),
        [
          'portStuffingTerminalInformation',
          'portStuffingTerminalCost',
          'portStuffingTerminalAdditionalInformation',
          'portStuffingTerminalAvailableCapacity',
          'portStuffingTerminalVolumeTakeOrPay',
          'portStuffingTerminalTakeOrPayCost',
          'portStuffingTerminalFreeTimeLogisticPortal'
        ]
      ),
      ...createCrudRoutes('movementItas', () =>
        import('../views/MovementItas')
      ),
      ...createCrudRoutes('carrier', () => import('@/views/Carrier'), [
        'carrierCostAndTransitTimeInformation',
        'carrierCapacityInformation',
        'carrierFreeTimeInformation',
        'carrierTerminalRestriction'
      ]),
      ...createCrudRoutes('impurityGreatness', () =>
        import('@/views/ImpurityGreatness')
      ),
      ...createCrudRoutes('optimizationConstraintMinimumWithdrawal', () =>
        import('@/views/OptimizationConstraintMinimumWithdrawal')
      ),
      ...createCrudRoutes('qualityGreatness', () =>
        import('@/views/QualityGreatness')
      ),
      ...createCrudRoutes(
        'purchaseContract',
        () => import('@/views/PurchaseContract'),
        ['purchaseContractQualitySpecification']
      ),
      ...createCrudRoutes('saleContract', () => import('@/views/SaleContract')),
      ...createCrudRoutes(
        'saleContractPremiumAndDiscount',
        () => import('../views/SaleContractPremiumAndDiscount'),
        [
          'saleContractPremiumAndDiscountUSBucketAplication',
          'saleContractPremiumAndDiscountTypeAplication',
          'saleContractPremiumAndDiscountQualityAplication',
          'saleContractPremiumAndDiscountImpurityAplication'
        ]
      ),
      ...createCrudRoutes('usBucket', () => import('@/views/UsBucket'), [
        'usBucketPD',
        'usBucketCountry'
      ]),
      ...createCrudRoutes('flowProhibition', () =>
        import('@/views/FlowProhibition')
      ),
      ...createCrudRoutes('limitedDaysWithoutCharge', () =>
        import('@/views/LimitedDaysWithoutCharge')
      ),
      ...createCrudRoutes('demandFulfillmentProhibition', () =>
        import('@/views/DemandFulfillmentProhibition')
      ),
      ...createCrudRoutes('inventory', () => import('@/views/Inventory'), [
        'inventoryQualitySpecification',
        'inventoryImpuritySpecification'
      ]),
      ...createCrudRoutes(
        'configurationArrivalWindow',
        () => import('@/views/ConfigurationArrivalWindow'),
        []
      ),
      ...createCrudRoutes('inventoryQualitySpecification', () =>
        import('@/views/InventoryQualitySpecification')
      ),
      ...createCrudRoutes('inventoryImpuritySpecification', () =>
        import('@/views/InventoryImpuritySpecification')
      ),
      ...createCrudRoutes('incoterm', () => import('@/views/Incoterm'), [
        'incotermRuleIncoterm'
      ]),
      ...createCrudRoutes('supplyChainBudget', () =>
        import('@/views/SupplyChainBudget')
      ),
      ...createCrudRoutes('weeklyOpenContractsProjectionDashboard', () =>
        import('@/views/WeeklyOpenContractsProjectionDashboard')
      ),
      ...createCrudRoutes('weeklyBookingProjectionDashboard', () =>
        import('@/views/WeeklyBookingProjectionDashboard')
      ),
      ...createCrudRoutes('weeklyPurchaseProjectionDashboard', () =>
        import('@/views/WeeklyPurchaseProjectionDashboard')
      ),
      ...createCrudRoutes('weeklyDemandFullfilmentDashboard', () =>
        import('@/views/WeeklyDemandFullfilmentDashboard')
      ),
      ...createCrudRoutes('weeklyCapacityUtilizationDashboard', () =>
        import('@/views/WeeklyCapacityUtilizationDashboard')
      ),
      ...createCrudRoutes('weeklyIndicatorsDashboard', () =>
        import('@/views/WeeklyIndicatorsDashboard')
      ),
      ...createCrudRoutes(
        'leadtimebyCountry',
        () => import('@/views/LeadtimebyCountry'),
        ['leadtimeCapacityContainer']
      ),
      ...createCrudRoutes('fullfilledContractsDashboard', () =>
        import('@/views/FullfilledContractsDashboard')
      ),
      ...createCrudRoutes('port', () => import('@/views/Port')),
      ...createCrudRoutes('booking', () => import('@/views/Booking')),
      ...createCrudRoutes(
        'storageTerminal',
        () => import('@/views/StorageTerminal'),
        [
          'storageTerminalInformation',
          'storageTerminalCost',
          'storageTerminalVolumeTakeOrPay',
          'storageTerminalTakeOrPayCost'
        ]
      ),
      ...ControlTower.routes,
      ...workflow.routes,
      ...integrationRoutes,
      ...importLogRoutes,
      ...notificationRoutes,
      {
        path: '/tabular-reports-cost-projection',
        name: 'weeklyTabularReportsCostProjection',
        title: 'application.pages.weeklyTabularReportsCostProjection.title',
        component: () =>
          import('@/views/reports/WeeklyTabularReportsCostProjection')
      },
      ...createCrudRoutes('monthlyDemandAttendanceReport', () =>
        import('@/views/reports/MonthlyDemandAttendanceReport')
      ),
      ...createCrudRoutes('weeklyDemandAttendanceReport', () =>
        import('@/views/reports/WeeklyDemandAttendanceReport')
      ),
      ...createCrudRoutes('weeklyDemandAttendanceResumeReport', () =>
        import('@/views/reports/WeeklyDemandAttendanceResumeReport')
      ),
      ...createCrudRoutes('weeklyPlanStuffingReport', () =>
        import('@/views/reports/WeeklyPlanStuffingReport')
      ),
      ...createCrudRoutes('weeklySummaryPlanAllocationReport', () =>
        import('@/views/reports/WeeklySummaryPlanAllocationReport')
      ),
      ...createCrudRoutes(
        'departureTerminal',
        () => import('../views/DepartureTerminal'),
        ['departureTerminalStuffingTerminal', 'departureTerminalFreeTime']
      )
    ]
  }
]
