import { createPinia, PiniaVuePlugin } from 'pinia'
import Vue from 'vue'
import PersistedState from 'pinia-plugin-persistedstate'
import VueCompositionApi from '@vue/composition-api'

Vue.use(VueCompositionApi)
Vue.use(PiniaVuePlugin)

const pinia = createPinia().use(PersistedState)

export { pinia }
