<template>
  <div class="bc-crud-filter-set">
    <bc-text-field
      v-model="search"
      type="text"
      embedded
      hide-details="auto"
      :placeholder="$t('crud.filter.searchPlaceholder')"
    ></bc-text-field>
    <div class="bc-crud-filter-set__header">
      <bc-checkbox
        dense
        :disabled="loading"
        hide-details="auto"
        :label="$t(selectAllLabel)"
        :input-value="selectAll === true"
        :indeterminate="selectAll === null"
        @change="onToggleSelectAll"
      />
      <bc-checkbox
        dense
        v-model="addCurrentSelectionToFilter"
        :disabled="loading"
        hide-details="auto"
        :label="$t('crud.filter.addCurrentSelectionToFilter')"
      />
    </div>
    <bc-divider horizontal />

    <div class="v-text-field__details">
      <div class="v-messages theme--dark primary--text">
        <div class="v-messages__wrapper"></div>
      </div>
      <div class="v-counter theme--dark">
        {{ counterString }}
      </div>
    </div>
    <div
      :id="`cargill-filter-${id}`"
      class="bc-crud-filter-set__item-list"
      :style="{ height: listHeight }"
      :aria-rowcount="items.length"
    >
      <template v-if="loading">
        <div class="bc-crud-filter-set__loading-container">
          <bc-loading :blockUi="false" size="md" class="white--text" />
        </div>
      </template>
      <template v-else>
        <bc-virtual-scroll
          :bench="10"
          :items="filteredItems"
          :key="virtualScrollKey"
          item-height="35"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item">
              <v-list-item-content>
                <bc-checkbox
                  dense
                  hide-details="auto"
                  v-model="selectedItemsDict[item]"
                  @change="updateInputCount($event)"
                  :label="item"
                />
              </v-list-item-content>
            </v-list-item>
            <bc-divider></bc-divider>
          </template>
        </bc-virtual-scroll>
      </template>
    </div>
    <div class="bc-crud-filter-set__footer">
      <bc-btn color="link-blue" @click.prevent="onResetFilter">
        {{ $t('crud.filter.resetFilter') }}
      </bc-btn>
      <bc-btn
        color="primary"
        :disabled="shouldDisableFilter"
        @click.prevent="onApplyFilter"
      >
        {{ $t('crud.filter.applyFilter') }}
      </bc-btn>
    </div>
  </div>
</template>

<script>
import { helpers } from '@brain/grid'
import {
  BcBtn,
  BcCheckbox,
  BcDivider,
  BcLoading,
  BcVirtualScroll,
  BcTextField
} from '@brain/ui'
import { VListItem, VListItemContent } from 'vuetify/lib'
import Vue from 'vue'
import { eventHub } from '../../bus/eventhub'
import { useGridSettings } from '../../stores/gridSettings'

var filterId = 0

export default Vue.extend({
  name: 'CgCrudFilterSetPd',
  components: {
    BcBtn,
    BcCheckbox,
    BcDivider,
    BcLoading,
    BcVirtualScroll,
    VListItem,
    VListItemContent,
    BcTextField
  },
  data() {
    return {
      startValue: false,
      searchInput: null,
      field: '',
      filterText: '',
      items: [],
      loading: false,
      addCurrentSelectionToFilter: false,
      filteredItems: [],
      search: '',
      firstLoad: true,
      rawItemByText: {},
      selectedItemsCount: 0,
      filteredSelectedItemsCount: 0,
      selectedItemsDict: {},
      selectedItemsApplied: [],
      model: {},
      checkFilterModel: null,
      id: filterId++,
      debouncedQuerySelections: null,
      maxFilterQuery: 300,
      virtualScrollKey: 0,
      lastMasterId: null
    }
  },
  mounted() {
    this.debouncedQuerySelections = this.debounce(this.querySelections, 500)
    this.field = this.params.colDef.field
    this.model = {
      ...this.params.api.getFilterModel(),
      [this.field]: undefined
    }

    this._loadItems()
    this.checkFilterModel = this._throttle(this._checkFilterModel, 1000)
    this.reloadItemsFunc = () => this._loadItems()
    if (this.params.colDef.masterName) {
      this.reloadItemsFuncOnMasterChange = () => {
        if (this.isSameMaster()) {
          this._loadItems()
        }
      }
      eventHub.$on('update-grid-master', this.reloadItemsFuncOnMasterChange)
    }
    eventHub.$on('reload-crud-filters', this.reloadItemsFunc)
  },
  beforeDestroy() {
    if (this.params.colDef.masterName) {
      eventHub.$off('update-grid-master', this.reloadItemsFuncOnMasterChange)
    }
    eventHub.$off('reload-crud-filters', this.reloadItemsFunc)
  },
  computed: {
    counterString() {
      return `${this.selectedItemsCount} / ${this.items.length}`
    },
    selectAllLabel() {
      return this.hasSearch
        ? 'crud.filter.selectAllSearchResults'
        : 'crud.filter.selectAll'
    },
    hasSearch() {
      return this.search != null && this.search.length > 0
    },
    valueFormatter() {
      const formatter =
        this.params.valueFormatter || this._defaultValueFormatter
      return (value) => formatter({ value })
    },
    selectAll() {
      if (this.filteredSelectedItemsCount === this.filteredItems.length) {
        return true
      } else if (this.filteredSelectedItemsCount === 0) {
        return false
      } else {
        return null
      }
    },
    listHeight() {
      const gridHeight = this.params.gridHeight ?? 290
      const listHeight = (gridHeight * 25) / 100
      return `${listHeight}px`
    },
    shouldDisableFilter() {
      return this.selectedItemsCount.length === 0
    }
  },
  watch: {
    search(val) {
      this.debouncedQuerySelections(val)
    }
  },
  methods: {
    debounce(fn, ms = 0) {
      let timeoutId
      return function (...args) {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => fn.apply(this, args), ms)
      }
    },
    getMasterConfig() {
      const { getMasterConfig } = useGridSettings()
      const masterConfig = getMasterConfig()
      return masterConfig
    },
    isSameMaster() {
      return this.params.colDef.masterName == this.getMasterConfig().masterName
    },
    updateInputCount(value) {
      if (value) {
        this.filteredSelectedItemsCount++
        this.selectedItemsCount++
      } else {
        this.filteredSelectedItemsCount--
        this.selectedItemsCount--
      }
    },
    querySelections(queryText) {
      this.loading = true
      queryText ??= ''
      queryText = queryText.toLocaleLowerCase()

      const filteredItems = this.items.filter((x) =>
        this.filterDefaultQueryLower(x, queryText, x)
      )
      const filteredSelectedItemsCount = this.countSelected(filteredItems)

      this.filteredItems = filteredItems
      this.filteredSelectedItemsCount = filteredSelectedItemsCount
      this.loading = false
    },
    countSelected(items) {
      let selectedItemsCount = 0
      for (const item of items) {
        if (this.selectedItemsDict[item]) {
          selectedItemsCount++
        }
      }
      return selectedItemsCount
    },
    filterDefault(item, queryText, itemText) {
      return this.filterDefaultQueryLower(
        item,
        queryText.toLocaleLowerCase(),
        itemText
      )
    },
    filterDefaultQueryLower(item, queryText, itemText) {
      return itemText.toLocaleLowerCase().indexOf(queryText) > -1
    },
    onApplyFilter() {
      const selectedItemsApplied = this.items.filter(
        (x) => this.selectedItemsDict[x]
      )
      // if (
      //   selectedItemsApplied.length > this.maxFilterQuery &&
      //   selectedItemsApplied.length < this.items.length
      // ) {
      //   this.notify.error({
      //     title: this.$t('crud.filter.maxFilterQuery', {
      //       maxFilterQuery: this.maxFilterQuery
      //     })
      //   })
      // } else {

      // }
      this.selectedItemsApplied = selectedItemsApplied
      this.params.filterChangedCallback()
    },
    onResetFilter() {
      this.loading = true
      Object.keys(this.selectedItemsDict).forEach(
        (x) => (this.selectedItemsDict[x] = this.startValue)
      )
      if (this.startValue) {
        this.selectedItemsCount = this.items.length
        this.filteredSelectedItemsCount = this.filteredItems.length
        this.selectedItemsApplied = [...this.items]
      } else {
        this.selectedItemsCount = 0
        this.filteredSelectedItemsCount = 0
        this.selectedItemsApplied = []
      }
      this.reloadVirtualScroll()
      this.params.filterChangedCallback()
      this.loading = false
    },
    deselectItemsWithoutCount(items) {
      this.updateAllItens(items, false)
    },
    selectItemsWithoutCount(items) {
      this.updateAllItens(items, true)
    },
    updateAllItens(items, newValue) {
      items.forEach((x) => (this.selectedItemsDict[x] = newValue))
    },
    selectItems(items) {
      let selectedItemsCount = 0
      for (const item of items) {
        if (!this.selectedItemsDict[item]) {
          this.selectedItemsDict[item] = true
          selectedItemsCount++
        }
      }
      this.selectedItemsCount += selectedItemsCount
      return selectedItemsCount
    },
    deselectAllItens() {
      this.deselectItemsWithoutCount(Object.keys(this.selectedItemsDict))
      this.selectedItemsCount = 0
      this.filteredSelectedItemsCount = 0
    },
    onToggleSelectAll() {
      this.loading = true
      if (this.selectAll) {
        if (this.addCurrentSelectionToFilter) {
          this.deselectItemsWithoutCount(this.filteredItems)
          this.selectedItemsCount -= this.filteredItems.length
        } else {
          this.deselectAllItens()
        }
        this.filteredSelectedItemsCount = 0
      } else {
        if (!this.addCurrentSelectionToFilter) {
          this.deselectAllItens()
        }
        this.selectItems(this.filteredItems)
        this.filteredSelectedItemsCount = this.filteredItems.length
      }
      this.reloadVirtualScroll()
      this.loading = false
    },
    reloadVirtualScroll() {
      this.virtualScrollKey++
    },
    // This filter should not be used in client side filtering.
    doesFilterPass() {
      return true
    },
    isFilterApplied() {
      if (
        this.selectedItemsApplied == null ||
        this.selectedItemsApplied.length == 0
      ) {
        return false
      }
      if (this.selectedItemsApplied.length < this.items.length) {
        return true
      }
      const selectedItemsAppliedSet = new Set(this.selectedItemsApplied)
      return this.items.some((x) => !selectedItemsAppliedSet.has(x))
    },
    isFilterActive() {
      return this.isFilterApplied()
    },
    getModel() {
      let model
      if (!this.isFilterApplied()) {
        model = null
      } else {
        const selectedItems = this.selectedItemsApplied.filter(
          (item) => this.rawItemByText[item] != null
        )
        model = {
          values: selectedItems.map((item) =>
            this._formatFilterValue(this.rawItemByText[item].rawValue)
          ),
          filterType: 'set'
        }
        if (model.values.length == this.items.length) {
          model = null
        }
      }
      return model
    },
    async setModel(model) {
      if (this.firstLoad) {
        await this._loadItems()
      }
      this.loading = true

      this.deselectAllItens()
      if (!model) {
        if (this.startValue) {
          this.selectItemsWithoutCount(this.items)
          this.selectedItemsCount = this.items.length
          this.filteredSelectedItemsCount = this.filteredItems.length
          this.selectedItemsApplied = [...this.items]
        } else {
          this.selectedItemsApplied = []
        }
      } else {
        const valuesSet = new Set(model.values)
        const selectedItems = this.items.filter((item) => {
          const value = this._formatFilterValue(
            this.rawItemByText[item]?.rawValue
          )
          return valuesSet.has(value)
        })
        const selectedItemsSet = new Set(selectedItems)
        this.selectItemsWithoutCount(selectedItems)
        this.filteredSelectedItemsCount = this.filteredItems.filter((x) =>
          selectedItemsSet.has(x)
        ).length
        this.selectedItemsCount = selectedItems.length
        this.selectedItemsApplied = selectedItems
      }
      this.reloadVirtualScroll()
      this.loading = false
    },
    onNewRowsLoaded() {
      this.checkFilterModel()
    },
    resetFilterData() {
      this.filterText = ''
      this.items = []
      this.loading = false
      this.filteredItems = []
      this.search = ''
      this.firstLoad = true
      this.rawItemByText = {}
      this.selectedItemsCount = 0
      this.filteredSelectedItemsCount = 0
      this.selectedItemsDict = {}
      this.selectedItemsApplied = []
      this.model = {
        ...this.params.api.getFilterModel(),
        [this.field]: undefined
      }
    },
    async _values() {
      const filterParams = { objectFields: this.params.objectFields }
      if (this.isSameMaster()) {
        const masterConfig = this.getMasterConfig()
        if (this.lastMasterId != masterConfig.masterId) {
          this.resetFilterData()
        }
        filterParams.masterName = masterConfig.masterName
        filterParams.masterId = masterConfig.masterId
        this.lastMasterId = filterParams.masterId
      }
      const queryParamsObject = helpers.buildQueryObject(
        {
          filterModel: this.model
        },
        filterParams
      )
      const filterQuery = helpers.buildQueryParams(queryParamsObject)
      const options = this.params.values
      let values = []

      if (typeof options === 'function') {
        this.loading = true
        values = await options({ filterQuery })
        this.loading = false
      } else if (Array.isArray(options)) {
        values = options
      }

      values = values.map((item) => ({
        rawValue: item,
        formattedValue: this.valueFormatter(item)
      }))

      return values
    },
    updateRawItemByText(items) {
      items.forEach((item) => (this.rawItemByText[item.formattedValue] = item))
    },
    async _loadItems() {
      this.loading = true
      const items = await this._values()
      this.updateRawItemByText(items)
      this.items = items.map((x) => x.formattedValue)

      if (this.firstLoad) {
        if (this.startValue) {
          this.selectItemsWithoutCount(this.items)
          this.selectedItemsCount = this.items.length
        } else {
          this.deselectItemsWithoutCount(this.items)
          this.selectedItemsCount = 0
        }
        this.firstLoad = false
      } else {
        this.selectedItemsCount = this.countSelected(this.items)
      }

      if (this.hasSearch) {
        this.querySelections(this.search)
      } else {
        this.filteredItems = this.items
        this.filteredSelectedItemsCount = this.selectedItemsCount
      }
      this.loading = false
    },
    _defaultValueFormatter(params) {
      if (params.value == null) return ''
      return (
        typeof params.value === 'object' ? params.value.text : params.value
      )?.toString()
    },
    _formatFilterValue(item) {
      if (item == null) return 'null'
      return typeof item === 'object' ? item.value : item
    },
    _checkFilterModel() {
      const oldModel = this.model
      const newModel = {
        ...this.params.api.getFilterModel(),
        [this.field]: undefined
      }
      this.model = newModel

      // If the models do not match, reloade the items
      if (JSON.stringify(oldModel) !== JSON.stringify(newModel)) {
        this._loadItems()
      }
    },
    _throttle(fn, wait) {
      let inThrottle, lastFn, lastTime
      return function () {
        const context = this,
          args = arguments
        if (!inThrottle) {
          fn.apply(context, args)
          lastTime = Date.now()
          inThrottle = true
        } else {
          clearTimeout(lastFn)
          lastFn = setTimeout(function () {
            if (Date.now() - lastTime >= wait) {
              fn.apply(context, args)
              lastTime = Date.now()
            }
          }, Math.max(wait - (Date.now() - lastTime), 0))
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.bc-crud-filter-set {
  &__header {
    margin-bottom: 10px;
  }

  & .v-text-field__details {
    padding: 10px 0 5px 0;
  }

  &__item-list {
    overflow-y: hidden;
    margin-bottom: 10px;
    //min-height: 300px;
    max-height: 500px;

    & hr {
      display: none;
    }

    & .v-list-item {
      min-height: 30px;
      padding: 0;

      & .v-list-item__content {
        padding: 0;

        & .v-input {
          margin: 0;
          padding: 0;
          padding: 0 10px 0px 0;
          white-space: nowrap;
        }
      }
    }
  }

  &__loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

::v-deep .v-input__control .v-input__slot .v-label {
  overflow: hidden;
}

::v-deep .theme--dark.v-counter {
  color: #959db5 !important;
}
</style>
