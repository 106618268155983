<template>
  <div class="content">
    {{ value }}
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'DateFormat',
  components: {},
  data() {
    return {}
  },
  methods: {},
  computed: {
    value: function () {
      return this.params.value != null
        ? moment(new Date(this.params.value)).format(this.dateFormat)
        : ''
    },
    dateFormat() {
      return this.$t('application.dateFormat')
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  justify-content: center;
}
</style>
