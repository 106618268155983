<template>
  <div v-if="parameterData.possibleValues !== null">
    <bc-row>
      <span
        class="text-uppercase ml-3 mt-2 mb-1 bc-font-size--10 group-text-color"
        >{{ getGroupLabel() }}</span
      >
    </bc-row>
    <bc-radio-group v-model="value" row>
      <bc-radio
        v-for="possibleValue in parameterData.possibleValues"
        :key="possibleValue"
        :label="getLabel(possibleValue)"
        :value="possibleValue"
      ></bc-radio>
    </bc-radio-group>
  </div>
</template>

<script>
import { BcRadioGroup, BcRadio, BcRow } from '@brain/ui'

export default {
  name: 'ScenarioParameterRadio',
  components: { BcRadioGroup, BcRadio, BcRow },
  data() {
    return {
      value: null
    }
  },
  props: {
    parameterData: {
      type: Object,
      require: true
    }
  },
  created() {
    this.value = this.parameterData.value
  },
  methods: {
    getGroupLabel() {
      return this.$t(`application.parameters.items.${this.parameterData.code}`)
    },
    getLabel(possibleValue) {
      return this.$t(`application.parameters.options.${possibleValue}`)
    },
    getData() {
      return this.value
    }
  }
}
</script>

<style lang="scss" scoped>
.group-text-color {
  color: var(--bc-toast-color);
}
</style>
