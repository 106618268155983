import { defineStore } from 'pinia'
import { eventHub } from '../bus/eventhub'
import { userSettingsService } from '../api'
import { i18n } from '../brainCore'

export const useUserSettings = defineStore('userSettings', {
  state: () => ({
    userSettings: {}
  }),

  getters: {
    getLanguage: (state) => () => {
      return state.userSettings?.language
    },
    isScenarioResource: (state) => (resource) => {
      return !!state.userSettings?.scenarioResources?.includes(resource)
    },
    getResourceMasterDataSuffix: (state) => () => {
      return state.userSettings?.resourceMasterDataSuffix ?? 'Master'
    },
    getUserSettings: (state) => () => {
      return state.userSettings
    }
  },

  actions: {
    updateUser(userSettings) {
      this.userSettings = userSettings
    },
    async updateLanguage(language) {
      await userSettingsService.update({
        ...this.userSettings,
        language: language
      })
      eventHub.$emit('update-user-language', language)
      i18n.setLanguage(language)
      this.userSettings.language = language
    }
  },
  persist: true
})
