import {
  api,
  createCrudService,
  useModuleOpen,
  handleError
} from '@cargill/shared'

const createService = () => {
  const { getApiRef } = useModuleOpen()
  const basePath = `/api/${getApiRef()}notificationApi`

  const service = createCrudService(basePath, api)
  service.getUnreadNotifications = async (size) => {
    try {
      size ??= 3
      const response = await api.get(
        `${basePath}/getUnreadNotifications/${size}`
      )
      return response.data
    } catch (error) {
      handleError(error)
      throw error
    }
  }
  service.markAsRead = async (ids) => {
    try {
      const response = await api.put(`${basePath}/markAsRead`, ids)
      return response.data
    } catch (error) {
      handleError(error)
      throw error
    }
  }
  service.markAllAsRead = async () => {
    try {
      const response = await api.put(`${basePath}/markAllAsRead`)
      return response.data
    } catch (error) {
      handleError(error)
      throw error
    }
  }
  service.generateFakeNotifications = async (config) => {
    config = config ?? {}
    const _config = {
      notificationSize: 100,
      daysToDelete: 90,
      percentToDelete: 100,
      percentUnread: 0,
      percentNotDeleteRead: 0,
      ...config
    }
    try {
      const response = await api.post(
        `${basePath}/generateFakeNotifications`,
        _config
      )
      return response.data
    } catch (error) {
      handleError(error)
      throw error
    }
  }
  return service
}

export default createService
