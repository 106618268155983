<template>
  <div>
    <cg-crud-grid
      inline
      ref="gridComponent"
      v-bind="$attrs"
      v-on="$listeners"
      :name="name"
      :columns="columns"
      :gridMode="gridMode"
      :gridData="gridData"
      :gridHeight="gridHeight"
      :actions="actions"
      :selectableRow="selectableRow"
      :service="service"
      :validationSchema="validationSchema"
      :translate="translate"
      :customSetSize="customGridSetSize"
      :gridOptions="gridOptions"
      :keepFilters="keepFilters"
      :customToolbarActions="customToolbarActions"
      :deleteFiltered="deleteFiltered"
      @select="onMasterGridSelect($event)"
      @dataReload="onMasterGridDataReload()"
      @dataLoaded="onDataLoaded($event)"
    >
    </cg-crud-grid>

    <div v-if="showTabs">
      <bc-tabs v-model="tab" align-with-title>
        <bc-tab v-for="detail in detailTables" :key="detail.name">
          {{ detail.label }}
        </bc-tab>
      </bc-tabs>

      <bc-tabs-items v-model="tab">
        <bc-tab-item v-for="detail in detailTables" :key="detail.name">
          <cg-crud-grid
            inline
            ref="detailGridComponent"
            v-bind="$attrs"
            v-on="$listeners"
            :name="detail.name"
            :validationSchema="detail.validationSchema"
            :masterName="detail.masterName"
            :masterNameFilter="name"
            :columns="detail.columns"
            :gridMode="detail.gridMode"
            :gridData="detail.gridData"
            :gridHeight="detail.gridHeight"
            :actions="detail.actions"
            :selectableRow="detail.selectableRow"
            :service="detail.service"
            :translate="(key) => translate(`${name}.${key}`)"
            :masterId="masterId"
            :customSetSize="customGridSetSize"
            :gridOptions="gridOptions"
            :keepFilters="keepFilters"
            :customToolbarActions="detail.customToolbarActions"
            :deleteFiltered="deleteFiltered"
          >
          </cg-crud-grid>
        </bc-tab-item>
      </bc-tabs-items>
    </div>
  </div>
</template>

<script>
import CgCrudGrid from './CgCrudGrid.vue'
import { BcCrudList } from '@brain/crud'
import { BcTabItem, BcTabsItems } from '@brain/ui'
import { useGridSettings, useCrudFilters } from '../../stores'

export default {
  name: 'CgCrudList',
  components: {
    CgCrudGrid,
    BcTabItem,
    BcTabsItems
  },
  extends: BcCrudList,
  props: {
    customToolbarActions: { type: Array, default: () => [] },
    deleteFiltered: { type: Boolean, default: true }
  },
  data() {
    return {
      masterId: null,
      tab: null
    }
  },
  methods: {
    onMasterGridDataReload() {
      this.masterId = null
    },
    async onDataLoaded(data) {
      this.reloadMaster(data)
    },
    async reloadMaster(data) {
      const { getMasterId } = useCrudFilters()
      const lastMasterId = getMasterId(this.name)
      if (lastMasterId != null) {
        // Reload on every page
        // const masterRow = await this.service.getById(lastMasterId)
        // if (masterRow?.id != null) {
        //   this.masterId = lastMasterId
        // }

        // Reload on same page
        if (data.find((x) => x.id == lastMasterId) != null) {
          this.masterId = lastMasterId
          this.updateMasterConfig()
        }
      }
    },
    getMasterName() {
      return this.hasDetails() ? this.detailTables[0].masterName : this.name
    },
    getMasterConfig() {
      return {
        masterName: this.getMasterName(),
        masterId: this.masterId
      }
    },
    hasDetails() {
      return this.detailTables && this.detailTables.length > 0
    },
    updateMasterConfig() {
      const { updateMaster } = useGridSettings()
      const masterConfig = this.getMasterConfig()
      updateMaster(masterConfig)
    },
    onMasterGridSelect(event) {
      this.masterId = event.masterId
      const { updateMasterId } = useCrudFilters()
      updateMasterId(this.name, this.masterId)
      this.updateMasterConfig()
      this.$emit('lineSelected', event)
    }
  }
}
</script>
