import { defineStore } from 'pinia'
import { eventHub } from '../bus/eventhub'

export const useGridSettings = defineStore('gridSettings', {
  state: () => ({
    gridSettings: {}
  }),
  getters: {
    getMasterConfig: (state) => () => {
      return { ...state.gridSettings?.masterConfig }
    }
  },
  actions: {
    updateMaster(masterConfig) {
      this.gridSettings.masterConfig = masterConfig
      eventHub.$emit('update-grid-master', this.getMasterConfig())
    }
  },
  persist: true
})
