import { api, createCrudService, useModuleOpen } from '@cargill/shared'

const createService = () => {
  const { getApiRef } = useModuleOpen()
  const basePath = `/api/${getApiRef()}integrationLog`

  const service = createCrudService(basePath, api)

  return service
}

export default createService
