<script>
import { BcGrid, helpers } from '@brain/grid'

export default {
  name: 'CgGrid',
  extends: BcGrid,
  methods: {
    loadData(filterParameters = {}, pageOnReload = 0) {
      if (this.mode === 'serverSide') {
        this.loadServerSide(filterParameters, pageOnReload)
      } else if (this.mode === 'clientSide') {
        this.loadClientSide(filterParameters)
      }
      this.setSize()
      this.$emit('select', [])
    },
    loadServerSide(filterParameters = {}, pageOnReload = 0) {
      const datasource = this.createServerSideDataSource(
        this.getItems,
        this.countItems,
        filterParameters,
        pageOnReload
      )
      this.aggridApi.setServerSideDatasource(datasource)
    },
    createServerSideDataSource(
      getItems,
      countItems,
      serviceParams,
      pageOnReload = 0
    ) {
      return {
        getRows: async (agGridParams) => {
          this.aggridApi.showLoadingOverlay()
          this.isEmpty = true
          try {
            const queryParamsObject = helpers.buildQueryObject(
              agGridParams.request,
              serviceParams
            )
            const queryStringParams =
              helpers.buildQueryParams(queryParamsObject)
            const count = await countItems(
              helpers.buildQueryParams({
                filterQuery: queryParamsObject.filterQuery
              })
            )

            if (this.isFirstRequest) {
              this.isFirstRequest = false
              if (
                this.startPage > 0 &&
                this.pageSize * this.startPage < count
              ) {
                agGridParams.successCallback(
                  new Array(this.pageSize).fill({}),
                  count
                )
                this.aggridApi.hideOverlay()
                this.isEmpty = false
                this.setSize()
                this.aggridApi.paginationGoToPage(this.startPage)
                return
              }
            }

            const data = await getItems(queryStringParams)

            if (Array.isArray(data) && data.length > 0) {
              agGridParams.successCallback(data, count)

              if (pageOnReload > 0 && this.pageSize * pageOnReload < count) {
                this.aggridApi.paginationGoToPage(pageOnReload)
              } else {
                this.$emit('dataLoaded', data)
              }
              pageOnReload = 0

              this.aggridApi.hideOverlay()
              this.isEmpty = false
              this.setSize()
            } else {
              this.aggridApi.showNoRowsOverlay()
            }
          } catch (e) {
            console.error(e)
            this.aggridApi.showNoRowsOverlay()
          }
        }
      }
    }
  }
}
</script>
