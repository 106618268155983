import { moduleCargillEnum } from './moduleCargillEnum'

export const getModules = () => [
  {
    key: 1,
    icon: 'fa-users',
    name: 'Colaboration & Consensus',
    module: '',
    disabled: true
  },
  {
    key: 2,
    icon: 'fa-broadcast-tower',
    name: 'controlTower.pages.controlTower.title',
    module: moduleCargillEnum.controlTower,
    href: 'controltower',
    disabled: false
  },
  {
    key: 3,
    icon: 'fa-calculator',
    name: 'Statistical Demand Forecast',
    module: '',
    disabled: true
  },
  {
    key: 4,
    icon: 'fa-chart-network',
    name: 'SCO - Supply Chain Optimizer',
    module: moduleCargillEnum.integratedPlanning,
    href: '',
    disabled: false
  },
  {
    key: 5,
    icon: 'fa-compass',
    name: 'Operation Tracking',
    module: '',
    disabled: true
  },
  {
    key: 6,
    icon: 'fa-network-wired',
    name: 'workflow.pages.title',
    href: 'schedule',
    module: moduleCargillEnum.workflow,
    // TODO: Remoção Workflow - Voltar quando voltar workflow
    // disabled: false
    disabled: true
  },
  {
    key: 7,
    icon: 'fa-cogs',
    name: 'integrationService.general.integration',
    href: 'integration',
    module: moduleCargillEnum.integration,
    disabled: false
  }
]
