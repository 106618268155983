var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-toolbar"}),_c('bc-wizard',{ref:"bcwizard",attrs:{"hideButtonsOnLastStep":"","save-action":_vm.onCreate,"disableButtonBack":_vm.disableButtonBack,"backAction":_vm.onBackAction},on:{"cancel":_vm.onCloseHandler,"change":_vm.onChange}},[_c('tab-content',{attrs:{"title":_vm.$t('core.import.select.title'),"beforeChange":_vm.onSelectFileStepChange,"lazy":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [(active)?_c('select-file-step',{ref:"selectFileStep",attrs:{"name":_vm._name,"templateOptions":_vm.templateOptions},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}):_vm._e()]}}])}),_c('tab-content',{attrs:{"title":_vm.$t('core.import.selectTabs.title'),"beforeChange":_vm.onSelectTabStepChange,"lazy":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [(active)?_c('select-tab-step',{ref:"selectTabStep",attrs:{"details":_vm.details,"translate":_vm.translate,"name":_vm._name,"files":_vm.files,"selectedTemplate":_vm.templateSelected,"templateCustomPages":_vm.templateCustomPages},model:{value:(_vm.selectedTabs),callback:function ($$v) {_vm.selectedTabs=$$v},expression:"selectedTabs"}}):_vm._e()]}}])}),_c('tab-content',{attrs:{"title":_vm.$t('core.import.importing.title'),"beforeChange":_vm.onImportingStepChange,"lazy":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [(active)?_c('import-process-step',{ref:"importProcessStep",attrs:{"service":_vm._service,"name":_vm._name,"masterId":_vm.$route.params.masterId,"detail":_vm.detail,"translate":_vm.translate,"files":_vm.files,"selectedTabs":_vm.selectedTabs,"selectedTemplate":_vm.templateSelected,"masterLineId":_vm.masterLineId},on:{"import":_vm.onImportHandler,"error":_vm.onImportErrorHandler}}):_vm._e()]}}])}),_c('tab-content',{attrs:{"title":_vm.$t('core.import.result.title'),"lazy":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [(active)?_c('import-result-step',{attrs:{"name":_vm._name,"service":_vm._service},model:{value:(_vm.result),callback:function ($$v) {_vm.result=$$v},expression:"result"}}):_vm._e()]}}])}),_c('tab-content',{attrs:{"title":_vm.$t('core.import.conclusion.title')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [(active)?_c('import-conclusion-step',{ref:"conclusionStep",attrs:{"name":_vm._name,"label":_vm.label},on:{"resetImport":function($event){return _vm.onResetImport()},"close":_vm.onCloseHandler}}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }