import { defineStore } from 'pinia'
import { eventHub } from '../bus/eventhub'
import { scenarioCargillType } from '../constants/scenarioCargillType'
import _ from 'lodash'

export const useScenarioOpen = defineStore('scenarioOpen', {
  state: () => ({
    scenarioOpen: { id: null }
  }),

  getters: {
    getScenarioOpen: (state) => () => state.scenarioOpen,
    isScenarioOpen: (state) => () => state.scenarioOpen != null,
    getScenarioType: (state) => () => {
      const scenarioType = state.scenarioOpen?.type
      return _.isObject(scenarioType) ? scenarioType?.value : scenarioType
    },
    isScenarioMaster: (state) => () =>
      state.getScenarioType() == null ||
      state.getScenarioType() == scenarioCargillType.masterData,
    isScenarioWeekly: (state) => () =>
      state.getScenarioType() == scenarioCargillType.weekly,
    isScenarioMonthly: (state) => () =>
      state.getScenarioType() == scenarioCargillType.monthly,
    isScenarioWeeklyOrMaster: (state) => () =>
      state.isScenarioMaster() || state.isScenarioWeekly(),
    isScenarioMonthlyOrMaster: (state) => () =>
      state.isScenarioMaster() || state.isScenarioMonthly()
  },

  actions: {
    updateScenarioOpen(scenarioOpen) {
      this.scenarioOpen = scenarioOpen
      if (scenarioOpen?.id != null) {
        eventHub.$emit('open-scenario-callback', scenarioOpen)
      }
    }
  },
  persist: true
})
