import { defineStore } from 'pinia'
import { useScenarioOpen } from './scenarioOpen'
import { useUserSettings } from './userSettings'

const getTransaction = (transaction) => {
  const { isScenarioMaster } = useScenarioOpen()
  const { isScenarioResource, getResourceMasterDataSuffix } = useUserSettings()
  return isScenarioMaster() && isScenarioResource(transaction)
    ? transaction + getResourceMasterDataSuffix()
    : transaction
}

export const useTransactions = defineStore('transaction', {
  state: () => ({
    /**
     * User transactions object
     *
     * @example <caption>Example of transaction object</caption>
     * transaction: {
     *   user: {
     *     create: true,
     *     read: true,
     *     update: true,
     *     delete: true
     *   },
     *   // ...
     * }
     */
    transactions: {}
  }),

  getters: {
    /**
     * Determines whether the current user has permission to create the
     * `transaction` resource.
     *
     * @param {String} transaction The resource to check
     */
    canCreate: (state) => (transaction) =>
      !!state.transactions[getTransaction(transaction)]?.create,

    /**
     * Determines whether the current user has permission to read the
     * `transaction` resource.
     *
     * @param {String} transaction The resource to check
     */
    canRead: (state) => (transaction) =>
      !!state.transactions[getTransaction(transaction)]?.view,
    /**
     * Determines whether the current user has permission to update the
     * `transaction` resource.
     *
     * @param {String} transaction The resource to check
     */
    canUpdate: (state) => (transaction) =>
      !!state.transactions[getTransaction(transaction)]?.update,

    /**
     * Determines whether the current user has permission to delete the
     * `transaction` resource.
     *
     * @param {String} transaction The resource to check
     */
    canDelete: (state) => (transaction) =>
      !!state.transactions[getTransaction(transaction)]?.delete
  },

  actions: {
    /**
     * Fetch the user transaction list
     */
    fetchTransactions() {
      // TODO: Implement the actual API call here
      this.transactions = {
        User: {
          create: true,
          read: true,
          update: true,
          delete: true
        }
      }
    },

    /**
     * Update the user transaction list
     *
     * This method is for tests purpose only
     */
    updateTransactions(transactions) {
      this.transactions = transactions
    }
  },
  persist: true
})
