<template>
  <div v-if="meta != null" class="bc-crud">
    <cg-crud-meta-view
      :service="service"
      :details="_details"
      :metadata="meta"
      :gridData="cgGridData"
      :gridMode="gridMode"
      @gridReady="onGridReady"
      @clientSideLoadData="onClientSideLoadData"
      :translatePrefix="translatePrefix"
      :validation-schema="_validationSchema"
      :gridOptions="gridOptions"
      :customGridSetSize="customGridSetSize"
      :defaultImport="defaultImport"
      :deleteFiltered="deleteFiltered"
      :keepFilters="keepFilters"
      :cleanFiltersOnExitCrud="cleanFiltersOnExitCrud"
    />
  </div>
</template>

<script>
import CgCrudMetaView from './CustomCargillCrud/CgCrudMetaView.vue'

import { helpers, metadataHelper, gridUtils } from '@cargill/shared'

export default {
  name: 'CargillCrudMetaView',
  components: { CgCrudMetaView },
  props: {
    metadata: {
      type: Object,
      default: null
    },
    service: {
      type: Object,
      default: null
    },
    reloadData: {
      default: false,
      type: Boolean
    },
    gridData: { type: Array },
    gridMode: { type: String, default: 'serverSide' },
    details: { type: Object },
    translatePrefix: { type: String },
    validationSchema: { type: Function, default: () => null },
    defaultImport: { type: Boolean, default: true },
    deleteFiltered: { type: Boolean, default: true },
    keepFilters: { default: true, type: Boolean },
    cleanFiltersOnExitCrud: { default: true, type: Boolean }
  },
  data() {
    return {
      meta: null,
      // eslint-disable-next-line vue/no-reserved-keys
      _validationSchema: {},
      // eslint-disable-next-line vue/no-reserved-keys
      _details: {},
      cgGridData: null,
      gridOptions: {
        ...gridUtils.defaultGridOptions
      },
      bcTabs: null
    }
  },
  async mounted() {
    if (this.service.clearLastAppliedFilter) {
      this.service.clearLastAppliedFilter()
    }
    this.defineMetadata()
  },
  beforeDestroy() {},
  methods: {
    async defineMetadata() {
      this.metadata.translatePrefix = this.translatePrefix
      metadataHelper.processServices(this.metadata, this.service, this.details)
      try {
        this.meta = await metadataHelper.buildMetadata(
          this.metadata,
          this.service
        )
      } catch (exception) {
        console.log(exception)
      }
      this._validationSchema = this.validationSchema(
        this.meta,
        this.translate.bind(this)
      )
      this._details = this.meta.details?.reduce((acc, detail) => {
        const detailProps = this.details?.[detail.id]

        if (!detailProps) return acc
        return {
          ...acc,
          [detail.id]: {
            ...detailProps,
            validationSchema:
              detailProps.validationSchema?.(detail, (key) =>
                this.translate(`${detail.id}.${key}`)
              ) ?? null
          }
        }
      }, {})
    },
    translate(key) {
      const translateKey = this.translatePrefix
        ? `${this.translatePrefix}.${this.meta.id}.${key}`
        : key
      return this.$t(translateKey)
    },
    getCrudGridComponent() {
      return helpers.findFirstComponent(this, ['BcCrudGrid', 'CgCrudGrid'])
    },
    getGridComponent() {
      return helpers.findFirstComponent(this, ['BcGrid', 'CgGrid'])
    },
    onGridReady() {
      this.$emit('gridReady')
    },
    onClientSideLoadData() {
      const gridOptions = this.getGridComponent().aggridOptions
      this.customGridSetSize(gridOptions)
      this.$emit('clientSideLoadData')
    },
    customGridSetSize(aggridOptions) {
      gridUtils.resize(aggridOptions)
      // TODO: ao corrigir problema de redimencionamento ao trocar de aba detalhe no brain deve remover esse código updateDetailSizeOnTabChange
      this.updateDetailSizeOnTabChange()
    },
    updateDetailSizeOnTabChange() {
      const bcTabs = helpers.findComponent(this, 'BcTabs')
      if (bcTabs != null) {
        if (this.bcTabs != null) {
          this.bcTabs.$off('change', this.updateDetailTab)
        }
        this.bcTabs = bcTabs
        this.bcTabs.$on('change', this.updateDetailTab)
      }
    },
    updateDetailTab() {
      const bcTabsItems = helpers.findComponent(this, 'BcTabsItems')
      setTimeout(() => {
        const grid = helpers.findFirstComponent(
          bcTabsItems?.$children[bcTabsItems?.value],
          ['BcGrid', 'CgGrid']
        )
        const gridOptions = grid?.aggridOptions
        gridUtils.resize(gridOptions)
      }, 10)
    }
  },
  watch: {
    gridData: function (value) {
      this.cgGridData = value
    },
    reloadData: function () {
      if (!this.reloadData) {
        return
      }
      this.cgGridData = null
      this.getCrudGridComponent()?.reloadData()
      this.$emit('onReloadData')
    }
  }
}
</script>

<style lang="scss" scoped>
.align-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
