import { api } from '@cargill/shared'
import { createCrudService } from './cargillCrudService'

const getHviTemplate = async () => {
  const hviTemplateEndpoint = `/api/controltower/takeUpHvi/possibleTemplates`
  var results = await api.get(hviTemplateEndpoint)
  return results.data
}

const importExcelTemplate = async (
  formData,
  importTabs,
  templateName,
  masterLineId
) => {
  const dumb = createCrudService('', '', {})

  importTabs = dumb.applyTabNameQuery(dumb.fixPlusSign(importTabs))
  importTabs = dumb.applyFixedColumnsFilter(importTabs)

  const hviTemplateImportEndpoint = `/api/controltower/takeUpHvi/excelTemplate`
  try {
    const response = await api.post(
      `${hviTemplateImportEndpoint}?${importTabs}&templateName=${templateName}&masterLineId=${masterLineId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    return response.data
  } catch (e) {
    dumb.handleError(e)
    return [
      {
        service: null,
        totalRows: 0,
        insertedRows: 0,
        updatedRows: 0,
        errorRows: 0,
        invalidFile: true
      }
    ]
  }
}

export { getHviTemplate, importExcelTemplate }
