import { api, createCrudService, useModuleOpen } from '@cargill/shared'

const createService = () => {
  const { getApiRef } = useModuleOpen()
  const integrationJobURL = `/api/${getApiRef()}integrationTrigger`

  const service = createCrudService(integrationJobURL, api)

  service.customDelete = async (nodeData) => {
    const response = await api.delete(`${integrationJobURL}/customDelete`, {
      data: nodeData
    })
    return response?.data
  }

  service.customPause = async (nodeData) => {
    const response = await api.post(
      `${integrationJobURL}/pauseTrigger`,
      nodeData
    )
    return response?.data
  }

  service.customResume = async (nodeData) => {
    const response = await api.post(
      `${integrationJobURL}/resumeTrigger`,
      nodeData
    )
    return response?.data
  }

  service.customEditCron = async (nodeData) => {
    const response = await api.post(
      `${integrationJobURL}/editTriggerCron`,
      nodeData
    )
    return response?.data
  }

  return service
}

export default createService
