import {
  api,
  createCrudService,
  responseToOptionsInContext,
  useModuleOpen
} from '@cargill/shared'
import _ from 'lodash'

const createService = () => {
  const { getApiRef } = useModuleOpen()
  const basePath = `/api/${getApiRef()}integrationInbound`

  const service = createCrudService(basePath, api)

  service.getValidationContext = async () =>
    (await api.get(`${basePath}/validationContext`))?.data

  service.processValidationContext = (ctx, meta) => {
    responseToOptionsInContext(ctx)
    const fieldsById = _.keyBy(meta.fields, (field) => field.id)
    fieldsById.authenticator.options = ctx.authenticatorOptions
  }

  service.getMetaWithValidation = async () => {
    const meta = await service.getMeta()
    const ctx = await service.getValidationContext()
    service.processValidationContext(ctx, meta)
    meta.validationCtx = ctx
    return meta
  }

  return service
}

export default createService
