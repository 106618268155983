<template>
  <div>
    <div class="main-toolbar"></div>
    <bc-wizard
      ref="bcwizard"
      hideButtonsOnLastStep
      :save-action="onCreate"
      :disableButtonBack="disableButtonBack"
      :backAction="onBackAction"
      @cancel="onCloseHandler"
      @change="onChange"
    >
      <tab-content
        :title="$t('core.import.select.title')"
        :beforeChange="onSelectFileStepChange"
        v-slot="{ active }"
        lazy
      >
        <select-file-step
          v-if="active"
          ref="selectFileStep"
          :name="_name"
          v-model="files"
          :templateOptions="templateOptions"
        />
      </tab-content>

      <tab-content
        :title="$t('core.import.selectTabs.title')"
        :beforeChange="onSelectTabStepChange"
        v-slot="{ active }"
        lazy
      >
        <select-tab-step
          v-if="active"
          ref="selectTabStep"
          :details="details"
          :translate="translate"
          :name="_name"
          v-model="selectedTabs"
          :files="files"
          :selectedTemplate="templateSelected"
          :templateCustomPages="templateCustomPages"
        />
      </tab-content>

      <tab-content
        :title="$t('core.import.importing.title')"
        :beforeChange="onImportingStepChange"
        v-slot="{ active }"
        lazy
      >
        <import-process-step
          v-if="active"
          ref="importProcessStep"
          :service="_service"
          :name="_name"
          :masterId="$route.params.masterId"
          :detail="detail"
          :translate="translate"
          :files="files"
          :selectedTabs="selectedTabs"
          @import="onImportHandler"
          @error="onImportErrorHandler"
          :selectedTemplate="templateSelected"
          :masterLineId="masterLineId"
        />
      </tab-content>

      <tab-content
        :title="$t('core.import.result.title')"
        v-slot="{ active }"
        lazy
      >
        <import-result-step
          v-if="active"
          v-model="result"
          :name="_name"
          :service="_service"
        />
      </tab-content>

      <tab-content
        :title="$t('core.import.conclusion.title')"
        v-slot="{ active }"
      >
        <import-conclusion-step
          v-if="active"
          ref="conclusionStep"
          :name="_name"
          :label="label"
          @resetImport="onResetImport()"
          @close="onCloseHandler"
        />
      </tab-content>
    </bc-wizard>
  </div>
</template>

<script>
import { BcWizard } from '@brain/ui'
import { TabContent } from 'vue-form-wizard'
import { eventHub } from '@cargill/shared'

import {
  SelectFileStep,
  SelectTabStep,
  ImportProcessStep,
  ImportResultStep,
  ImportConclusionStep
} from './CustomImportSteps/single-import-wizard'

import {
  getHviTemplate,
  importExcelTemplate
} from '../../api/hviTemplatesService'

export default {
  name: 'CgCrudImport',
  components: {
    SelectFileStep,
    SelectTabStep,
    TabContent,
    BcWizard,
    ImportProcessStep,
    ImportResultStep,
    ImportConclusionStep
  },
  props: {
    name: {
      type: String,
      require: true
    },
    label: {
      type: String,
      require: true
    },
    service: {
      type: Object,
      require: true
    },
    columns: {
      type: Array,
      default: () => []
    },
    details: {
      type: Array,
      default: () => []
    },
    translate: {
      type: Function,
      require: true
    },
    masterLineId: {
      require: true
    }
  },
  data() {
    return {
      currentStep: 'importProcessStep',
      files: [],
      selectedTabs: [],
      result: {},
      planning: {
        planningName: null,
        copyPlanning: null
      },
      disableButtonBack: false,
      templateSelected: {},
      templateOptions: [],
      templateCustomPages: [],
      templatesConfig: {}
    }
  },
  computed: {
    isDetailImport() {
      return this.$route.params.masterId
    },
    detail() {
      const detailName = this.$route.params.name
      const detail = detailName
        ? this.details?.find((detail) => detail.name === detailName)
        : null

      return detail
    },
    _service() {
      const service = this.isDetailImport ? this.detail.service : this.service
      service.import = importExcelTemplate
      return service
    },
    _name() {
      return this.translate(
        `${this.isDetailImport ? this.$route.params.parentRoute + '.' : ''}${
          this.$route.params.name
        }.title`
      )
    }
  },
  async mounted() {
    eventHub.$emit('before-request')
    this.templatesConfig = await getHviTemplate()
    let templates = Object.keys(this.templatesConfig)
    templates =
      this.masterLineId == null || this.masterLineId == 0
        ? ['brain']
        : templates
    this.templateOptions = templates.map((t) => {
      return {
        label: this.translateTemplateName(t),
        value: t
      }
    })
    eventHub.$emit('after-response')
  },
  methods: {
    async onCreate() {},
    async onChange(args) {
      const [, nextIndex] = args
      this.disableButtonBack = Array.from([1, 2]).includes(nextIndex)
    },
    translateTemplateName(templateName) {
      return this.$t(`application.enums.takeUpHviTemplate.${templateName}`)
    },
    onSelectFileStepChange() {
      const stepRef = this.$refs.selectFileStep
      if (stepRef == null) return false
      const valid = stepRef.validate()
      this.templateSelected = stepRef.getSelectedTemplate()
      this.templateCustomPages =
        this.templatesConfig[this.templateSelected.value]
      return valid
    },
    onSelectTabStepChange() {
      const stepRef = this.$refs.selectTabStep
      if (stepRef == null) return false
      const valid = stepRef.validate()
      return valid
    },
    onImportingStepChange() {
      return this.$refs.importProcessStep.isImportFinished()
    },
    onResetImport() {
      this.$refs.bcwizard.$refs.wizard.reset()
    },
    onImportHandler(importResult) {
      this.result = importResult
      if (this.result) {
        this.$refs.bcwizard.$refs.wizard.nextTab()
      }
      this.files = []
    },
    onImportErrorHandler(importResult) {
      this.result = importResult
      if (this.result) {
        this.$refs.bcwizard.$refs.wizard.nextTab()
      }
      this.files = []
    },
    onCloseHandler() {
      this.$emit('close', { parentRoute: this.$route.params.parentRoute })
    },
    onBackAction(args, ref) {
      ref.reset()
    }
  }
}
</script>

<style lang="scss" scoped></style>
