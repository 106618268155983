import api from './api'
import { moduleCargillEnum, moduleApiRefCargillEnum } from '../constants'

const ignoredModules = new Set([
  moduleCargillEnum.integratedPlanning,
  moduleCargillEnum.integration,
  moduleCargillEnum.workflow,
  moduleCargillEnum.dataPermission
])

const service = {}

service.getAllDetailsRouters = async () => {
  const allDetailsRouters = await Promise.all(
    Object.keys(moduleCargillEnum).map((moduleKey) =>
      service.getDetailsRouters(moduleKey)
    )
  )
  return Object.fromEntries(
    allDetailsRouters.map((detailsRouters) => [
      detailsRouters.module,
      detailsRouters.detailsRouters
    ])
  )
}

service.getDetailsRouters = async (moduleKey) => {
  const routers = {
    module: moduleCargillEnum[moduleKey],
    detailsRouters: {}
  }
  if (ignoredModules.has(moduleCargillEnum[moduleKey])) {
    return routers
  }
  try {
    const response = await api.get(
      `/api/${moduleApiRefCargillEnum[moduleKey]}detailDynamicRouter/detailsRouters`
    )
    routers.detailsRouters = response?.data
  } catch (e) {
    console.error(
      `Não foi possível recuperar as rotas dinâmicas do módulo ${moduleCargillEnum[moduleKey]}, { key: ${moduleKey}, api: ${moduleApiRefCargillEnum[moduleKey]}}`
    )
    console.error(e)
  }
  return routers
}

export default service
