import _ from 'lodash'
import { helpers, i18n, api, notify } from '@cargill/shared'
import moment from 'moment'
import FileDownload from 'js-file-download'

const getAxisTitle = (unity, type = 'column') => {
  let titleOptions = {
    text: unity,
    align: 'low',
    offset: 0,
    rotation: 0,
    style: {
      color: '#959DB5'
    },
    y: 20
  }

  if (type == 'bar') {
    return { ...titleOptions, ...{ align: 'high', y: -20, x: 27 } }
  }

  return titleOptions
}
const processDate = (date) => (date ? moment(date).format('YYYY-MM-DD') : '')

const getUnityTonne = () => {
  return {
    text: i18n.t('application.pages.monthlyPlanningDashboardRevised.ton'),
    value: 'Tonne',
    yAxesTextUnit: 'MT',
    yAxesTextCurrencyPerUnit: 'R$/MT'
  }
}

const getUnityHc = () => {
  return {
    text: i18n.t('application.pages.monthlyPlanningDashboardRevised.highCube'),
    value: 'Container',
    yAxesTextUnit: 'Cntr',
    yAxesTextCurrencyPerUnit: 'R$/Cntr'
  }
}

const getUnities = () => [getUnityTonne(), getUnityHc()]

const getMarketTypeME = () => {
  return {
    text: i18n.t('controlTower.pages.cockpitExecution.externalMarket'),
    value: 'EM'
  }
}

const getMarketTypeMI = () => {
  return {
    text: i18n.t('controlTower.pages.cockpitExecution.internalMarket'),
    value: 'IM'
  }
}

const getMarketTypes = () => [getMarketTypeME(), getMarketTypeMI()]

const getPerformancePlans = () => {
  return [
    {
      text: i18n.t('application.pages.performanceDashboard.weekly'),
      value: 'weekly'
    },
    {
      text: i18n.t('application.pages.performanceDashboard.monthly'),
      value: 'monthly'
    }
  ]
}

const getCropYears = () => {
  const actualCropYear = getActualCropYear()
  return [
    {
      text: actualCropYear - 2,
      value: actualCropYear - 2
    },
    {
      text: actualCropYear - 1,
      value: actualCropYear - 1
    },
    {
      text: actualCropYear,
      value: actualCropYear
    },
    {
      text: actualCropYear + 1,
      value: actualCropYear + 1
    },
    {
      text: actualCropYear + 2,
      value: actualCropYear + 2
    }
  ]
}

const getActualCropYear = () => {
  const actualDate = moment()
  return actualDate.month() < 7 ? actualDate.year() - 1 : actualDate.year()
}

const initFilterRangeTotalPlan = () => {
  const actualCropYear = getActualCropYear()
  const minDate = `${actualCropYear - 2}-08-01`
  const maxDate = `${actualCropYear + 2}-07-31`
  return [minDate, maxDate]
}

const processErrors = (errors) => {
  const argsByError = createArgsByError(errors)
  for (const error of Object.keys(argsByError)) {
    notify.error({
      title: i18n.t(error, { arg: argsByError[error] })
    })
  }
}

const createArgsByError = (allErrors) => {
  const argsByError = {}
  allErrors?.forEach((error) => {
    var tokens = error.split(',')
    const key = tokens[0]
    const args = (argsByError[key] = argsByError[key] ?? new Set())
    tokens.slice(1).forEach((arg) => args.add(arg))
  })
  Object.keys(argsByError).forEach(
    (key) => (argsByError[key] = _.sortBy([...argsByError[key]]).join(', '))
  )
  return argsByError
}

const getErrors = async (exception) => {
  const data = exception?.response?.data
  if (data == null) {
    return []
  }
  if (data instanceof Blob) {
    if (data.type == 'application/json') {
      const json = await data.text()
      const jsonData = JSON.parse(json)
      return jsonData?.errors ?? []
    }
    return []
  }
  return data?.errors ?? []
}

const processException = async (processError, exception) => {
  if (processError) {
    const errors = await getErrors(exception)
    processErrors(errors)
  } else {
    throw exception
  }
}

const filterDashboard = async (filter, url) => {
  try {
    const query = createQueryJsonObj(filter)
    const response = await api.get(url, {
      params: query
    })
    return response.data
  } catch (exception) {
    await processException(filter.processError, exception)
  }
}

const filterChart = async (
  oldChart,
  baseFilter,
  url,
  processChart,
  changeZeroToNull = true
) => {
  const filter = buildFilterChart(oldChart, baseFilter)
  const chart = await filterDashboard(filter, url)
  if (processChart && chart) {
    processChart(chart)
  }
  return processChartInput(chart, oldChart, changeZeroToNull)
}

const exportExcel = async (chartRef, baseFilter, url) => {
  const filter = buildFilterChart(chartRef, baseFilter)
  try {
    const query = createQueryJsonObj(filter)
    const response = await api.get(url, {
      params: query,
      responseType: 'blob'
    })
    FileDownload(response.data, `${chartRef.title}.xlsx`)
  } catch (exception) {
    await processException(filter.processError, exception)
  }
}

const createQueryObj = (filter) => {
  // eslint-disable-next-line no-unused-vars
  const { processError, ...realFilter } = filter
  realFilter.startDate = processDate(filter.startDate)
  realFilter.endDate = processDate(filter.endDate)
  return realFilter
}

const createQueryJsonObj = (filter) => {
  return { jsonQuery: JSON.stringify(createQueryObj(filter)) }
}

const buildFilter = (filters) => {
  const filterObj = {}
  filters?.forEach((filter) => {
    filterObj[filter.name] = filter.modelApplied
  })
  return filterObj
}

const buildFilterChart = (chart, baseFilter) => {
  var filter = {
    ...baseFilter,
    ...buildFilter(chart.filters)
  }
  Object.keys(filter)
    .filter((key) => filter[key] == null)
    .forEach((key) => delete filter[key])
  return filter
}

const processChartInput = (chart, oldChart, changeZeroToNull = true) => {
  processFilters(chart?.filters, oldChart?.filters)
  if (chart?.series == null) {
    return chart
  }
  const labels = chart.series.length
    ? chart.series[0].data.map((x) => x.label)
    : []

  chart.chartInput = {
    chartDataSets: chart.series.map((serie, i) => {
      serie.label = serie.name
      serie.data = serie.data.map((x) => {
        if (changeZeroToNull && !x.value) {
          return null
        }
        return x.value
      })
      serie.defaultBackgroundColor = serie.backgroundColor
      serie.backgroundColor = serie.data.map(() => serie.backgroundColor)
      serie.borderColor = serie.borderColor ?? ''
      serie.order = serie.order ?? chart.series.length - i
      if (serie.type == 'line') {
        serie.pointStyle = serie.pointStyle ?? 'circle'
        serie.pointRadius = serie.pointRadius ?? 3
        serie.pointBorderColor = serie.pointBorderColor ?? serie.backgroundColor
        serie.labelDesign = 'meta'
      }
      return serie
    }),
    forecast: chart.series.length ? chart.series[0].data : [],
    labels: labels
  }
  return chart
}

const processFilters = (filters, oldFilters) => {
  const oldFilterByName = _.keyBy(oldFilters ?? {}, (filter) => filter.name)
  filters?.forEach((filter) => processFilter(filter, oldFilterByName))
}

const processFilter = (filter, oldFilterByName) => {
  filter.name = _.camelCase(filter.name)
  processFilterModel(filter, oldFilterByName[filter.name])
  processEnumFilter(filter)
  filter.enumType = _.camelCase(filter.name)
}

const processFilterModel = (filter, oldFilter) => {
  if (oldFilter) {
    filter.modelApplied = oldFilter.modelApplied
    filter.model = oldFilter.model
  }
}

const processEnumFilter = (filter) => {
  if (filter.enumType != null) {
    filter.enumType = _.camelCase(filter.enumType)
    filter.value = helpers.createEnumOptions(
      filter,
      filter.value,
      (filter, value) =>
        i18n.tc(`application.enums.${filter.enumType}.${value}`)
    )
  }
}

const initFilterRange = (scenario, filterRange, lastFilterRange) => {
  filterRange[0] = scenario.beginHorizon
  filterRange[1] = scenario.endHorizon
  lastFilterRange[0] = scenario.beginHorizon
  lastFilterRange[1] = scenario.endHorizon
}

const getDateByWeek = (w) => {
  const week = w.substr(1)
  return moment().day('Sunday').week(week).add(7, 'days')
}

const getStartDateNextWeek = (date) => {
  let d = moment(date)
  let day = d.day()
  let diff = d.date() - day // adjust when day is sunday
  d = moment(d).set('date', diff)
  d = moment(d).subtract(1, 'days')
  return processDate(moment(d))
}

const initFilterRangePerformance = (
  planValue,
  filterRange,
  lastFilterRange
) => {
  if (planValue == getPerformancePlans()[0].value) {
    ///weekly
    filterRange[1] = getStartDateNextWeek(processDate(moment()))
    filterRange[0] = processDate(
      moment(filterRange[1]).subtract(8 * 7 - 1, 'days')
    )
  } else {
    //monthly
    filterRange[1] = processDate(moment().startOf('month').subtract(1, 'days'))
    filterRange[0] = processDate(
      moment(filterRange[1]).subtract(22, 'M').add(1, 'days')
    )
  }

  lastFilterRange[0] = filterRange[0]
  lastFilterRange[1] = filterRange[1]
}

export default {
  getAxisTitle,
  processChartInput,
  filterDashboard,
  filterChart,
  exportExcel,
  initFilterRange,
  processErrors,
  buildFilter,
  buildFilterChart,
  getUnityTonne,
  getUnityHc,
  getUnities,
  getMarketTypeME,
  getMarketTypeMI,
  getMarketTypes,
  getCropYears,
  getActualCropYear,
  initFilterRangeTotalPlan,
  processFilters,
  getPerformancePlans,
  initFilterRangePerformance,
  getDateByWeek
}
